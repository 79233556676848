// Routing Outlet
import { Outlet } from "react-router-dom"

// Style
import './App.css';

function App() {
  return (
    <Outlet />
  )
}

export default App
