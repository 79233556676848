/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

// material-ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/joy/CircularProgress";

// images anf logo
import alert from "../../assets/icons/notification.png"

// Modal style
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    "@media (min-width: 768px)": {
        width: "800px",
    },
};

const UnpublishEventModal = ({ open, onClose, name, onSubmit }: any) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="relative">
                    <div
                        className="cursor-pointer absolute right-[-15px] top-[-25px]"
                        onClick={onClose}
                    >
                        <i className="fas fa-times "></i>
                    </div>

                    <div>
                        <div className="relative">
                            <img src={alert} alt="alert" className="w-20 h-20 mx-auto" />
                            <div className="text-3xl text-black font-bold capitalize text-center mt-4 mb-2">
                                Warning!
                            </div>
                        </div>

                        <div className="relative p-4">
                            <div>
                                <p className="text-base font-medium text-center mb-3">
                                    {`Are you sure you want to unpublish ${name}?`}
                                </p>
                            </div>
                            <div className="mt-5 mx-auto text-center flex items-center justify-center gap-6 max-sm:flex-wrap max-sm:mt-4 max-sm:gap-4">
                                <button
                                    onClick={onSubmit}
                                    className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base w-[13.5rem] capitalize relative"
                                >
                                    Unpublish event
                                    {isLoading && (
                                        <CircularProgress
                                            thickness={2}
                                            size="sm"
                                            style={{ marginLeft: "10px", lineHeight: "0", position: "absolute", right: "10px" }}
                                        />
                                    )}
                                </button>
                                <button
                                    onClick={onClose}
                                    className="text-white px-4 py-2.5 find-btn font-normal text-base capitalize"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default UnpublishEventModal