// material-ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// dropzone for image upload
import Dropzone from "react-dropzone";

// material-ui

// Logos and Image
import addimage from "../../assets/image/addimage.png";

//Progressbar
// Modal style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  "@media (min-width: 768px)": {
    width: "800px",
  },
};

const UploadImageModal = ({
  open,
  onClose,
  file,
  setFile,
  isLoading,
  onSubmit,
}: any) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="relative">
          {/* <div
                        className="cursor-pointer absolute right-[-15px] top-[-45px]"
                        onClick={!isLoading && onClose}
                    >
                        <i className="fas fa-times " />
                    </div> */}

          <div className="mt-2">
            <div className="relative">
              <div className="text-xl text-black font-semibold capitalize text-center mb-6">
                Add Images
              </div>
              {/* <div className="flex items-center justify-center gap-6">
                                <div>
                                    <label className="w-52 cursor-pointer inline-block box-inner-shadow py-1.5 px-2">
                                        <input type="radio" /> Skip Duplicates
                                    </label>
                                </div>
                                <div>
                                    <label className="w-52 cursor-pointer inline-block input-box-shadow py-1.5 px-2">
                                        <input type="radio" /> Overwrite Duplicates
                                    </label>
                                </div>
                            </div> */}
            </div>
            <div className="grid grid-cols-5 gap-5 max-h-[200px] overflow-y-auto py-2">
              {file &&
                file.length > 0 &&
                file.map((row: any, index: string) => {
                  return (
                    <div key={index} className="relative">
                      <button
                        onClick={() => {
                          const files = [...file];
                          files.splice(+index, 1);
                          setFile(files);
                        }}
                        className="cursor-pointer absolute right-[5px] top-[3px] leading-[0] bg-white h-[15px] w-[15px] rounded-full text-[13px] font-bold flex items-center justify-center" 
                        
                      >
                        x
                      </button>
                      <img
                        src={URL.createObjectURL(row)}
                        alt=""
                        className="h-[80px] w-full object-cover"
                      />
                    </div>
                  );
                })}
            </div>
            <Dropzone
              onDrop={(acceptedFiles: any) => {
                console.log("acceptedFiles", acceptedFiles);
                setFile([...file, ...acceptedFiles]);
              }}
              accept={{ "image/*": [".png", ".gif", ".jpeg", ".jpg"] }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <div className="mt-4 relative p-4 border-2 border-dashed border-[#ddd] rounded input-box-shadow inline-block w-full cursor-pointer">
                    <input
                      {...getInputProps()}
                      type="file"
                      multiple
                      // onChange={(e: any) => {
                      //     console.log(e.target.files);
                      //     console.log(e.target.files[0]);
                      //     setFile([e.target.files]);
                      //     // setFile([...file, e.target.files]);
                      // }}
                      id="myfile"
                      name="myfile"
                      className="w-full opacity-0 leading-none"
                    />
                    <div>
                      <img
                        src={addimage}
                        alt="addimage"
                        className="w-12 h-auto mx-auto mb-3"
                      />
                    </div>
                    <div>
                      <p className="text-sm font-medium capitalize text-center mb-3">
                        Drag & drop images to upload
                      </p>
                      <p className="text-sm font-medium uppercase text-center">
                        or
                      </p>
                    </div>
                    <div className="mt-3 mx-auto text-center">
                      <div className="flex gap-6 items-center justify-center">
                        <div className="bg-[#4854e2] text-white px-4 py-2.5 font-normal text-base w-[10rem] capitalize">
                          Browse Files
                        </div>
                      </div>
                      <p className="text-xs text-[#666] font-normal mt-3">
                        Maximum upload file size: 30 MB
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
            <button
              onClick={onSubmit}
              disabled={isLoading || file.length === 0}
              className={`bg-[#4854e2] text-white px-4 py-2.5 font-normal capitalize text-center text-base w-full mt-6 ${
                isLoading || file.length === 0
                  ? "cursor-no-drop"
                  : "cursor-pointer"
              } relative flex items-center justify-center mx-auto`}
            >
              Upload
              {/* {isLoading && (
                                <CircularProgress
                                    thickness={2}
                                    size="sm"
                                    style={{ marginLeft: "10px", lineHeight: "0" }}
                                />
                            )} */}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UploadImageModal;
