/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

// Navigation
import { useNavigate } from "react-router-dom";

// material-ui
import CircularProgress from "@mui/joy/CircularProgress";

// Formik
import { Formik } from "formik";
import * as Yup from "yup";

// Header Component
import Header from "../menu/Header";

// Appcontext
import { useAppContext } from "../hooks/useAppContext";

// ApiService
import ApiService from "../service/ApiDataRequest";

// Images and Logos
// import googleIcon from "../assets/image/google-icon.png";
import SignInPhoto from "../assets/image/human-ai.png";

// RegExp
const emailRegExp = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
// const passwordRegExp = RegExp(
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
// );

// Validationschema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")
    .matches(emailRegExp, "Please enter valid email id")
    .required("Email Id is required"),
  firstName: Yup.string().trim("Enter valid Name").required("First Name is required"),
  lastName: Yup.string().trim("Enter valid Name").required("Last Name is required"),
  phoneNumber: Yup.string()
    .min(10, 'Invalid Phone Number')
    .matches(/^[0-9]{0,10}$/, 'Invalid Phone Number'),
  // .required("Phone Number is required"),
  password: Yup.string()
    .label("Password")
    .required("Password is required")
    .min(4, "Minimum 4 characters required")
    .max(20, "Maximum 20 characters allowed"),
  // .matches(
  //   passwordRegExp,
  //   "Password must contain one uppercase, one lowercase, one digit and special character"
  // ),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "You need to accept the terms and conditions"
  ),
});

type RegistrationValue = {
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  phoneNumber: string,
  termsAndConditions: boolean
}

const Register = () => {
  const {
    setIsOpen,
    setAlertType,
    setResponseMessage,
    setUserDetail,
  } = useAppContext();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password")

  // function for API call to create a new user
  const handleRegistration = async (values: RegistrationValue) => {
    setIsLoading(true);
    const { email, firstName, lastName, password, phoneNumber } = values;

    try {
      const response = await ApiService.registerUser(
        email,
        firstName.trim(),
        lastName.trim(),
        password,
        phoneNumber
      );

      console.log("response", response);
      localStorage.setItem("auth-token", response.data.token);
      setIsLoading(false);
      setIsOpen(true);
      setAlertType("success");
      setResponseMessage(response.message);

      const userData = {
        firstName: response.data.userObj.firstName.trim(),
        lastName: response.data.userObj.lastName.trim(),
        email: response.data.userObj.email,
        userId: response.data.userObj.id,
      }
      setUserDetail(userData);
      localStorage.setItem("user-detail", JSON.stringify(userData));

    } catch (error: any) {
      console.log('error', error);
      setIsLoading(false);
      setIsOpen(true);
      setAlertType("error");
      setResponseMessage(error.data[0].description);
    }
  };

  return (
    <>
      <Header />
      <div className="container mx-auto max-sm:px-4">
        <div className="flex items-center min-h-[calc(100vh_-_64px)]">
          <div className="grid md:grid-cols-2 gap-4 py-10">
            <div className="p-6 flex items-start justify-center flex-col max-[640px]:text-center max-[640px]:items-center">
              <div className="mb-5 ">
                <img
                  src={SignInPhoto}
                  style={{
                    height: "150px",
                    borderRadius: "50% 50% 50% 50%/40% 40% 60% 60%",
                  }}
                  alt="SignInPhoto"
                />
              </div>
              PhotoFace is the world's only end to end AI powered image post
              production solution
            </div>
            <div className="box-inner-shadow rounded-xl p-6">
              <div className="font-bold text-xl py-3">Sign Up</div>
              <div className="mb-3">
                Already have an account?
                <button
                  onClick={() => navigate("/login")}
                  className="underline underline-offset-2 decoration-[#4854e2] text-[#4854e2] font-bold pl-2 cursor-pointer"
                >
                  Sign In
                </button>
              </div>
              <Formik
                initialValues={{
                  email: "",
                  firstName: "",
                  lastName: "",
                  phoneNumber: "",
                  password: "",
                  termsAndConditions: false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  console.log("values", values);
                  handleRegistration(values);
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                }) => {
                  return (
                    <>
                      <form onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                      }}>
                        <div className="my-3">
                          <div className="py-1">
                            <label htmlFor="email" className="px-3">
                              Email Id*
                            </label>
                          </div>
                          <div className="input-box-shadow rounded-lg">
                            <input
                              type="text"
                              id="email"
                              name="email"
                              onChange={handleChange("email")}
                              onBlur={handleBlur}
                              value={values.email}
                              className="w-full h-10 rounded-lg focus-visible:outline-none px-3"
                            />
                          </div>
                          {errors.email && touched.email ? (
                            <div className="text-[red]">{errors.email}</div>
                          ) : null}
                        </div>
                        <div className="grid md:grid-cols-2 gap-8">
                          <div className="my-3">
                            <div className="py-1">
                              <label htmlFor="firstname" className="px-3">
                                First Name*
                              </label>
                            </div>
                            <div className="input-box-shadow rounded-lg">
                              <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                onChange={handleChange("firstName")}
                                onBlur={handleBlur}
                                value={values.firstName}
                                className="w-full h-10 rounded-lg focus-visible:outline-none px-3"
                              />
                            </div>
                            {errors.firstName && touched.firstName ? (
                              <div className="text-[red]">{errors.firstName}</div>
                            ) : null}
                          </div>
                          <div className="my-3">
                            <div className="py-1">
                              <label htmlFor="lastname" className="px-3">
                                Last Name*
                              </label>
                            </div>
                            <div className="input-box-shadow rounded-lg">
                              <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                onChange={handleChange("lastName")}
                                onBlur={handleBlur}
                                value={values.lastName}
                                className="w-full h-10 rounded-lg focus-visible:outline-none px-3"
                              />
                            </div>
                            {errors.lastName && touched.lastName ? (
                              <div className="text-[red]">{errors.lastName}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="my-3">
                          <div className="py-1">
                            <label htmlFor="phoneNumber" className="px-3">
                              Phone Number
                            </label>
                          </div>
                          <div className="input-box-shadow rounded-lg">
                            <input
                              type="text"
                              id="phoneNumber"
                              name="phoneNumber"
                              onChange={handleChange("phoneNumber")}
                              onBlur={handleBlur}
                              value={values.phoneNumber}
                              className="w-full h-10 rounded-lg focus-visible:outline-none px-3"
                            />
                          </div>
                          {errors.phoneNumber && touched.phoneNumber ? (
                            <div className="text-[red]">{errors.phoneNumber}</div>
                          ) : null}
                        </div>
                        <div className="my-3">
                          <div className="py-1">
                            <label htmlFor="password" className="px-3">
                              Password*
                            </label>
                          </div>
                          <div className="input-box-shadow rounded-lg relative">
                            <input
                              type={passwordType}
                              id="password"
                              name="password"
                              onChange={handleChange("password")}
                              onBlur={handleBlur}
                              value={values.password}
                              className="w-full h-10 rounded-lg focus-visible:outline-none px-3"
                            />
                            <div className="absolute right-[10px] top-[9px]">
                              {passwordType === 'text' && <i onClick={() => setPasswordType('password')} className="fas fa-eye text-[#787878] cursor-pointer"></i>}
                              {passwordType === 'password' && <i onClick={() => setPasswordType('text')} className="fas fa-eye-slash text-[#787878] cursor-pointer"></i>}
                            </div>
                          </div>
                          {errors.password && touched.password ? (
                            <div className="text-[red]">{errors.password}</div>
                          ) : null}
                        </div>
                        <div className="my-5">
                          <input
                            type="checkbox"
                            id="termsAndConditions"
                            name="termsAndConditions"
                            onChange={handleChange("termsAndConditions")}
                          />
                          <label className="pl-3 text-sm">
                            Accept all
                            <span className=" text-[#4854e2] pl-2">
                              Terms & Conditions
                            </span>
                          </label>
                          {errors.termsAndConditions &&
                            touched.termsAndConditions ? (
                            <div className="text-[red]">
                              {errors.termsAndConditions}
                            </div>
                          ) : null}
                          <br />
                        </div>
                        <div className="w-full text-center">
                          <button
                            type="submit"
                            // onClick={() => handleSubmit()}
                            disabled={isLoading}
                            className="px-14 py-2 bg-[#4854e2] text-white input-box-shadow font-medium flex items-center justify-center mx-auto"
                          >
                            <span>Sign Up</span>
                            {isLoading && (
                              <CircularProgress thickness={2} size="sm" style={{ marginLeft: '10px', lineHeight: '0' }} />
                            )}
                          </button>
                        </div>
                      </form>
                    </>
                  );
                }}
              </Formik>
              {/* <div className="relative w-full text-center py-5">
                <div
                  className="after:content-[''] after:h-[1px] after:bg-[#cacaca] after:w-[43%]
                                after:absolute after:left-0 after:top-[30px]
                                before:content-[''] before:h-[1px] before:bg-[#cacaca] before:w-[43%]
                                before:absolute before:right-0 before:top-[30px]
                                "
                >
                  Or
                </div>
              </div>
              <div className="w-full text-center">
                <div className="flex justify-center">
                  <img src={googleIcon} className="h-[24px]" alt="googleIcon" />
                  <button
                    onClick={() => navigate("/dashboard")}
                    className="underline underline-offset-2 decoration-[#4854e2] text-[#4854e2] font-bold pl-2"
                  >
                    Sign up with Google
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
