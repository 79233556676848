/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext } from "react";

// sweet-alert
import Swal from "sweetalert2";

// ApiService
import ApiService from "../service/ApiDataRequest";

// Type Interface
import { AppContextInterface, Children, UserDetailInterface, Payload } from "../interface/ContextInterface";
import { AlertColor } from "@mui/material";
import moment from "moment";

export const AppContext = createContext<AppContextInterface>({
    isLogin: false,
    setIsLogin: () => undefined,
    isOpen: false,
    setIsOpen: () => undefined,
    alertType: "success",
    setAlertType: () => undefined,
    responseMessage: "",
    setResponseMessage: () => undefined,
    userDetail: { firstName: '', lastName: '', email: '', userId: '' },
    setUserDetail: () => undefined,
    handleDeleteEvent: () => undefined,
    eventDataById: {},
    setEventDataById: () => undefined,
    getEventDataById: () => undefined,
    handleUpdateEventData: () => undefined,
    eventTags: [],
    setEventTags: () => undefined,
    getAllTags: () => undefined,
    addOrUpdateEventTags: () => undefined,
    deleteEventTag: () => undefined,
    isDeleteActiveTag: false,
    setIsDeleteActiveTag: () => undefined,
    isUpdateEvent: false,
    setIsUpdateEvent: () => undefined,
    isMoveToExpireEvent: false,
    setIsMoveToExpireEvent: () => undefined
})

const AppContextProvider = ({ children }: Children) => {

    const [isLogin, setIsLogin] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [alertType, setAlertType] = useState<AlertColor>("success")
    const [responseMessage, setResponseMessage] = useState<string>('')
    const [userDetail, setUserDetail] = useState<UserDetailInterface>(
        () => {
            const userData = localStorage.getItem('user-detail')
            if (userData) {
                const data = JSON.parse(userData)
                return data;
            }
            return null;
        }
    );
    const [eventDataById, setEventDataById] = useState<any>();
    const [eventTags, setEventTags] = useState<any[]>([])
    const [isDeleteActiveTag, setIsDeleteActiveTag] = useState(false)
    const [isUpdateEvent, setIsUpdateEvent] = useState(false)
    const [isMoveToExpireEvent, setIsMoveToExpireEvent] = useState(false)


    // Function for Api call to delete Event
    const handleDeleteEvent = (id: string, name: string, handleDelete: () => void) => {
        Swal.fire({
            title: "Warning!",
            text: `Are you sure you want to permanently delete ${name}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await ApiService.deleteEventById(id);
                    console.log("response", response);
                    if (response.statusCode === 200) {
                        handleDelete()
                        setIsOpen(true)
                        setAlertType("success")
                        setResponseMessage(response.message)
                    }
                } catch (error: any) {
                    console.log('error', error);
                    setIsOpen(true)
                    setAlertType("error")
                    setResponseMessage(error.data[0].description)
                }
            }
        });
    }

    //function for APi call to Update event data

    const handleUpdateEventData = async (id: string, payload: Payload, img: any) => {

        let formData: any = new FormData()
        if (img !== '') {
            formData.append("CoverImage", img);
        } else {
            Object.entries(payload).forEach(([key, value]) => {
                // if (value) formData.append(key, value as string)
                formData.append(key, value as string)
            })
        }
        setIsUpdateEvent(true)
        console.log('payload', payload)
        try {
            const response = await ApiService.updateEventData(id, formData)
            setIsUpdateEvent(false)
            console.log("response", response);
            setIsOpen(true)
            setAlertType("success")
            setResponseMessage(response.message)
            getEventDataById(id)
            if (payload.ExpiryDate === moment().subtract(1, 'days').format("YYYY-MM-DD")) {
                console.log('adsada')
                setIsMoveToExpireEvent(true)
            }
        } catch (error : any) {
            console.log('error', error);
            setIsUpdateEvent(false)
            setIsOpen(true)
            setAlertType("error")
            setResponseMessage(error.data[0].description)
        }
    }

    // Function for Api call to get event data
    const getEventDataById = async (id: string) => {
        try {
            const response = await ApiService.getEventById(id);
            console.log("response", response);
            setEventDataById(response.data.data);
        } catch (error: any) {
            console.log('error', error);
        }
    }

    //Function for Api call to get event tags
    const getAllTags = async (id: string) => {
        try {
            const response = await ApiService.getAllTag(id)
            setEventTags(response.data.data)
            console.log("response", response);
            if (isDeleteActiveTag) {
                handleUpdateEventData(id as string, { TagId: response.data.data[0].id }, '')
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    //function for Api call to Add/Update event tags
    const addOrUpdateEventTags = async (id: string, eventId: string, name: string) => {
        try {
            const response = await ApiService.addOrUpdateTag(id, eventId, name)
            console.log('response', response);
            getAllTags(userDetail.userId)
            setIsOpen(true);
            setAlertType("success");
            setResponseMessage(response.message);
        } catch (error: any) {
            console.log('error', error);
            setIsOpen(true)
            setAlertType("error")
            setResponseMessage(error.data[0].description)
        }
    }


    //function for Api call to delete event Tag
    const deleteEventTag = async (id: string) => {
        try {
            const response = await ApiService.deleteTag(id)
            getAllTags(userDetail.userId)
            console.log('response', response);
            setIsOpen(true)
            setAlertType("success")
            setResponseMessage(response.message)
        } catch (error : any) {
            setIsOpen(true)
            setAlertType("error")
            setResponseMessage(error.data[0].description)
            console.log('error', error);
        }
    }

    const value = {
        isLogin,
        setIsLogin,
        isOpen,
        setIsOpen,
        alertType,
        setAlertType,
        responseMessage,
        setResponseMessage,
        userDetail,
        setUserDetail,
        handleDeleteEvent,
        eventDataById,
        setEventDataById,
        getEventDataById,
        handleUpdateEventData,
        eventTags,
        setEventTags,
        getAllTags,
        addOrUpdateEventTags,
        deleteEventTag,
        isDeleteActiveTag,
        setIsDeleteActiveTag,
        isUpdateEvent,
        setIsUpdateEvent,
        isMoveToExpireEvent,
        setIsMoveToExpireEvent
    }

    return (
        <>
            <AppContext.Provider value={value}>{children}</AppContext.Provider>
        </>
    )
}

export { AppContextProvider }