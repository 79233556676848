/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";

// Navigation
import { useNavigate, useParams } from "react-router-dom";

// Header Component
import Header from "../menu/Header";
import Loader from "./Loader";

// material-ui
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/joy/CircularProgress";
import CircularProgressLoader from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

// filesaver-download image
import { saveAs } from "file-saver";

// ApiService
import ApiService from "../service/ApiDataRequest";

// Appcontext
import { useAppContext } from "../hooks/useAppContext";

// sweetAlert
import Swal from "sweetalert2";

// moment
import moment from "moment";

// Copy to clipboard
import copy from "copy-to-clipboard";

// Images and Logos
import tiles from "../assets/image/tiles.png";
import start from "../assets/image/start.png";

// lazy-load images
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Modals
import UpdateEventNameModal from "./modals/UpdateEventNameModal";
import UnpublishEventModal from "./modals/UnpublishEventModal";
import UploadImageModal from "./modals/UploadImageModal";
import ShowFullScreenImage from "./modals/ShowFullScreenImage";
import ShareModel from "./modals/ShareModel";
import PhotoGallery from "./photo-gallery/PhotoGallery";

// import PhotoAlbum from "react-photo-album";

// lightbox
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Backdrop } from "@mui/material";

// import { Fade } from "react-slideshow-image";
// import "react-slideshow-image/dist/styles.css";

// Custom Slider design
// const PrettoSlider = styled(Slider)({
//   // color: "#52af77",
//   height: 8,
//   "& .MuiSlider-track": {
//     border: "none",
//   },
//   // "& .MuiSlider-thumb": {
//   //   height: 24,
//   //   width: 24,
//   //   backgroundColor: "#fff",
//   //   border: "2px solid currentColor",
//   //   "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
//   //     boxShadow: "inherit"
//   //   },
//   //   "&:before": {
//   //     display: "none"
//   //   }
//   // }
// });

// Marks for Slider
// const marks = [
//   {
//     value: 0,
//     label: "S",
//   },
//   {
//     value: 50,
//     label: "M",
//   },
//   {
//     value: 100,
//     label: "L",
//   },
// ];

// Api url
const API_URL = process.env.REACT_APP_API_KEY;

const Eventdashboard = ({ scrollPosition }: any) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    userDetail,
    setIsOpen,
    setAlertType,
    setResponseMessage,
    handleDeleteEvent,
    eventDataById,
    getEventDataById,
    handleUpdateEventData,
    getAllTags,
    eventTags,
    addOrUpdateEventTags,
    deleteEventTag,
    setIsDeleteActiveTag,
    isUpdateEvent,
  } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  // const [open, setOpen] = useState(false);
  const [eventOpen, setEventOpen] = useState(false);
  const [view, setView] = useState("Tile");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<string[]>([]);
  const [modalPublish, setModalPublish] = useState(false);
  const [openEventNameModal, setOpenEventNameModal] = useState(false);
  const [isAddImage, setIsAddImage] = useState(false);
  const [isAddMore, setIsAddMore] = useState(false);
  const [imageListData, setImageListData] = useState<any>([]);
  // const [file, setFile] = useState<any>();
  const [file, setFile] = useState<any>([]);
  const [eventAge, setEventAge] = useState<number>();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [imageDetailById, setImageDetailById] = useState<any>();
  const [imageListDataId, setImageListDataId] = useState<string[]>([]);
  const [activeImage, setActiveImage] = useState<number>(0);
  const [filterType, setFilterType] = useState("1");
  const [filterArg, setFilterArg] = useState({
    orderByColumnName: "CreatedDateTime",
    sortColumnDir: "desc",
  });
  const [searchText, setSearchText] = useState<string>("");
  const [eventDescription, setEventDescription] = useState<string>();
  const [eventName, setEventName] = useState<string>();
  const [eventTagName, setEventTagName] = useState("");
  const [openAddTagModel, setOpenAddTagModel] = useState(false);
  const [tagNameToShow, setTagNameToShow] = useState("");
  const [isUpdateTag, setIsUpdateTag] = useState(false);
  const [eventTagId, setEventTagId] = useState<number>(0);
  const [isDescriptionChanged, setIsDescriptionChanged] = useState(false);
  const [sharemodal, setSharemodal] = useState(false);
  const [isImageUpload, setIsImageUpload] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  // const [tagId, setTagId] = useState('')
  // const arr = [1, 2, 3, 4];
  const [galleryPhoto, setGalleryPhoto] = useState<any>();
  const [index, setIndex] = useState(-1);
  const [copyMsgShow, setCopyMsgShow] = useState(false);
  const [state, setState] = useState<any>(0);

  // function to filter the images
  const handleApplyFilter = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setFilterType(value);
    setSearchText("");
    if (value === "1") {
      setFilterArg({
        orderByColumnName: "CreatedDateTime",
        sortColumnDir: "desc",
      });
      getDynamicImageList("", "CreatedDateTime", "desc");
    } else if (value === "2") {
      setFilterArg({
        orderByColumnName: "CreatedDateTime",
        sortColumnDir: "asc",
      });
      getDynamicImageList("", "CreatedDateTime", "asc");
    } else if (value === "3") {
      setFilterArg({ orderByColumnName: "imageName", sortColumnDir: "asc" });
      getDynamicImageList("", "imageName", "asc");
    } else if (value === "4") {
      setFilterArg({ orderByColumnName: "imageName", sortColumnDir: "desc" });
      getDynamicImageList("", "imageName", "desc");
    } else if (value === "5") {
      setFilterArg({ orderByColumnName: "imageSize", sortColumnDir: "asc" });
      getDynamicImageList("", "imageSize", "asc");
    } else if (value === "6") {
      setFilterArg({ orderByColumnName: "imageSize", sortColumnDir: "desc" });
      getDynamicImageList("", "imageSize", "desc");
    }
  };

  // set event description and name from eventdata
  useEffect(() => {
    setEventDescription(eventDataById?.description);
    setEventName(eventDataById?.eventName);
  }, [eventDataById]);

  // function to select all checkbox in list
  const handleSelectAllCheckbox = () => {
    setIsCheck(imageListDataId);
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  // fuction to call on every single checkbox action
  const handleCheckboxChange = (e: {
    target: { id: string; checked: boolean };
  }) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // useEffect to check-uncheck checkbox in list
  useEffect(() => {
    if (
      imageListDataId.length > 0 &&
      isCheck.length === imageListDataId.length
    ) {
      setIsCheckAll(true);
    } else setIsCheckAll(false);
  }, [isCheck, imageListDataId]);

  // Function for Api call to get dynamic image list to display
  const getDynamicImageList = async (
    searchtext: string,
    orderByColumnName: string,
    sortColumnDir: string
  ) => {
    setIsLoading(true);
    try {
      const response = await ApiService.getDynamicImageDataList(
        id,
        userDetail.userId,
        searchtext,
        orderByColumnName,
        sortColumnDir
      );
      // console.log("response", response);
      setImageListData(response?.data?.data?.result);
      const imageIds =
        response?.data?.data?.result &&
        response?.data?.data?.result.map((item: any) => item.id);
      setImageListDataId(imageIds);
      setIsLoading(false);

      if (response.status === 204) {
        setImageListData([]);
        setImageListDataId([]);
      }
    } catch (error: any) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  // useEffect to call getDynamicImageList & getEventDataById function
  useEffect(() => {
    const { orderByColumnName, sortColumnDir } = filterArg;
    getDynamicImageList(searchText, orderByColumnName, sortColumnDir);
    getEventDataById(id as string);
    getAllTags(userDetail.userId);
  }, []);

  // Function for Api call to upload event image
  const handleUploadEventImages = async (file: any) => {
    if (!file) {
      return;
    }
    // create formdata to upload image
    const formData: any = new FormData();

    Object.entries({
      EventId: id as string,
      UserId: userDetail.userId,
    }).forEach(([key, value]) => {
      if (value) formData.append(key, value as string);
    });

    // append all selected image in formdata
    for (const img of file) {
      formData.append("lstImage", img);
    }
    setIsImageUpload(true);
    try {
      const response = await ApiService.uploadEventImages(formData, {
        onUploadProgress: (progressEvent: any) => {
          console.log(progressEvent, "progressssssssss");

          const { loaded, total } = progressEvent;
          const percent = Math.round((loaded * 100) / total);
          console.log(`${loaded}kb of ${total}kb | ${percent}%`);

          if (percent < 100) {
            setState(percent);
          }
        },
      });
      console.log("response", response);
      setIsImageUpload(false);
      if (response.statusCode === 200) {
        getDynamicImageList("", "CreatedDateTime", "desc");
        setIsOpen(true);
        setAlertType("success");
        setResponseMessage(response.message);
      }
      setIsAddImage(false);
    } catch (error: any) {
      console.log("error", error);
      setIsImageUpload(false);
      setIsOpen(true);
      setAlertType("error");
      setResponseMessage(error.data[0].description);
    }
  };

  // Get difference between today to event create date
  useEffect(() => {
    if (eventDataById) {
      const today = new Date();
      const eventdate = new Date(eventDataById?.eventDate);
      const diff = today.getTime() - eventdate.getTime();
      const days = Math.round(diff / 86400000);
      setEventAge(days);
    }
  }, [eventDataById]);

  // Function for Api call to delete image
  const handleDeleteImage = (ids: string[]) => {
    Swal.fire({
      title: "Warning!",
      // text: `Are you sure you want to permanently delete ${name}?`,
      text: `Are you sure you want to permanently delete image?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await ApiService.deleteImageDataById(ids);
          console.log("response", response);
          if (response.statusCode === 200) {
            const { orderByColumnName, sortColumnDir } = filterArg;
            getDynamicImageList(searchText, orderByColumnName, sortColumnDir);
            setIsCheck(isCheck.filter((item) => item !== ids[0]));
            console.log(isCheck, "hiiiiiiiiiiiii");

            setIsLoading(false);
            setIsOpen(true);
            setAlertType("success");
            setResponseMessage(response.message);
            // if (isCheckAll) setIsCheck(imageListData.map((item: any) => item.id))
            ids.length > 1 && setIsCheck([]);
          }
        } catch (error: any) {
          console.log("error", error);
          setIsLoading(false);
          setIsOpen(true);
          setAlertType("error");
          setResponseMessage(error.data[0].description);
        }
      }
    });
  };

  // function for Api call of getImageDataById and show image in full screen
  const handleFullScreenImage = async (id: string) => {
    setIsFullScreen(true);

    try {
      const response = await ApiService.getImageDataById(id);
      console.log("response", response);
      setImageDetailById(response.data.data);
    } catch (error: any) {
      console.log("error", error);
    }
  };

  // useEffect to set tag Name
  useEffect(() => {
    if (eventDataById && eventTags) {
      const EventTagDataById = eventTags.filter(
        (i: any) => i.id === eventDataById.tagId
      );
      const EventTagNameById = EventTagDataById.map((i: any) => i.name);
      setTagNameToShow(EventTagNameById[0]);
    }
  }, [eventDataById]);

  // useEffect for api call to delete tag
  const handleDeleteTag = (tagId: any) => {
    deleteEventTag(tagId);
    if (eventDataById?.tagId === tagId) {
      setIsDeleteActiveTag(true);
    } else {
      setIsDeleteActiveTag(false);
    }
  };

  // Function for api call to add or update event tag
  const addOrUpdateEventTag = (tagId: number) => {
    if (isUpdateTag) {
      addOrUpdateEventTags(tagId, userDetail.userId, eventTagName);
    } else {
      addOrUpdateEventTags(0, userDetail.userId, eventTagName);
    }
  };

  // setTimeout to change copy text
  useEffect(() => {
    setTimeout(() => {
      setCopyMsgShow(false);
    }, 3000);
  }, [copyMsgShow]);

  return (
    <div>
      {isImageUpload && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isImageUpload}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div>
            <CircularProgressLoader color="inherit" />
          </div>
          <br />
          <div>
            {state > 0 && <label>{`Image Uploading...  ${state}%`}</label>}
          </div>
        </Backdrop>
      )}
      <Header />
      {!isLoading ? (
        <div>
          <div className="flex justify-between items-center pt-6 bg-[#fff] z-[9] px-[3rem] pb-6 border-b border-[#f5f5f5] max-lg:px-10 max-sm:px-4 max-lg:flex-wrap sticky top-[67px]">
            <div className="flex items-center">
              <i
                className="fas fa-chevron-left pr-4 cursor-pointer text-xl"
                onClick={() => navigate("/dashboard")}
              />
              <h2 className="text-3xl font-semibold text-black max-sm:text-lg">
                {eventDataById?.eventName}
              </h2>
              <Tooltip title="Edit Event Name" placement="bottom-end" arrow>
                <div onClick={() => setOpenEventNameModal(true)}>
                  <i className="fas fa-pencil-alt text-lg ml-3 cursor-pointer"></i>
                </div>
              </Tooltip>
            </div>
            <div className="flex items-center gap-4 max-sm:flex-wrap max-sm:mt-4">
              <ClickAwayListener
                onClickAway={() => eventOpen && setEventOpen(false)}
              >
                <div
                  className="cursor-pointer relative"
                  onClick={() => setEventOpen(!eventOpen)}
                >
                  <Tooltip title="More" placement="bottom-end" arrow>
                    <i className="fas fa-ellipsis-h" />
                  </Tooltip>
                  <div
                    style={{ display: eventOpen ? "block" : "none" }}
                    className="absolute top-[20px] bg-[#fff] shadow-[0_4px_12px_0_rgba(0,0,0,0.2)] rounded p-3 w-48 right-0 z-10"
                  >
                    <div className="flex flex-col">
                      <div
                        className="text-sm text-black font-medium mb-1 px-1 py-1 hover:bg-[#f1f1f1]"
                        onClick={() =>
                          navigate(`/dashboard/events/${id}/settings`)
                        }
                      >
                        Event Settings
                      </div>
                      <div
                        className="text-sm text-black font-medium px-1 py-1 hover:bg-[#f1f1f1]"
                        onClick={() =>
                          handleDeleteEvent(
                            id as string,
                            eventDataById.eventName,
                            () => navigate("/dashboard")
                          )
                        }
                      >
                        Delete Event
                      </div>
                    </div>
                  </div>
                </div>
              </ClickAwayListener>
              <button
                className="button-border text-[#4854e2] px-6 font-semibold text-base w-[10rem]"
                onClick={() => navigate(`/share/${id}`)}
              >
                Share
              </button>
              {eventDataById?.isPublish ? (
                <button
                  className="bg-[#4854e2] text-white px-2 py-2.5 font-normal text-base w-[10rem] relative"
                  onClick={() => setModalPublish(true)}
                >
                  Un-publish Event
                </button>
              ) : (
                <button
                  className="bg-[#4854e2] text-white px-2 py-2.5 font-normal text-base w-[10rem]"
                  // onClick={() => handlePublishEvent(true)}
                  onClick={() => {
                    if (imageListData.length === 0) {
                      setIsOpen(true);
                      setAlertType("error");
                      setResponseMessage(
                        "Please upload at least one image to publish event"
                      );
                    } else {
                      handleUpdateEventData(
                        id as string,
                        { IsPublish: true },
                        ""
                      );
                    }
                  }}
                  // disabled={imageListData.length === 0}
                >
                  Publish Event
                </button>
              )}
            </div>
          </div>
          <div className="flex items-start pt-6">
            <div className="w-[20%] mr-6 shadow-sidebar pt-7 px-[3rem] min-h-[calc(100vh_-_184px)] sticky top-[184px] bg-[#f1f1f1] max-2xl:pr-4  max-xl:px-[1rem] max-lg:w-[30%] max-[991px]:mr-2 max-md:mr-2 max-[430px]:mr-3 max-sm:w-[40%]">
              <div>
                <div className="relative rounded-lg w-full aspect-video overflow-hidden">
                  <a
                    onClick={() => navigate(`/dashboard/events/${id}/settings`)}
                    className="absolute cursor-pointer left-0 right-0 bottom-0 bg-[#4854e2] text-white opacity-80 text-center p-2 uppercase text-sm tracking-wide max-[401px]:text-xs max-[401px]:p-1"
                  >
                    Change Cover
                  </a>
                  {eventDataById?.coverImage && (
                    <img className="w-full h-auto object-cover aspect-video"
                      src={`${API_URL}/${eventDataById?.coverImage}`}
                      alt={eventDataById.eventName}
                    />
                  )}
                </div>

                <div className="flex justify-between mt-2">
                  <div className="relative flex items-center">
                    <span className="h-[9px] w-[9px] rounded-full bg-[#ababab] mr-2"></span>
                    <ClickAwayListener
                      onClickAway={() => show && setShow(false)}
                    >
                      <div>
                        <h5
                          onClick={() => setShow(!show)}
                          className="text-[#767676] text-[15px] font-normal leading-[20px] cursor-pointer max-[991px]:text-xs"
                        >
                          {tagNameToShow ? tagNameToShow : "Add Tag"}
                        </h5>
                        <div
                          style={{ display: show ? "block" : "none" }}
                          className="absolute top-[25px] bg-[#fff] shadow-[0_4px_12px_0_rgba(0,0,0,0.2)] rounded z-10 w-[13rem]"
                        >
                          <div className="max-h-28 overflow-y-scroll border-b">
                            {eventTags &&
                              eventTags.map((tag: any) => (
                                <div
                                  className="flex relative px-4 py-2 hover:bg-[#ddd]"
                                  key={tag.id}
                                >
                                  <span className="rounded-full w-3 h-3 mr-2 mt-2 bg-[#4854e2]" />
                                  <div className="flex justify-between items-center w-full">
                                    <div
                                      onClick={() => {
                                        setShow(!show);
                                        handleUpdateEventData(
                                          id as string,
                                          { TagId: tag.id },
                                          ""
                                        );
                                        setIsDescriptionChanged(false);
                                      }}
                                      className="text-sm text-black mt-1 cursor-pointer"
                                    >
                                      {" "}
                                      {tag.name}
                                    </div>
                                    <div>
                                      <i
                                        className="fas fa-trash-alt ml-3 text-xs text-[#666] cursor-pointer"
                                        onClick={() => {
                                          handleDeleteTag(tag.id);
                                          setShow(!show);
                                        }}
                                      ></i>
                                      <i
                                        className="fas fa-edit ml-3 text-xs text-[#666] cursor-pointer"
                                        onClick={() => {
                                          setOpenAddTagModel(true);
                                          setIsUpdateTag(true);
                                          setEventTagId(tag.id);
                                          setEventTagName(tag.name);
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div
                            onClick={() => setOpenAddTagModel(!openAddTagModel)}
                            className="font-semibold text-base underline-offset-4 px-4 py-2 hover:bg-[#4854e240] text-center cursor-pointer"
                          >
                            Customize
                          </div>
                          {openAddTagModel && (
                            <div>
                              <input
                                type="text"
                                value={eventTagName}
                                placeholder="Add New tag"
                                onChange={(e) =>
                                  setEventTagName(e.target.value)
                                }
                                className="w-full py-2 px-1.5 input-box-shadow text-xs text-[6666] font-normal focus:outline-transparent rounded"
                              />
                              <div className="flex items-center justify-center gap-4 py-2 px-1.5 text-center mx-auto">
                                <a
                                  className="text-sm font-bold underline underline-offset-1 text-[#4854e2] cursor-pointer"
                                  onClick={() => {
                                    eventTagName !== "" &&
                                      addOrUpdateEventTag(eventTagId);
                                    setEventTagName("");
                                    setShow(!show);
                                    setOpenAddTagModel(!openAddTagModel);
                                    setIsUpdateTag(false);
                                  }}
                                >
                                  {isUpdateTag ? "Update tag" : "Add tag"}
                                </a>
                                <a className="text-sm font-bold underline underline-offset-1 text-[#00f3dd] cursor-pointer">
                                  Cancel
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </ClickAwayListener>
                  </div>
                  <div className="text-[#767676] text-[15px] font-normal leading-[20px] max-[991px]:text-xs">
                    {eventAge} days
                  </div>
                  <div className="text-[#767676] text-[15px] font-normal leading-[20px] max-[991px]:text-xs">
                    {imageListData.length > 0 && imageListData.length} photos
                  </div>
                </div>
                <div>
                  <div className="flex justify-between mt-6">
                    <div className="text-[#000] text-xs font-normal leading-[20px]">
                      Add Description
                    </div>
                    <div
                      onClick={() => {
                        handleUpdateEventData(
                          id as string,
                          { Description: eventDescription },
                          ""
                        );
                        setIsDescriptionChanged(true);
                      }}
                      className="text-[#4854e2] text-sm font-normal leading-[20px] cursor-pointer relative"
                    >
                      {isUpdateEvent && isDescriptionChanged && (
                        <CircularProgress
                          thickness={2}
                          size="sm"
                          style={{
                            marginLeft: "10px",
                            lineHeight: "0",
                            position: "absolute",
                            left: "-40px",
                          }}
                        />
                      )}
                      Update
                    </div>
                  </div>
                  <div className="mt-2">
                    <textarea
                      onChange={(e) => setEventDescription(e.target.value)}
                      value={eventDescription ? eventDescription : ""}
                      rows={3}
                      placeholder="Maximum 250 characters"
                      className="w-full p-2 placeholder:text-xs outline outline-transparent focus:outline-transparent input-box-shadow rounded-lg"
                    ></textarea>
                  </div>
                </div>

                <div>
                  <div className="flex justify-between mt-6 border-b border-[#ddd] pb-1">
                    <div className="text-[#000] text-xs leading-[20px] font-semibold">
                      Event Code: {eventDataById?.eventCode}
                    </div>
                    <div
                      onClick={() => {
                        copy(eventDataById?.eventCode);
                        setCopyMsgShow(true);
                      }}
                      className="text-[#4854e2] text-sm font-normal leading-[20px] cursor-pointer"
                    >
                      {copyMsgShow ? "Copied" : "Copy"}
                    </div>
                  </div>
                  {/* Don't remove this commented code */}
                  {/* <div className="mt-2 flex items-center justify-between gap-4 pt-1 max-2xl:flex-wrap">
                  <button className="button-border text-[#4854e2] px-6 font-semibold text-base w-full">
                    Preview
                  </button>
                  <button className="button-border text-[#4854e2] px-6 font-semibold text-base w-full">
                    Insights
                  </button>
                </div> */}
                </div>

                {/* Don't remove this commented code */}
                {/* <div>
                <div className="flex justify-between mt-4">
                  <div className="text-[#000] text-xs leading-[20px] font-semibold">
                    Sub-Events
                  </div>
                  <div className="text-[#4854e2] text-sm font-semibold leading-[20px] cursor-pointer">
                    +Add New
                  </div>
                </div>
                <div className="mt-1 flex items-center justify-between gap-4">
                  <ul className="w-full">
                    {arr.map((index) => {
                      return (
                        <li
                          key={index}
                          className="flex items-center text-black justify-between py-2 my-1 pl-2 pr-2 hover:bg-[#4854e2] hover:text-white max-[450px]:pl-2 "
                        >
                          <div className="flex items-center">
                            <i className="fas fa-arrows-alt text-sm"></i>
                            <p className="ml-2 text-sm font-semibold">
                              25 Apr 2023
                            </p>
                          </div>
                          <div className="flex items-center">
                            <div className="bg-[#ddd] rounded text-sm font-semibold  h-7 w-7 flex items-center justify-center cursor-pointer mr-3">
                              4
                            </div>

                            <div
                              className="relative flex items-center cursor-pointer"
                              onClick={() => {
                                console.log("index", index);
                                setActiveIndex(index);
                                setOpen(!open);
                              }}
                            >
                              <i className="fas fa-ellipsis-v cursor-pointer"></i>
                              <div
                                style={{
                                  display:
                                    open && activeIndex === index
                                      ? "block"
                                      : "none",
                                }}
                                className="absolute top-[20px] bg-[#fff] shadow-[0_4px_12px_0_rgba(0,0,0,0.2)] rounded p-3 w-48 right-0 z-10"
                              >
                                <div className="flex flex-col">
                                  <div className="text-sm text-black font-medium mb-1 px-1 py-1 hover:bg-[#f1f1f1]">
                                    Make Private
                                  </div>
                                  <div className="text-sm text-black font-medium mb-1 px-1 py-1 hover:bg-[#f1f1f1]">
                                    Rename
                                  </div>
                                  <div className="text-sm text-black font-medium mb-1 px-1 py-1 hover:bg-[#f1f1f1]">
                                    Delete
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="border-t border-[#ddd]">
                  <div className="flex items-center text-black justify-between py-2 my-2 pl-4 pr-6 hover:bg-[#4854e2] hover:text-white max-[450px]:pl-2 ">
                    <div className="flex items-center">
                      <p className="ml-2 text-sm font-semibold">
                        Guest Uploads
                      </p>
                    </div>
                    <div className="flex items-center">
                      <div className="bg-[#ccc] rounded text-sm font-semibold  h-7 w-7 flex items-center justify-center cursor-pointer mr-3">
                        0
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>

            <div className="w-[80%] max-lg:w-[70%] pr-[3rem] max-lg:px-10 max-[991px]:pl-4 max-sm:px-4 max-sm:w-[60%]">
              <div className="flex items-center justify-between max-lg:flex-wrap">
                <div className="flex justify-between items-center flex-wrap gap-4">
                  <div className="inputsearch relative ">
                    <i className="fas fa-search absolute top-[12px] left-[10px] text-[#707070]"></i>
                    <input
                      name="search"
                      autoFocus
                      placeholder="Search Events"
                      value={searchText}
                      onChange={(e) => {
                        const { orderByColumnName, sortColumnDir } = filterArg;
                        setSearchText(e.target.value);
                        getDynamicImageList(
                          e.target.value,
                          orderByColumnName,
                          sortColumnDir
                        );
                      }}
                      className="bg-[#f3f3f3] px-4 pl-9 py-2 text-sm border border-[#e3e3e3] placeholder:text-sm  outline outline-transparent focus:outline-transparent w-[22rem] placeholder-[#7a7a7a] max-sm:w-full"
                    />
                  </div>
                  <div className="flex items-center flex-wrap gap-4 max-md:mt-4">
                    <div className="sorting-menu">
                      <div>
                        <FormControl sx={{ m: 1, minWidth: 163 }}>
                          <Select
                            value={filterType}
                            onChange={handleApplyFilter}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            {/* <MenuItem value="">
                              <span>Sort By</span>
                            </MenuItem> */}
                            <ListSubheader>Sort By</ListSubheader>
                            <ListSubheader>Date Created</ListSubheader>
                            <MenuItem value="1">New - Old</MenuItem>
                            <MenuItem value="2">Old - New</MenuItem>
                            <ListSubheader>File Name</ListSubheader>
                            <MenuItem value="3">A - Z</MenuItem>
                            <MenuItem value="4">Z - A</MenuItem>
                            <ListSubheader>File Size</ListSubheader>
                            <MenuItem value="5">Smallest - Largest</MenuItem>
                            <MenuItem value="6">Largest - Smallest</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    {/* <div className="max-sm:mt-3 sorting-menu">
                      <FormControl sx={{ m: 1, minWidth: 163 }}>
                        <Select
                          value={age}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">
                            <span>Filter By</span>
                          </MenuItem>
                          <MenuItem value={10}>Show All</MenuItem>
                          <MenuItem value={20}>Private Images</MenuItem>
                          <MenuItem value={30}>Public Images</MenuItem>
                          <MenuItem value={30}>video</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}
                  </div>
                </div>
                <div className="flex flex-end items-center gap-4 max-lg:mt-3 max-sm:flex-wrap">
                  {/* <div className="w-20">
                    <div className="flex items-center justify-between text-xs itesm-center">
                      <PrettoSlider
                        aria-label="Restricted values"
                        defaultValue={50}
                        valueLabelDisplay="off"
                        step={null}
                        marks={marks}
                      />
                    </div>
                  </div> */}
                  {imageListData.length < 1 && (
                    <>
                      {/* <Tooltip title="Tile View" placement="bottom-end" arrow> */}
                      <div
                        // onClick={() => setView("Tile")}
                        className="bg-[#f3f3f3] border border-[#e3e3e3] cursor-pointer h-[39px] w-[39px] p-1 flex items-center justify-center"
                      >
                        <img src={tiles} alt="tiles" className="w-5 h-auto" />
                      </div>
                      {/* </Tooltip> */}
                      {/* <Tooltip title="List View" placement="bottom-end" arrow> */}
                      <div
                        // onClick={() => setView("List")}
                        className="bg-[#f3f3f3] border border-[#e3e3e3] cursor-pointer h-[39px] w-[39px] p-1 flex items-center justify-center"
                      >
                        <i className="fas fa-list-ul text-base"></i>
                      </div>
                      {/* </Tooltip> */}
                    </>
                  )}

                  {imageListData.length > 0 && (
                    <>
                      <Tooltip title="Tile View" placement="bottom-end" arrow>
                        <div
                          onClick={() => setView("Tile")}
                          className="bg-[#f3f3f3] border border-[#e3e3e3] cursor-pointer h-[39px] w-[39px] p-1 flex items-center justify-center"
                        >
                          <img src={tiles} alt="tiles" className="w-5 h-auto" />
                        </div>
                      </Tooltip>
                      <Tooltip title="List View" placement="bottom-end" arrow>
                        <div
                          onClick={() => setView("List")}
                          className="bg-[#f3f3f3] border border-[#e3e3e3] cursor-pointer h-[39px] w-[39px] p-1 flex items-center justify-center"
                        >
                          <i className="fas fa-list-ul text-base"></i>
                        </div>
                      </Tooltip>
                    </>
                  )}
                  {/* <div
                  className="bg-[#f3f3f3] border border-[#e3e3e3] cursor-pointer h-[39px] w-[39px] p-1 flex items-center justify-center"
                  onClick={() => setModalOpen(true)}
                >
                  <i className="fas fa-upload text-base"></i>
                </div> */}
                  <div>
                    <ClickAwayListener onClickAway={() => setIsAddMore(false)}>
                      <div className="relative flex items-center cursor-pointer">
                        <div
                          onClick={() => setIsAddMore(!isAddMore)}
                          className="text-black text-sm bg-[#f3f3f3] border border-[#e3e3e3] cursor-pointer h-[39px] py-1 px-2 flex items-center font-medium"
                        >
                          Add More
                          <i
                            className={`fas ${
                              isAddMore ? "fa-sort-up" : "fa-sort-down"
                            } ml-2 mt-[-5px] leading-[0] text-base text-[#000]`}
                          />
                        </div>
                        <div
                          style={{ display: isAddMore ? "block" : "none" }}
                          className="absolute top-[40px] bg-[#fff] shadow-[0_4px_12px_0_rgba(0,0,0,0.2)] rounded p-2 w-[10rem] right-0 z-10"
                        >
                          <div className="flex flex-col">
                            <div
                              className="text-sm text-black font-medium px-1 py-1 hover:bg-[#f1f1f1]"
                              onClick={() => setIsAddImage(true)}
                            >
                              Images
                            </div>
                          </div>
                        </div>
                      </div>
                    </ClickAwayListener>
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <div className="text-xl mb-8 font-semibold text-black leading-[26px] flex items-center">
                  {moment(eventDataById?.eventDate).format("D MMM YYYY")}
                  {imageListData.length > 0 && (
                    <span className="ml-2 bg-[#ddd] rounded text-sm font-semibold  h-6 w-8 flex items-center justify-center cursor-pointer mr-3">
                      {/* {eventDataById?.lstImage.length} */}
                      {imageListData.length > 0 && imageListData.length}
                    </span>
                  )}
                </div>
                {!isLoading ? (
                  <>
                    {view === "Tile" && (
                      <div className="mb-[20px]">
                        {/* <PhotoAlbum photos={galleryPhoto} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} /> */}
                        <Lightbox
                          slides={galleryPhoto}
                          open={index >= 0}
                          index={index}
                          close={() => setIndex(-1)}
                          // enable optional lightbox plugins
                          plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                        />

                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                          {imageListData.length > 0 &&
                            imageListData.map((row: any, index: number) => {
                              return (
                                <div key={index}>
                                  <div className="relative">
                                    <div
                                      className={`relative image-blog ${
                                        isCheck.length > 0 &&
                                        isCheck.includes(row.id)
                                          ? "image-blog1"
                                          : ""
                                      } h-full`}
                                      onClick={() => {
                                        setIndex(index);
                                        // setActiveImage(index);
                                        // handleFullScreenImage(row.id);
                                      }}
                                    >
                                      <div className="image-hover h-full">
                                        {/* <img
                                      src={`${API_URL}/${row.imageLink}`}
                                      alt={row.imageName}
                                      className="rounded-md h-full object-cover w-full"
                                    /> */}
                                        <LazyLoadImage
                                          key={index}
                                          src={`${API_URL}/${row.imageLink}`}
                                          alt={row.imageName}
                                          width={"100%"}
                                          height={"100%"}
                                          scrollPosition={scrollPosition}
                                          className="img-lazy rounded-md h-full object-cover w-full object-top aspect-video"
                                          // delayTime={10000}
                                          // placeholderSrc={placeholder}
                                          effect="blur" // opacity | black-and-white
                                          // afterLoad={(load: any) => console.log('load', load)}
                                          // onClick={ }
                                        />
                                      </div>
                                    </div>
                                    <div className="absolute top-0 right-0 left-0 opacity-0 image-icon">
                                      <div className="flex justify-between items-center p-4">
                                        <div>
                                          <input
                                            id={row.id.toString()}
                                            type="checkbox"
                                            onChange={handleCheckboxChange}
                                            checked={isCheck.includes(row.id)}
                                            className="cursor-pointer"
                                          />
                                        </div>
                                        <div className="flex gap-5">
                                          {/* <i
                                        onClick={() => handleUpdateEventData(id as string, { CoverImage: `${API_URL}/${row.imageLink}` }, `${API_URL}/${row.imageLink}`)}
                                        className="fas fa-image cursor-pointer text-white text-lg"
                                      /> */}
                                          <i
                                            onClick={() =>
                                              handleDeleteImage([row.id])
                                            }
                                            className="fas fa-trash-alt cursor-pointer text-white text-lg"
                                          />
                                          <i
                                            // onClick={() => saveAs(obama, "obama")}
                                            onClick={() =>
                                              saveAs(
                                                `${API_URL}/${row.imageLink}`,
                                                row.imageName
                                              )
                                            }
                                            className="fas fa-arrow-down cursor-pointer text-white text-lg"
                                          />
                                          {/* <i className="fas fa-eye-slash cursor-pointer text-white text-lg" /> */}
                                          <i
                                            onClick={() => {
                                              setImageUrl(
                                                `${API_URL}/${row.imageLink}`
                                              );
                                              setSharemodal(true);
                                            }}
                                            className="fas fa-share-alt cursor-pointer text-white text-lg"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}

                          {imageListData.length > 0 && isCheck.length > 0 && (
                            <BootomImageSelectionBar
                              isCheck={isCheck}
                              setIsCheck={setIsCheck}
                              imageListData={imageListData}
                              onDelete={() => handleDeleteImage(isCheck)}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    {/* reuse list image code */}
                    {view === "List" && imageListData.length > 0 && (
                      <div className="relative">
                        <div className="flex flex-row items-center gap-5 bg-[#767676] px-6 py-3 overflow-x-auto">
                          <div className="basis-[10%]">
                            <input
                              type="checkbox"
                              id="selectAll"
                              onChange={handleSelectAllCheckbox}
                              checked={isCheckAll}
                              className="cursor-pointer"
                            />
                          </div>
                          <div className="basis-[30%] text-base text-white font-normal leading-[16px]">
                            Name
                          </div>
                          <div className="basis-[15%] text-base text-white font-normal leading-[16px]">
                            Date Created
                          </div>
                          <div className="basis-[15%] text-base text-white font-normal leading-[16px]">
                            File Size
                          </div>
                          <div className="basis-[30%] text-base text-white font-normal leading-[16px]"></div>
                        </div>
                        <div>
                          <div className="flex flex-col">
                            {imageListData.length > 0 &&
                              imageListData.map((row: any, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    className="py-2 border-b border-[#ddd] bg-[#f1f1f1] overflow-x-auto"
                                  >
                                    <div className="flex flex-row gap-5 items-center py-4 px-6">
                                      <div className="basis-[10%]">
                                        <label>
                                          <input
                                            id={row.id.toString()}
                                            type="checkbox"
                                            onChange={handleCheckboxChange}
                                            checked={isCheck.includes(row.id)}
                                            className="cursor-pointer"
                                          />
                                        </label>
                                      </div>
                                      <div className="basis-[30%] flex items-center">
                                        <div className="relative w-36 rounded overflow-hidden h-14">
                                          <img
                                            src={`${API_URL}/${row.imageLink}`}
                                            className="w-full h-14 object-cover"
                                            alt={row.imageName}
                                          />
                                        </div>
                                        <div className="px-3 break-all w-full items-start">
                                          {row.imageName}
                                        </div>
                                      </div>
                                      <div className="basis-[15%]">
                                        {moment(row.createdDateTime).format(
                                          "D MMM YYYY"
                                        )}
                                      </div>
                                      <div className="basis-[15%]">
                                        {row.imageSize} kb
                                      </div>
                                      <div className="basis-[30%]">
                                        <div className="flex gap-5 items-center justify-center">
                                          {/* <i className="fas fa-eye-slash cursor-pointer text-[#333] text-base" /> */}
                                          <i
                                            onClick={() => {
                                              setImageUrl(
                                                `${API_URL}/${row.imageLink}`
                                              );
                                              setSharemodal(true);
                                            }}
                                            className="fas fa-share-alt cursor-pointer text-[#333] text-base"
                                          />
                                          <i
                                            // onClick={() => saveAs(obama1, "obama1")}
                                            onClick={() =>
                                              saveAs(
                                                `${API_URL}/${row.imageLink}`,
                                                row.imageName
                                              )
                                            }
                                            className="fas fa-arrow-down cursor-pointer text-[#333] text-base"
                                          />
                                          <i
                                            onClick={() =>
                                              handleDeleteImage([row.id])
                                            }
                                            className="fas fa-trash-alt cursor-pointer text-[#333] text-base"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        {imageListData.length > 0 && isCheck.length > 0 && (
                          <BootomImageSelectionBar
                            isCheck={isCheck}
                            setIsCheck={setIsCheck}
                            imageListData={imageListData}
                            onDelete={() => handleDeleteImage(isCheck)}
                          />
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <Loader />
                )}
              </div>

              <div className="flex justify-center mb-[30px]">    
              <Stack spacing={2}>
                <Pagination count={10} shape="rounded" />
              </Stack>
              </div>    
              {/* without image display div */}
              {imageListData.length === 0 && (
                <div className="mt-8 border border-[#ddd] px-8 py-24 rounded">
                  <div className="flex flex-col items-center justify-center">
                    <div className="mb-1">
                      <img
                        alt="start"
                        src={start}
                        className="w-[100px] h-auto"
                      />
                    </div>
                    <div className="text-center">
                      <h3 className="text-2xl font-semibold text-black mb-1">
                        Get Started!
                      </h3>
                      <p className="text-base text-[#666] font-medium">
                        Add photos to start editing and sharing them instantly.
                      </p>
                    </div>
                    <div className="flex gap-6 mt-4 max-sm:flex-wrap max-sm:justify-center">
                      <button
                        className="button-border text-[#4854e2] px-2 font-semibold text-base w-[10rem] input-box-shadow"
                        onClick={() => setIsAddImage(true)}
                      >
                        Add Images
                      </button>
                      {/* <button className="button-border text-[#4854e2] px-2 font-semibold text-base w-[10rem] input-box-shadow">
                      Add Video Link
                    </button> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* {isFullScreen && <div className="w-full h-full">
            <Fade>
              {eventData?.lstImage.map((row: any, index: number) => {
                return (
                  <div key={index} className="each-fade">
                    <img
                      // src={obama}
                      src={`${API_URL}/${row}`}
                      alt='aa'
                    // className="mx-auto  object-contain h-[840px]"
                    />
                  </div>
                )
              })}
            </Fade>
          </div>} */}
          </div>
          <PhotoGallery
            imageList={imageListData}
            setGalleryPhoto={setGalleryPhoto}
          />
        </div>
      ) : (
        <Loader />
      )}

      {/* modal un publish event material ui */}
      <UnpublishEventModal
        open={modalPublish}
        onClose={() => setModalPublish(false)}
        name={eventDataById?.eventName}
        onSubmit={() => {
          handleUpdateEventData(id as string, { IsPublish: false }, "");
          setModalPublish(false);
        }}
      />

      {/* modal un update event name material ui */}
      <UpdateEventNameModal
        open={openEventNameModal}
        onClose={() => setOpenEventNameModal(false)}
        eventName={eventName}
        setEventName={setEventName}
        isLoading={isUpdateEvent}
        onSubmit={() => {
          handleUpdateEventData(id as string, { EventName: eventName }, "");
          setOpenEventNameModal(false);
        }}
      />

      {/* modal upload folder material ui */}
      <UploadImageModal
        state={state}
        open={isAddImage}
        onClose={() => {
          if (!isLoading) {
            setIsAddImage(false);
            setFile([]);
          }
        }}
        file={file}
        setFile={setFile}
        isLoading={isImageUpload}
        onSubmit={() => {
          file && handleUploadEventImages(file);
          setFile([]);
          setIsAddImage(false);
        }}
      />

      {/* modal full screen image material ui */}
      <ShowFullScreenImage
        open={isFullScreen}
        onClose={() => {
          setActiveImage(0);
          setIsFullScreen(false);
          setImageDetailById(null);
        }}
        imageDetailById={imageDetailById}
        activeImage={activeImage}
        displayPrevious={() => {
          if (activeImage > 0) {
            handleFullScreenImage(imageListDataId[activeImage - 1]);
            setActiveImage(activeImage - 1);
          }
        }}
        imageListDataId={imageListDataId}
        displayNext={() => {
          if (activeImage < imageListDataId.length - 1) {
            handleFullScreenImage(imageListDataId[activeImage + 1]);
            setActiveImage(activeImage + 1);
          }
        }}
      />

      {/* share image link modal */}
      <ShareModel
        open={sharemodal}
        onClose={() => {
          setSharemodal(false);
        }}
        imageUrl={imageUrl}
      />
    </div>
  );
};

export default trackWindowScroll(Eventdashboard);

export const BootomImageSelectionBar = ({
  isCheck,
  setIsCheck,
  imageListData,
  onDelete,
}: any) => {
  return (
    <div className="fixed flex w-full bottom-[20px] left-0 right-0 mx-auto ">
      <div className="w-[20%] mr-3 max-lg:w-[40%] max-sm:w-0"></div>
      <div className="w-[80%] input-box-shadow p-4 h-[80px] bg-white flex-col flex justify-center  ml-11 mr-16 max-lg:w-[60%] max-lg:ml-7  max-sm:w-[100%] max-sm:ml-4 max-sm:mr-4">
        <div className="flex items-center justify-between">
          <div className="flex gap-4 items-center">
            <div>{isCheck.length} Image Selected</div>
            <button
              onClick={() =>
                setIsCheck(imageListData.map((item: any) => item.id))
              }
              className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base"
            >
              select all
            </button>
            <button
              onClick={() => setIsCheck([])}
              className="text-sm font-semibold text-black leading-[16px] underline tracking-wide"
            >
              De-select all
            </button>
          </div>
          <div className="flex gap-4">
            {/* <i className="fas fa-eye-slash cursor-pointer text-[#333] text-base" /> */}
            <i
              // onClick={() => handleDeleteImage(isCheck, 'abc')}
              onClick={onDelete}
              className="fas fa-trash-alt cursor-pointer text-[#333] text-base"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
