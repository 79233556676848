import React, { Dispatch, SetStateAction } from 'react';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    alertType: AlertColor,
    responseMessage: string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar = ({ isOpen, setIsOpen, alertType, responseMessage }: Props) => {

    // function to close the snackbar
    const handleClose = () => {
        setIsOpen(false);
    };

    // function which return response message to display
    const message = () => {
        return (responseMessage)
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={isOpen}
            autoHideDuration={3000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={alertType} sx={{ width: "100%" }}>
                {message()}
            </Alert>
        </Snackbar>
    )
}

export default CustomSnackbar