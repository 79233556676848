/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// navigation
import { useNavigate } from "react-router-dom"

// Images and Logos
import share from "../assets/icons/share.png";
import screensort from "../assets/icons/screensort.png";
import imgDownload from "../assets/icons/imgDownload.png";
import ai from "../assets/icons/ai.png";
import facebookIcon from "../assets/icons/facebook.png";
import recognizationImg from "../assets/image/aiRecognition.png";
import safeSecurityImg from "../assets/image/safeSecure.png";
// import ImageBackground from "../assets/image/algoShareAbovePricing.webp";
import sharingBg from "../assets/image/sharingBg.png";

const Home = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className="bg-white py-4 z-10">
        <div className="container-2xl mx-auto px-[3rem] max-lg:px-10 max-sm:px-4">
          <div className="grid md:grid-cols-2 gap-4 py-10">
            <div>
              <div
                className="text-black text-2xl font-bold leading-[24px]"
              >
                PhotoFace
              </div>
            </div>
            <div className="flex justify-end">
              <button onClick={() => navigate("/login")} className="px-[2px] py-[2px] rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-1">
                <div className="px-8 py-[10px] rounded-full bg-white back">
                  <h2 className="text-md text-black font-bold rounded-full uppercase">
                    GET Started
                  </h2>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden pt-[50px] lg:block xl:block 2xl:block lg:container xl:container 2xl:container lg:mx-auto xl:mx-auto 2xl:mx-auto px-4 2xl:px-[70px]">
        <div className="grid md:grid-cols-2 gap-2 py-10">
          <div className="">
            <h3 className="font-medium font-oswald text-4xl md:text-6xl mb-5 uppercase">
              SHARE IMAGES USING FACE RECOGNITION
            </h3>
            <h4 className="font-light text-2xl">
              Wow your clients and get 8x more visibility.
            </h4>
            <div className="flex justify-start mt-5">
              <button onClick={() => navigate("/login")} className="px-[2px] py-[2px] rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-1">
                <div className="px-8 py-[10px] rounded-full bg-white back">
                  <h2 className="text-md text-black font-bold rounded-full uppercase">
                    GET Started
                  </h2>
                </div>
              </button>
            </div>
          </div>
          <div>
            <video className="w-full rounded-3xl" autoPlay loop muted>
              <source
                src="https://img2.pixnif.com/AA_Videos/ASFSA.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
      <section className="bg-[#000] hidden lg:block xl:block 2xl:block mt-10 mb-10 ">
        <div className="pt-[4rem] pb-[4rem] lg:container xl:container 2xl:container  mx-auto">
          <h2 className="text-white font-oswald uppercase font-light md:text-[2.5rem] text-center md:mb-20">
            let your clients discover their images within seconds
          </h2>
          <div className="animationIcons grid grid-cols-2 md:grid-cols-4  ">
            <div className="text-center sideline">
              <img
                className="mx-auto mb-6"
                height="55px"
                width="55px"
                alt="share"
                src={share}
              />
              <div className="w-2/3 md:w-8/12 mx-auto text-white">
                Share event link with attendees via email, QR code or WhatsApp
              </div>
            </div>
            <div className="text-center sideline">
              <img
                className="mx-auto mb-6"
                height="55px"
                width="55px"
                alt="screensort"
                src={screensort}
              />
              <div className="w-2/3 md:w-8/12 mx-auto text-white">
                Attendees go to the link and take a selfie
              </div>
            </div>
            <div className="text-center sideline">
              <img
                className="mx-auto mb-6"
                height="55px"
                width="55px"
                alt="ai"
                src={ai}
              />
              <div className="w-2/3 md:w-8/12 mx-auto text-white">
                Our AI recognizes attendees with 99% accuracy and show them all
                their images
              </div>
            </div>
            <div className="text-center sideline">
              <img
                className="mx-auto mb-6"
                height="55px"
                width="55px"
                alt="imgDownload"
                src={imgDownload}
              />
              <div className="w-2/3 md:w-8/12 mx-auto text-white">
                Images can be printed or downloaded right from mobile
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hidden justify-center lg:flex xl:flex 2xl:flex lg:bg-white xl:bg-white   lg:flex-wrap  lg:overflow-hidden">
        <div className="md:pr-10 md:pt-[1.938rem] md:pb-[2.68rem] w-full text-center md:pl-[4rem] md:w-7/12 leading-[1.25] tracking-tight uppercase text-black font-oswald font-bold text-[44px]  undefined">
          Make your work reach the world. <br />
          Elevate your clients’ experience.
        </div>
      </section>
      <section className="hidden lg:block xl:block 2xl:block container mx-auto mt-10 md:pt-[94px]">
        <h3 className="uppercase font-oswald text-[40px] tracking-normal text-white text-center font-thin mb-10 md:mb-[4.35rem]">
          features you don’t want to miss
        </h3>
        <div className="grid  grid-cols-1  lg:grid-cols-2 gap-4 gap-y-10">
          <div className="text-center mb-5 relative boxhover">
            <div className="inline-block relative">
              <img className="mx-auto w-[80%]" alt="recognizationImg" src={recognizationImg} />
            </div>
            <div className="absolute bottom-0 w-full  bg-gradient-to-b from-transparent via-bb-500 to-black text-white flex flex-wrap justify-center pt-10">
              <p className="font-oswald w-full   flex justify-center items-center font-medium  text-[22px] uppercase  ">
                Blazing Fast AI Recognition
              </p>
              <div className="w-3/4 md:1/2 hoverSec max-h-0 overflow-hidden ease-in duration-500">
                <div className="feature-seperator my-2 mx-auto"></div>
                <p className="font-light  w-11/12">
                  Let your clients discover their pictures in a matter of
                  seconds with our cutting edge AI. 99.4 % accurate, 100%
                  awesome.
                </p>
              </div>
            </div>
          </div>
          <div className="text-center mb-5 relative boxhover">
            <div className="inline-block relative">
              <img className="mx-auto w-[80%]" alt="safeSecurityImg" src={safeSecurityImg} />
            </div>
            <div className="absolute bottom-0 w-full  bg-gradient-to-b from-transparent via-bb-500 to-black text-white flex flex-wrap justify-center pt-10">
              <p className="font-oswald w-full   flex justify-center items-center font-medium  text-[22px] uppercase  ">
                Safe, Secure &amp; Private
              </p>
              <div className="w-3/4 md:1/2 hoverSec max-h-0 overflow-hidden ease-in duration-500">
                <div className="feature-seperator my-2 mx-auto"></div>
                <p className="font-light  w-11/12">
                  Secured by S3 standards. Packed with features that ensure
                  maximum privacy.
                </p>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section
        className="mt-20 w-full bg-cover bg-no-repeat mb-28 hidden lg:block xl:block 2xl:block   "
        style={{ backgroundImage: "url(" + sharingBg + ")" }}
      >
        <div className="container flex flex-wrap mx-auto mb-32">
          <div className="w-6/12"></div>
          <div className="w-6/12 pl-10 mb-10 text-white">
            <h2 className="font-medium font-oswald mt-[72px] mb-2 text-[44px] uppercase">
              Experience smart sharing
            </h2>
            <h5 className="font-light text-2xl">
              Wow your clients with AI based image sharing
            </h5>

            <div className="flex justify-start mt-5">
              <div className="px-[2px] py-[2px] rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-1">
                <div className="px-2 py-[10px] rounded-full bg-black back cursor-pointer">
                  <h2 className="text-md text-white font-bold rounded-full uppercase">
                    <a
                      className="py-[0.5rem] px-[1.87rem] lg:py-[0.87rem] lg:px-[3.4rem] xl:py-[0.87rem] xl:px-[3.4rem] 2xl:py-[0.87rem] 2xl:px-[3.4rem] mb-5 md:mb-0 ml-0 mt-10 text-[10px] lg:text-[14px] font-bold uppercase grad-button2"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hidden lg:block xl:block 2xl:block container mx-auto mb-10 2xl:px-[70px]">
        <div className="flex flex-wrap justify-center">
          <div className="w-4/12">
            <ul>
              <li className="min-h-[340px]">
                <div className="w-8/12 text-[40px] font-oswald uppercase font-light">
                  Pricing plans for photoshare
                </div>
              </li>
              <li className="mb-7">Storage</li>
              <li className="mb-7">Max Image Size</li>
              <li className="mb-7">Face Searches</li>
              <li className="mb-7">Pre Registrations</li>
              <li className="mb-7">Insights</li>
              <li className="mb-7">Personalised Mobile App</li>
              <li className="mb-7">Concierge Service</li>
              <li className="mb-7">Digital Sales Store</li>
              <li className="mb-7">Digital Sales Assistant</li>
              <li className="mb-7">White Labelling</li>
              <li className="mb-7">Print Store with frames </li>
              <li className="mb-7">Live Chat Support</li>
              <li className="mb-7">
                Phone Support + Dedicated Support Manager
              </li>
              <li className="mb-7">Galleries</li>
              <li className="mb-7">Uncompressed Uploads</li>
              <li className="mb-7">Data Migration From Existing Galleries*</li>
              <li className="mb-7">Image limit per sub event</li>
            </ul>
          </div>
          <div className="flex justify-center w-2/12">
            <ul className="bg-[#eee] text-black w-[90%] rounded-3xl">
              <li className="min-h-[340px] relative">
                <p className="relative clip-text text-[40px] pt-12 font-oswald uppercase font-light text-black text-center mb-6">
                  <span className="cilpEffect">free</span>
                </p>
                <div className="text-center text-sm  ">
                  <span className="text-[44px] relative">
                    <span className="text-sm absolute top-1 left-[-10px]">
                      ₹
                    </span>
                    0
                  </span>
                  / month
                </div>
                <div className="text-center mt-2 text-sm ">30 day trial</div>
                <div>
                  <a
                    className=" mb-5 md:mb-0  mt-5 text-[10px] lg:text-[14px] font-bold uppercase grad-button px-6 py-2 mx-auto"
                    href="/register"
                  >
                    Subscribe
                  </a>
                </div>
                <div className="h-[1px] mt-12 w-11/12 mx-auto bg-[#656565]"></div>
              </li>
              <li className="mb-7 h-[24px] text-center">10GB</li>
              <li className="mb-7 h-[24px] text-center">5MB</li>
              <li className="mb-7 h-[24px] text-center">399 per event</li>
              <li className="mb-7 h-[24px] text-center">399 per event</li>
              <li className="mb-7 h-[24px] text-center">Basic</li>
              <li className="mb-7 h-[24px] text-center"></li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd" >
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px] text-center">99</li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px] text-center">999</li>
            </ul>
          </div>
          <div className="flex justify-center w-2/12">
            <ul className="bg-[#eee] text-black w-[90%] rounded-3xl">
              <li className="min-h-[340px] relative">
                <p className="relative clip-text text-[40px] pt-12 font-oswald uppercase font-light text-black text-center mb-6">
                  <span className="cilpEffect">Basic</span>
                </p>
                <div className="text-center text-sm  ">
                  <span className="text-[44px] relative">
                    <span className="text-sm absolute top-1 left-[-10px]">
                      ₹
                    </span>
                    1249
                  </span>
                  / month
                </div>
                <div className="text-center mt-2 text-sm ">
                  Billed at ₹ 14999 annualy
                </div>
                <div>
                  <a
                    className=" mb-5 md:mb-0  mt-5 text-[10px] lg:text-[14px] font-bold uppercase grad-button px-6 py-2 mx-auto"
                    href="/register"
                  >
                    Subscribe
                  </a>
                </div>
                <div className="h-[1px] mt-12 w-11/12 mx-auto bg-[#656565]"></div>
              </li>
              <li className="mb-7 h-[24px] text-center">100GB</li>
              <li className="mb-7 h-[24px] text-center">5MB</li>
              <li className="mb-7 h-[24px] text-center">399 per event</li>
              <li className="mb-7 h-[24px] text-center">399 per event</li>
              <li className="mb-7 h-[24px] text-center">Basic</li>
              <li className="mb-7 h-[24px] text-center">+₹20000/year</li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px] text-center">99</li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px] text-center">999</li>
            </ul>
          </div>
          <div className="flex justify-center w-2/12">
            <ul className="bg-[#eee] text-black w-[90%] rounded-3xl">
              <li className="min-h-[340px] relative">
                <div className="text-white bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 top-[-15px] absolute text-sm text-center p-1 px-5 left-[50%] translate-x-[-50%]  rounded-2xl">
                  Recommended
                </div>
                <p className="relative clip-text text-[40px] pt-12 font-oswald uppercase font-light text-black text-center mb-6">
                  <span className="cilpEffect">Advance</span>
                </p>
                <div className="text-center text-sm  ">
                  <span className="text-[44px] relative">
                    <span className="text-sm absolute top-1 left-[-10px]">
                      ₹
                    </span>
                    2499
                  </span>
                  / month
                </div>
                <div className="text-center mt-2 text-sm ">
                  Billed at ₹ 29999 annualy
                </div>
                <div>
                  <a
                    className=" mb-5 md:mb-0  mt-5 text-[10px] lg:text-[14px] font-bold uppercase grad-button px-6 py-2 mx-auto"
                    href="/register"
                  >
                    Subscribe
                  </a>
                </div>
                <div className="h-[1px] mt-12 w-11/12 mx-auto bg-[#656565]"></div>
              </li>
              <li className="mb-7 h-[24px] text-center">1TB</li>
              <li className="mb-7 h-[24px] text-center">15MB</li>
              <li className="mb-7 h-[24px] text-center">999 per event</li>
              <li className="mb-7 h-[24px] text-center">999 per event</li>
              <li className="mb-7 h-[24px] text-center">Basic</li>
              <li className="mb-7 h-[24px] text-center">+₹20000/year</li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px] text-center">199</li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px] text-center">2999</li>
            </ul>
          </div>
          <div className="flex justify-center w-2/12">
            <ul className="bg-[#eee] text-black w-[90%] rounded-3xl">
              <li className="min-h-[340px] relative">
                <p className="relative clip-text text-[40px] pt-12 font-oswald uppercase font-light text-black text-center mb-6">
                  <span className="cilpEffect">Premium</span>
                </p>
                <div className="text-center text-sm  ">
                  <span className="text-[44px] relative">
                    <span className="text-sm absolute top-1 left-[-10px]">
                      ₹
                    </span>
                    4166
                  </span>
                  / month
                </div>
                <div className="text-center mt-2 text-sm ">
                  Billed at ₹ 49999 annualy
                </div>
                <div>
                  <a
                    className=" mb-5 md:mb-0  mt-5 text-[10px] lg:text-[14px] font-bold uppercase grad-button px-6 py-2 mx-auto"
                    href="/register"
                  >
                    Subscribe
                  </a>
                </div>
                <div className="h-[1px] mt-12 w-11/12 mx-auto bg-[#656565]"></div>
              </li>
              <li className="mb-7 h-[24px] text-center">5TB</li>
              <li className="mb-7 h-[24px] text-center">25MB</li>
              <li className="mb-7 h-[24px] text-center">1999 per event</li>
              <li className="mb-7 h-[24px] text-center">1999 per event</li>
              <li className="mb-7 h-[24px] text-center">Deep Insights</li>
              <li className="mb-7 h-[24px] text-center">+₹20000/year</li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7 h-[24px]  "></li>
              <li className="mb-7 h-[24px] text-center">299</li>
              <li className="mb-7  h-[24px]">
                <svg
                  className="mx-auto"
                  width="20"
                  height="22"
                  viewBox="0 0 20 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M10 20.947c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                      fill="#000"
                      fillRule="nonzero"
                    ></path>
                    <path
                      d="M14.619 7.578a1.25 1.25 0 0 0-1.753-.015L8.75 11.68l-1.616-1.616a1.25 1.25 0 0 0-1.768 1.768l2.5 2.5a1.25 1.25 0 0 0 1.768 0l5-5a1.25 1.25 0 0 0-.015-1.753z"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </li>
              <li className="mb-7 h-[24px] text-center">Up to 1 TB</li>
              <li className="mb-7 h-[24px] text-center">4999</li>
            </ul>
          </div>
        </div>
        <div className="flex gap-x-1 text-xs">
          *write to us at
          <a className="text-primary-green" href="mailto:hello@photoface.com">
            hello@photoface.com
          </a>
          for migration charges
        </div>
        <div className="flex w-full justify-start gap-x-1 ">
          <p className=" text-[0.6rem]">*All Prices are exclusive of taxes</p>
        </div>
      </section>
      <section className="mt-20 bg-gradient-to-r from-[#a64492] via-[#09063d] to-[#000000] hidden lg:block xl:block 2xl:block text-white">
        <div className="bg-gradient-to-r from-transparent to-black py-10 md:py-20  md:flex items-center md:px-[150px] px-5  ">
          <div className="w-full md:w-8/12">
            <h5 className="font-oswald uppercase text-[34px] md:text-[44px] text-center md:text-left leading-10 mb-4 md:lg-1">
              Get the complete PhotoFace suite
            </h5>
            <h6 className="font-poppins text-[14px] font-light md:text-[24px] text-center md:text-left ">
              Experience the power of AI from shoot to share
            </h6>
          </div>
          <div className="w-full md:w-4/12 flex justify-center items-end mt-5 md:mt-0 ">
            <div className="flex justify-end">
              <div className="px-[2px] py-[2px] rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-1">
                <div className="px-8 py-[10px] rounded-full bg-black back">
                  <h2 className="text-md text-white font-bold rounded-full uppercase">
                    <button
                      className="text-[10px] lg:text-[14px] font-bold uppercase grad-button mx-0"
                      onClick={() => navigate("/register")}
                    >
                      Register
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hidden bg-black text-white lg:block xl:block 2xl:block pt-[5.5] pb-5 lg:pt-16 lg:pb-16 pl-[4.625rem] pr-[9.37rem]">
        <div className="flex">
          <div className="w-full lg:w-4/12 flex ">
            <div className="col-span-4 lg:col-span-1 pt-10 md:pt-0">
              <p className="text-2xl font-bold leading-6 ">
                Join our community and <br /> get exciting offers
              </p>
              <div className="flex mt-10 relative items-center">
                <input
                  className="bg-transparent outline-none border-b border-zinc-700 w-full p-3"
                  placeholder="Your email here"
                  type="email"
                // value=""
                />
                <svg
                  className="absolute right-3 cursor-pointer"
                  width="23"
                  height="15"
                  viewBox="0 0 23 15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.428.812a1.402 1.402 0 0 1 1.982 0l5.607 5.607a1.402 1.402 0 0 1 0 1.982l-5.607 5.607a1.402 1.402 0 0 1-1.982-1.982l3.214-3.214H1.402a1.402 1.402 0 0 1 0-2.804h16.24l-3.214-3.214a1.402 1.402 0 0 1 0-1.982z"
                    fill="#656565"
                    fillRule="nonzero"
                  ></path>
                </svg>
              </div>
              <label className="text-error-red text-xs"></label>
            </div>
          </div>
          <div className=" lg:w-8/12 flex justify-between gap-28 outline-none border-l border-zinc-700 pl-12">
            <div className="">
              <p className="font-oswald text-lg md:text-[22px] font-bold uppercase">
                Other links
              </p>
              <ul className="mt-6 space-y-3">
                <li className="text-sm text-[#a8a8a8]">
                  <a href="#">About Us</a>
                </li>
                <li className="text-sm text-[#a8a8a8]">
                  <a className="#" target="_blank" href="#">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
            <div className="">
              <p className="font-oswald text-lg md:text-[22px] font-medium md:font-bold uppercase">
                Policies
              </p>
              <ul className="mt-6 space-y-3">
                <li className="text-sm text-[#a8a8a8]">
                  <a href="#">Terms &amp; Conditions</a>
                </li>
                <li className="text-sm text-[#a8a8a8]">
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div className="">
              <p className="font-oswald text-lg  md:text-[22px] font-bold uppercase">
                Follow Us
              </p>
              <div className="flex justify-start mt-6">
                <div className="mr-5 rounded-full bg-[#232323] w-[30px] h-[30px] text-center pt-[2.5px]">
                  <a
                    target="_blank"
                    href="#"
                  >
                    <svg
                      className="mx-auto align-middle inline"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="M8 .182c2.607 0 2.915.01 3.945.057.951.043 1.468.202 1.812.336.455.177.78.388 1.121.73.342.341.553.667.73 1.122.134.344.293.86.337 1.812.046 1.029.057 1.338.057 3.942 0 2.605-.01 2.914-.057 3.943-.044.952-.203 1.468-.336 1.812-.177.455-.39.78-.73 1.12a3.018 3.018 0 0 1-1.122.73c-.344.135-.86.294-1.812.337-1.03.047-1.338.057-3.944.057s-2.915-.01-3.944-.057c-.952-.044-1.468-.203-1.812-.336a3.023 3.023 0 0 1-1.123-.73 3.025 3.025 0 0 1-.73-1.122c-.134-.344-.293-.86-.336-1.812C.009 11.094 0 10.785 0 8.18s.01-2.914.056-3.943c.043-.951.202-1.468.336-1.812.177-.455.389-.78.73-1.122a3.029 3.029 0 0 1 1.123-.73c.344-.135.86-.293 1.812-.337.9-.04 1.25-.053 3.068-.055v.002h.876z"
                          fill="#232323"
                          fillRule="nonzero"
                        ></path>
                        <path
                          d="M8 1.625c2.137 0 2.39.007 3.233.046.78.035 1.204.166 1.486.275.373.145.64.318.92.598.28.28.452.547.598.92.11.282.24.705.275 1.485.039.844.047 1.097.047 3.232 0 2.135-.008 2.388-.047 3.232-.035.78-.166 1.203-.275 1.485-.145.373-.319.639-.599.919-.28.28-.546.453-.92.598-.28.11-.705.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.282-.24-.705-.275-1.485-.038-.843-.046-1.097-.046-3.233s.008-2.388.046-3.232c.036-.78.166-1.203.276-1.485.145-.373.319-.64.599-.92.28-.28.546-.453.92-.599.282-.11.705-.24 1.485-.275.738-.034 1.024-.044 2.515-.045v.002h.718z"
                          fill="#FFF"
                        ></path>
                        <path
                          d="M11.311 3.913a.96.96 0 1 0 .96-.96.96.96 0 0 0-.96.96zM3.893 8.183A4.108 4.108 0 1 0 8 4.075a4.109 4.109 0 0 0-4.108 4.108z"
                          fill="#232323"
                          fillRule="nonzero"
                        ></path>
                        <path
                          d="M10.667 8.183a2.667 2.667 0 1 1-2.666-2.666 2.667 2.667 0 0 1 2.666 2.666z"
                          fill="#FFF"
                          fillRule="nonzero"
                        ></path>
                      </g>
                    </svg>
                  </a>
                </div>
                <div className="rounded-full bg-[#232323] w-[30px] h-[30px] text-center pt-[2.5px] flex justify-center">
                  <a target="_blank" href="#">
                    <img height="16" width="16" alt="facebookIcon" src={facebookIcon} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
