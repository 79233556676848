/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";

// Navigation
import { useNavigate } from "react-router-dom";

// material-ui
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import CircularProgress from "@mui/joy/CircularProgress";
import { styled } from "@mui/material/styles";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// PhoneInput
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Api Service
import ApiService from "../service/ApiDataRequest";

// Header Component
import Header from "../menu/Header";

// AppContext
import { useAppContext } from "../hooks/useAppContext";

// react-scroll
import { Link, Element } from 'react-scroll'

// type interface
import { Payload } from "../interface/ProfileInterface";

// Images and Logos
import facebook from "../assets/image/facebook.png";
import instagram from "../assets/image/instagram.png";
import linkedin from "../assets/image/linkedin.png";
import twitter from "../assets/image/twitter.png";
import vimeo from "../assets/image/vimeo.png";
import youtube from "../assets/image/youtube.png";
import CustomFormikInput from "./CustomFormikInput";
import Loader from "./Loader";
// import indiaflag from "../assets/image/india-flag.png";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const socialDetailsArr = [
  {
    mediaName: "facebook",
    mediaLink: '',
  },
  {
    mediaName: "instagram",
    mediaLink: '',
  },
  {
    mediaName: "linkedin",
    mediaLink: '',
  },
  {
    mediaName: "vimeo",
    mediaLink: '',
  },
  {
    mediaName: "youtube",
    mediaLink: '',
  },
  {
    mediaName: "twitter",
    mediaLink: '',
  },
]

const Profile = () => {
  const { userDetail, setIsOpen, setAlertType, setResponseMessage } = useAppContext();

  const navigate = useNavigate();

  const portfolioWebsite: any = useRef();
  const password: any = useRef();
  const facebookLink: any = useRef();
  const instagramLink: any = useRef();
  const linkedinLink: any = useRef();
  const vimeoLink: any = useRef();
  const youtubeLink: any = useRef();
  const twitterLink: any = useRef();

  const [bio, setBio] = useState<string>()
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [fieldNo, setFieldNo] = useState<number>();
  const [country, setCountry] = useState('');
  const [userDetailsData, setUserDetailsData] = useState<any>();
  const [socialMediaLinkArr, setSocialMediaLinkArr] = useState(socialDetailsArr)
  const [isLoad, setIsload] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [passwordType, setPasswordType] = useState("password")
  const [mobileNoMsgShow, setMobileNoMsgShow] = useState(false)
  const [bioMsgShow, setBioMsgShow] = useState(false)
  const [passwordMsgShow, setPasswordMsgShow] = useState(false)

  // function for API call to login user
  const handleUpdateUserProfile = async (payload: Payload) => {
    setIsLoading(true);

    try {
      const response = await ApiService.updateUserProfile(
        userDetail.userId,
        payload
      );
      console.log("response", response);
      setIsOpen(true);
      setAlertType("success");
      setResponseMessage(response.message);
      setIsLoading(false);
    } catch (error: any) {
      console.log('error', error);
      setIsLoading(false);
      setIsOpen(true);
      setAlertType("error");
      setResponseMessage(error.data[0].description);
    }
  };

  // function for API call to get user details
  const getUserDetails = async () => {
    setIsload(true)

    try {
      const response = await ApiService.getUserDetailsByUserId(userDetail.userId);
      console.log('response', response)
      setIsload(false)
      setUserDetailsData(response.data.data)
      setBio(response.data.data.bio)
      setPhoneNumberValue(response.data.data.phone)
      setCountry(response.data.data.countryId)
      if (response.data.data.socialMediaLinks.length > 0) {
        setSocialMediaLinkArr(response.data.data.socialMediaLinks)
      }
    } catch (error: any) {
      setIsload(false)
      console.log('error', error)
    }
  }

  const getCountriesList = async () => {
    try {
      const response = await ApiService.getAllCountry()
      setCountryList(response.data.data)
      console.log(response, "response");
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    getCountriesList()
    getUserDetails()
  }, [])

  const getMediaLink = (name: string) => {
    for (const media of socialMediaLinkArr) {
      if (media.mediaName === name) {
        return (media.mediaLink);
      }
    }
  }


  const setMediaLink = (name: string, link: string) => {
    for (const media of socialDetailsArr) {
      if (media.mediaName === name) {
        media.mediaLink = link
      } else {
        media.mediaLink = getMediaLink(media.mediaName) as string
      }
    }
    setSocialMediaLinkArr([...socialDetailsArr])
    return { "socialMediaLinks": [...socialDetailsArr] }
  }


  return (
    <>
      <Header />
      {isLoad ?
        <Loader /> :
        <div>
          <div className="flex items-start">
            <div className="w-[20%] mr-6 shadow-sidebar pt-7 pl-[3rem] pr-4 h-screen sticky top-0 bg-[#f1f1f1] max-sm:pl-[1rem] max-sm:w-[30%] max-sm:mr-3">
              <div
                className="cursor-pointer mb-6 flex items-center text-base text-black font-bold"
                onClick={() => navigate("/dashboard")}
              >
                <i className="fas fa-chevron-left pr-3"></i>
                Back
              </div>
              <ul>

                <li className='cursor-pointer text-base font-medium my-2 pl-4 pr-6 active:text-[#4854e2] hover:text-white1 text-black'>
                  <Link
                    style={{display : "block", width : "200px"}}
                    className=" font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm text-black"
                    activeClass="text-base font-medium py-2.5 my-2  hover:bg-[#4854e2] pl-4 pr-6 active:bg-[#4854e2] active:text-[#4854e2] hover:text-white1 bg-[#4854e2] text-white"
                    to="test1"
                    spy={true}
                    smooth={true}
                    duration={50}
                  >
                    Business Details
                  </Link>
                </li>
                <li className='cursor-pointer text-base font-medium my-2 pl-4 pr-6 active:text-[#4854e2] hover:text-white1 text-black'>
                  <Link
                    style={{display : "block", width : "200px"}}
                    className=" font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm text-black"
                    activeClass="text-base font-medium py-2.5 my-2  hover:bg-[#4854e2] pl-4 pr-6 active:bg-[#4854e2] active:text-[#4854e2] hover:text-white1 bg-[#4854e2] text-white"
                    to="test2"
                    spy={true}
                    smooth={true}
                    duration={50}
                  >
                    Social Links
                  </Link>
                </li>
                <li className='cursor-pointer text-base font-medium my-2 pl-4 pr-6 active:text-[#4854e2] hover:text-white1 text-black'>
                  <Link
                    style={{display : "block", width : "200px"}}
                    className=" font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm text-black"
                    activeClass="text-base font-medium py-2.5 my-2  hover:bg-[#4854e2] pl-4 pr-6 active:bg-[#4854e2] active:text-[#4854e2] hover:text-white1 bg-[#4854e2] text-white"
                    to="test3"
                    spy={true}
                    smooth={true}
                    duration={50}
                  >
                    Your Plan
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-[80%] max-sm:w-[70%]">
              <div className="flex flex-col pr-[3rem] grow pb-44 max-sm:pr-3">
                <h2 className="text-3xl font-semibold text-black mt-6">
                  Profile
                </h2>

                <Element name="test1" className="element" >
                  <div className="mt-4 mb-16" id="business-details">
                    <h3 className="bg-[#f3f3f3] py-2 px-4 w-full text-xl font-semibold text-black mb-7">
                      Business Details
                    </h3>
                    <div className="grid grid-cols-2 gap-5 max-sm:grid-cols-1">
                      <div className="pr-16 max-sm:pr-0">
                        <div className="relative">
                          <label className="text-base text-black font-medium">
                            Contact email
                          </label>
                          <div className="relative">
                            <input
                              type="email"
                              value={userDetail?.email}
                              // placeholder={userDetailsData?.email}
                              disabled
                              className="border border-[#e3e3e3] text-base text-black placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent mt-1"
                            />
                            {/* <span className="text-sm text-[#4854e2] cursor-pointer font-semibold absolute right-[12px] top-[12px] capitalize">
                          update
                        </span> */}
                          </div>
                        </div>
                        <div className="mt-6">
                          <label className="text-base text-black font-medium">
                            Phone
                          </label>
                          <div className="relative">
                            <PhoneInput
                              inputStyle={{ width: "100%" }}
                              placeholder="Enter phone number"
                              country="in"
                              value={phoneNumberValue}
                              onChange={(e) => { setPhoneNumberValue(e) }}
                            />
                            <span
                              onClick={() => {
                                setFieldNo(3);
                                setMobileNoMsgShow(!phoneNumberValue ? true : false)
                                phoneNumberValue &&
                                  handleUpdateUserProfile({
                                    phone: phoneNumberValue as string,
                                  });
                              }}
                              className="text-sm text-[#4854e2] cursor-pointer font-semibold absolute right-[12px] top-[8px] capitalize flex items-center"
                            >
                              {isLoading && fieldNo === 3 && (
                                <CircularProgress thickness={2} size="sm" />
                              )}
                              update
                            </span>
                            {mobileNoMsgShow && <span className=" text-[red]">Phone number is a required field.</span>}
                          </div>
                        </div>
                        <div className="mt-6">
                          <label className="text-base text-black font-medium">
                            Change Your Password
                          </label>
                          {/* Don't Remove this commented code */}
                          {/* <div className="border border-[#e3e3e3] flex items-center h-[38px] shadow-[0_1px_4px_0_rgba(0,0,0,0.1)]">
                        <div className="bg-[#cf0000] py-1.5 px-2.5">
                          <i className="fas fa-exclamation-circle text-white"></i>
                        </div>
                        <span className="text-sm text-black pl-2.5">
                          Password is not editable for social logins
                        </span>
                      </div> */}
                          <div className="relative">
                            <input
                              type={passwordType}
                              placeholder="Enter Password"
                              ref={password}
                              // value="Abc@1234"
                              // value={password.current.value}
                              onChange={(e) => {
                                password.current = e.target.value
                                console.log("pas", e.target.value)
                              }}
                              className="border border-[#e3e3e3] text-base text-black placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent mt-1"
                            />
                            <div className="absolute right-[70px] top-[11px]">
                              <i
                                onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}
                                className={`fas ${passwordType !== "password" ? "fas fa-eye" : "fa-eye-slash"} text-[#787878] cursor-pointer`}
                              ></i>
                            </div>
                            <span
                              onClick={() => {
                                setFieldNo(5)
                                setPasswordMsgShow(!password.current.length ||
                                  password.current.length < 4 ? true : false)
                                password.current.length > 3 && handleUpdateUserProfile({
                                  password: password.current as string,
                                })
                              }}
                              className="text-sm text-[#4854e2] cursor-pointer font-semibold absolute right-[12px] top-[12px] capitalize flex items-center"
                            >
                              {isLoading && fieldNo === 5 && (
                                <CircularProgress thickness={2} size="sm" />
                              )}
                              Update
                            </span>
                            {passwordMsgShow && <span className="text-[red]">Password must contain atleast 4 character</span>}
                          </div>
                        </div>
                        <div className="mt-6">
                          <label className="text-base text-black font-medium">
                            country
                          </label>
                          <div className="max-sm:mt-3 sorting-menu w-full dropdown-height">
                            <FormControl sx={{ m: 1, width: "100%" }} >
                              <Select
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="" style={{ maxHeight: '400px' }}>
                                  <span>Select Country</span>
                                </MenuItem>
                                {countryList.length > 0 && countryList.map((country: any) =>
                                  <MenuItem
                                    value={country.id}
                                    key={country.id}
                                    onClick={() => handleUpdateUserProfile({
                                      countryId: country.id,
                                    })}
                                  >
                                    {country.name}
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>

                      <div className="pl-16 max-sm:pl-0">
                        <div>
                          <label className="text-base text-black font-medium">
                            Portfolio Website
                          </label>
                          <div className="relative">
                            <CustomFormikInput
                              initialValue={userDetailsData?.portfolioWebsite}
                              onsubmit={() => {
                                setFieldNo(2);
                                handleUpdateUserProfile({
                                  portfolioWebsite: portfolioWebsite.current.value,
                                });
                              }}
                              inputRef={portfolioWebsite}
                              placeholder={"https://www.portfolioWebsite.com/"}
                              isLoading={isLoading}
                              fieldNo={fieldNo}
                              name={'Portfolio Website Link'}
                              number={2}
                            />
                          </div>
                        </div>

                        <div className="mt-6">
                          <div className="flex justify-between">
                            <span className="text-base text-black font-medium">
                              Bio
                            </span>
                            <span
                              onClick={() => {
                                setFieldNo(4);
                                setBioMsgShow(!bio ? true : false)
                                bio && handleUpdateUserProfile({ bio: bio as string });
                              }}
                              className="text-sm text-[#4854e2] cursor-pointer font-semibold capitalize mr-5 flex items-center"
                            >
                              {isLoading && fieldNo === 4 && (
                                <CircularProgress thickness={2} size="sm" />
                              )}
                              update
                            </span>
                          </div>

                          <div className="overflow-hidden">
                            <textarea
                              value={bio}
                              onChange={(e: any) =>
                                setBio(e.target.value)
                              }
                              rows={6}
                              placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                              className="border border-[#e3e3e3] text-base text-black placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent mt-1"
                            />
                          </div>
                          {bioMsgShow && <span className="text-[red]">Bio is required field.</span>}
                        </div>
                      </div>
                    </div>
                  </div>
                </Element>

                <Element name="test2" className="element" >
                  <div className="mt-4 mb-16" id="social-links">
                    <h3 className="bg-[#f3f3f3] py-2 px-4 w-full text-xl font-semibold text-black mb-7">
                      Social Links
                    </h3>
                    <div className="grid grid-cols-2 gap-5 max-sm:grid-cols-1">
                      <div className="pr-16 max-sm:pr-0">
                        <div className="flex items-center">
                          <img src={facebook} className="h-8 w-8" alt="facebook" />
                          <div className="relative w-full ml-4">
                            <CustomFormikInput
                              initialValue={getMediaLink('facebook')}
                              onsubmit={() => {
                                setFieldNo(7);
                                handleUpdateUserProfile(setMediaLink("facebook", facebookLink.current.value))
                              }}
                              inputRef={facebookLink}
                              placeholder={"https://www.facebook.com/"}
                              isLoading={isLoading}
                              fieldNo={fieldNo}
                              name={'Facebook Link'}
                              number={7}
                            />
                          </div>
                        </div>

                        <div className="flex items-center mt-6">
                          <img
                            src={instagram}
                            className="h-8 w-8"
                            alt="instagram"
                          />
                          <div className="relative w-full ml-4">
                            <CustomFormikInput
                              initialValue={getMediaLink('instagram')}
                              onsubmit={() => {
                                setFieldNo(9);
                                handleUpdateUserProfile(setMediaLink("instagram", instagramLink.current.value))
                              }}
                              inputRef={instagramLink}
                              placeholder={"https://www.insta.com/"}
                              isLoading={isLoading}
                              fieldNo={fieldNo}
                              name={'Instagram Link'}
                              number={9}
                            />
                          </div>
                        </div>

                        <div className="flex items-center mt-6">
                          <img src={linkedin} className="h-8 w-8" alt="linkedin" />
                          <div className="relative w-full ml-4">
                            <CustomFormikInput
                              initialValue={getMediaLink('linkedin')}
                              onsubmit={() => {
                                setFieldNo(11);
                                handleUpdateUserProfile(setMediaLink("linkedin", linkedinLink.current.value))
                              }}
                              inputRef={linkedinLink}
                              placeholder={"https://linkedin.com/"}
                              isLoading={isLoading}
                              fieldNo={fieldNo}
                              name={'Linkedin Link'}
                              number={11}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="pl-16 max-sm:pl-0">
                        <div className="flex items-center">
                          <img src={vimeo} className="h-8 w-8" alt="vimeo" />
                          <div className="relative w-full ml-4">
                            <CustomFormikInput
                              initialValue={getMediaLink('vimeo')}
                              onsubmit={() => {
                                setFieldNo(8);
                                handleUpdateUserProfile(setMediaLink("vimeo", vimeoLink.current.value))
                              }}
                              inputRef={vimeoLink}
                              placeholder={"https://vimeo.com/"}
                              isLoading={isLoading}
                              fieldNo={fieldNo}
                              name={'Vimeo Link'}
                              number={8}
                            />
                          </div>
                        </div>

                        <div className="flex items-center mt-6">
                          <img src={youtube} className="h-8 w-8" alt="youtube" />
                          <div className="relative w-full ml-4">
                            <CustomFormikInput
                              initialValue={getMediaLink('youtube')}
                              onsubmit={() => {
                                setFieldNo(10);
                                handleUpdateUserProfile(setMediaLink("youtube", youtubeLink.current.value))
                              }}
                              inputRef={youtubeLink}
                              placeholder={"https://youtube.com/"}
                              isLoading={isLoading}
                              fieldNo={fieldNo}
                              name={'Youtube Link'}
                              number={10}
                            />
                          </div>
                        </div>

                        <div className="flex items-center mt-6">
                          <img src={twitter} className="h-8 w-8" alt="twitter" />
                          <div className="relative w-full ml-4">
                            <CustomFormikInput
                              initialValue={getMediaLink('twitter')}
                              onsubmit={() => {
                                setFieldNo(12);
                                handleUpdateUserProfile(setMediaLink("twitter", twitterLink.current.value))
                              }}
                              inputRef={twitterLink}
                              placeholder={"https://twitter.com/"}
                              isLoading={isLoading}
                              fieldNo={fieldNo}
                              name={'Twitter Link'}
                              number={12}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Element>

                <Element name="test3" className="element" >
                  <div className="mt-4" id="your-plan">
                    <h3 className="bg-[#f3f3f3] py-2 px-4 w-full text-xl font-semibold text-black mb-7">
                      Your Plan
                    </h3>
                    <div className="flex items-center justify-between max-sm:flex-wrap">
                      <div className="max-sm:w-full">
                        <a className="text-black text-2xl font-bold leading-[24px]">
                          PhotoFace
                        </a>
                      </div>

                      <div className="w-1/2 max-sm:w-full">
                        <div className="flex items-center justify-between">
                          <span className="text-sm text-black mb-1 font-normal">
                            Images
                          </span>
                          <span className="text-sm text-[#666] mb-1 font-normal">
                            1 TB /2.00 TB
                          </span>
                        </div>
                        {/* progressbar material ui */}
                        <BorderLinearProgress variant="determinate" value={50} />
                      </div>
                      <div className="max-sm:w-full">
                        <button className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base mt-4 w-[10rem]">
                          Manage Plan
                        </button>
                      </div>
                    </div>
                    <div className="flex input-box-shadow py-6 px-8  items-center mt-10">
                      <div className="basis-1/4">
                        <div className="text-lg font-medium capitalize mb-1">
                          Premium Yearly
                        </div>
                        <div className="text-xs font-medium capitalize">
                          Billed at $50,000
                        </div>
                      </div>
                      <div className="basis-1/4">
                        <div className="text-sm font-medium capitalize mb-1">
                          Next payment due on
                        </div>
                        <div className="text-xs font-medium capitalize">
                          24th Feb 2024
                        </div>
                      </div>
                      <div className="basis-2/4">
                        <div className="mx-auto text-center flex items-center justify-center gap-6">
                          <button className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base capitalize">
                            Cancel Subscription
                          </button>
                          <button className="text-white px-4 py-2.5 find-btn font-normal text-base capitalize">
                            Update Plan
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Element>

              </div>
            </div>
          </div>
        </div>}
    </>
  );
};

export default Profile;
