/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";

// navigation
import { Link } from "react-router-dom";

// material-ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// material-ui
// import CircularProgress from "@mui/joy/CircularProgress";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";

// Formik
import { Formik } from "formik";
import * as Yup from "yup";

// moment
import moment from "moment";

// Api Service
import ApiService from "../service/ApiDataRequest";

// App Context
import { useAppContext } from "../hooks/useAppContext";

// Header Component
import Header from "../menu/Header";

// Copy to clipboard
import copy from "copy-to-clipboard";

// Images amd Logos
// import image1 from "../assets/image/img1.png";
import Loader from "./Loader";
import start from "../assets/image/start.png";
// import image2 from "../assets/image/img2.png";

//MUI calender
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  "@media (min-width: 768px)": {
    width: "600px",
  },
};

// Validationschema
const validationSchema = Yup.object().shape({
  eventName: Yup.string().required("Event Name is required").trim(),
  // eventDate: Yup.string().required("Date is required"),
});

type EventValues = {
  eventName: string;
  // eventDate: string;
};

// Api url
const API_URL = process.env.REACT_APP_API_KEY;

const Event = () => {
  const {
    userDetail,
    setIsOpen,
    setAlertType,
    setResponseMessage,
    handleDeleteEvent,
    getAllTags,
    eventTags,
    addOrUpdateEventTags,
    handleUpdateEventData,
    deleteEventTag,
    isMoveToExpireEvent,
    setIsMoveToExpireEvent,
  } = useAppContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>();
  const [expireActiveIndex, setExpireActiveIndex] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState<any>([]);
  const [expireEventData, setExpireEventData] = useState<any>([]);
  const [totalPage, setTotalPage] = useState<number | null>();
  const [page, setPage] = useState<number>(1);
  const [eventTagName, setEventTagName] = useState("");
  const [openAddTagModel, setOpenAddTagModel] = useState(false);
  const [filterType, setFilterType] = useState("2");
  const [searchText, setSearchText] = useState<string>("");
  const [isUpdateTag, setIsUpdateTag] = useState(false);
  const [eventTagId, setEventTagId] = useState<number>(0);
  const [isActiveTagUpdate, setIsActiveTagUpdate] = useState(false)
  const [allEventwiseTag, setAllEventwiseTag] = useState<string[]>([]);
  const [allExpireEventwiseTag, setAllExpireEventwiseTag] = useState<string[]>(
    []
  );
  const [eventCreateDate, setEventCreateDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [trimmedText, setTrimmedText] = useState("");
  const [filterArg, setFilterArg] = useState({
    orderByColumnName: "CreatedDateTime",
    sortColumnDir: "desc",
  });
  const [modelShow, setModelShow] = useState<any>([]);
  let eventShow: any = {};
  const handleApplyFilter = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setFilterType(value);
    if (value === "1") {
      getDynamicEventList(1, searchText, "CreatedDateTime", "desc", "", true);
    } else if (value === "2") {
      getDynamicEventList(1, searchText, "CreatedDateTime", "asc", "", true);
    } else if (value === "3") {
      getDynamicEventList(1, searchText, "eventName", "asc", "", true);
    } else if (value === "4") {
      getDynamicEventList(1, searchText, "eventName", "desc", "", true);
    }
  };

  


  // function for Api call to create event
  const handleCreateEvent = async (values: EventValues) => {
    setIsLoading(true);
    const { eventName } = values;

    try {
      const response = await ApiService.createEvent(
        userDetail.userId,
        eventName,
        eventCreateDate
      );
      console.log("response", response);

      setIsOpen(true);
      setAlertType("success");
      setResponseMessage(response.message);
      setModalOpen(false);
      setIsLoading(false);

      if (response.statusCode === 200) {
        const { orderByColumnName, sortColumnDir } = filterArg;
        getDynamicEventList(
          page,
          "",
          orderByColumnName,
          sortColumnDir,
          "",
          true
        );
      }
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      setIsOpen(true);
      setAlertType("error");
      setResponseMessage(error.data[0].description);
    }
  };

  // Function for Api call to get events dynamic list
  const getDynamicEventList = async (
    pageNo: number,
    e: any,
    orderByColumnName: string,
    sortColumnDir: string,
    status: string,
    isTrue?: any
  ) => {
    if (
      isTrue ||
      (e.target.value?.trim()?.length > 0 || !!trimmedText) === true
    ) {
      setIsLoading(true);
      setPage(pageNo);
      setFilterArg({
        orderByColumnName: orderByColumnName,
        sortColumnDir: sortColumnDir,
      });
      setIsMoveToExpireEvent(false);
      let searchText;
      if (!!e?.target?.value) {
        searchText = e.target.value;
      } else {
        searchText = "";
      }
      try {
        const response = await ApiService.getEventDynamicList(
          pageNo,
          12,
          userDetail.userId,
          searchText,
          orderByColumnName,
          sortColumnDir,
          status
        );
        setIsLoading(false);
        if (status === "") {
          console.log("response", response);
          if (response.data) {
            setTotalPage(Math.ceil(response.data.data.totalCount / 12));
            setEventData(response.data.data.result);
            response.data.data.result.forEach((element: any, i: any) => {
              eventShow[`myevent_addtag_${i + 1}`] = false;
              eventShow[`myevent_options_${i + 1}`] = false;
            });
            const eventTag: any[] = [];
            response.data.data.result.map((tag: any) =>
              eventTag.push(tag.tagName)
            );
            setAllEventwiseTag(eventTag);
          }
          if (response.status === 204) {
            setEventData([]);
            setTotalPage(null);
          }
        } else if (status === "expired") {
          if (response.data) {
            setExpireEventData(response.data.data.result);
            const eventTag: any[] = [];
            response.data.data.result.map((tag: any, i: any) => {
              eventTag.push(tag.tagName);
              eventShow[`exp_addtag_${i + 1}`] = false;
              eventShow[`exp_options_${i + 1}`] = false;
            });
            setAllExpireEventwiseTag(eventTag);
          }
          if (response.status === 204) {
            setExpireEventData([]);
          }
        }
        setModelShow(eventShow);
      } catch (error: any) {
        console.log(error);
        setIsLoading(false);
      }
    }
    setTrimmedText(e?.target?.value.trim());
  };

  // useEffect to call getDynamicEventList function
  useEffect(() => {
    getDynamicEventList(1, "", "CreatedDateTime", "asc", "", true);
    getDynamicEventList(1, "", "CreatedDateTime", "asc", "expired", true);
    getAllTags(userDetail.userId);
  }, []);

  useEffect(() => {
    if (isMoveToExpireEvent) {
      getDynamicEventList(1, "", "CreatedDateTime", "asc", "", true);
      getDynamicEventList(1, "", "CreatedDateTime", "asc", "expired", true);
    }
  }, [isMoveToExpireEvent]);

  // function to handle changes in pagination
  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    const { orderByColumnName, sortColumnDir } = filterArg;
    getDynamicEventList(value, "", orderByColumnName, sortColumnDir, "", true);
  };

  const addOrUpdateEventTag = (tagId: number) => {
    if ( isUpdateTag ) {
      addOrUpdateEventTags(tagId, userDetail.userId, eventTagName);
    } else {
      addOrUpdateEventTags(0, userDetail.userId, eventTagName);
    }
  };

  function toogleModal(popupName: any, index: number) {
    const tempEvent = { ...modelShow };
    Object.keys(tempEvent).forEach((el, i) => {
      if (el === popupName + index) {
        tempEvent[el] = !tempEvent[el];
      } else {
        tempEvent[el] = false;
      }
    });
    setModelShow(tempEvent);
  }

  // function to close Popup
  const closePopup = () => {
    const model = { ...modelShow };
    const resetPopup: any = {};
    Object.keys(model).forEach(
      (key: any) => (resetPopup[key] = false)
    );
    setModelShow(resetPopup);
    setOpenAddTagModel(false);
    setEventTagName("")
  };

  useEffect(() => {
    if(isActiveTagUpdate){
      getDynamicEventList(1, "", "CreatedDateTime", "asc", "", true)
      getDynamicEventList(1, "", "CreatedDateTime", "asc", "expired", true)
      setIsActiveTagUpdate(false)
    }
  },[eventTags])

  return (
    <div onClick={closePopup}>
      <Header />
      {!isLoading ? (
        <div>
          <div className="container mx-auto pb-[130px] max-sm:px-4">
            <div className="flex justify-between items-center pt-6 flex-wrap">
              <div>
                <h3 className="text-3xl font-bold text-black mr-3">
                  My Events
                </h3>
              </div>
              <div>
                <button
                  className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base mt-4 w-[10rem]"
                  onClick={() => setModalOpen(true)}
                >
                  Create Events
                </button>
              </div>
            </div>
            <div className="flex justify-between items-center pt-6 flex-wrap">
              <div className="inputsearch relative">
                <i className="fas fa-search absolute top-[12px] left-[10px] text-[#707070]"></i>
                <input
                  placeholder="Search Events"
                  autoFocus
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    const { orderByColumnName, sortColumnDir } = filterArg;
                    getDynamicEventList(
                      1,
                      e,
                      orderByColumnName,
                      sortColumnDir,
                      "",
                      false
                    );
                  }}
                  className="bg-[#f3f3f3] px-4 pl-9 py-2 text-sm border border-[#e3e3e3] 
                 placeholder:text-sm  outline outline-transparent focus:outline-transparent 
                w-[22rem] placeholder-[#7a7a7a] max-sm:w-full"
                />
              </div>
              <div className="flex items-center flex-wrap gap-2  max-md:mt-4">
                <div className="sorting-menu">
                  <div>
                    <FormControl sx={{ m: 1, minWidth: 163 }}>
                      <Select
                        value={filterType}
                        onChange={handleApplyFilter}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {/* <MenuItem value="">
                          <span>Sort By</span>
                        </MenuItem> */}
                        <ListSubheader>Sort By</ListSubheader>
                        <ListSubheader>Date</ListSubheader>
                        <MenuItem value="1">New - Old</MenuItem>
                        <MenuItem value="2">Old - New</MenuItem>
                        <ListSubheader>Event Name</ListSubheader>
                        <MenuItem value="3">A - Z</MenuItem>
                        <MenuItem value="4">Z - A</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {/* <div className="sorting-menu">
                <div>
                  <FormControl sx={{ m: 1, minWidth: 163 }}>
                    <Select
                      value={age}
                      onChange={handleChange1}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        <span>All Tags</span>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div> */}
              </div>
            </div>

            <div className="mt-10">
              <div className="grid grid-cols-4 gap-5 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
                {eventData.length > 0 &&
                  eventData.map((row: any, index: number) => {
                    return (
                      <div key={index}>
                        <Link to={`events/${row.id}`}>
                          <div className="relative cursor-pointer">
                            {/* <img src={image1} className="h-auto w-full object-cover" alt="image1" /> */}
                            <img
                              src={`${API_URL}/${row?.coverImage}`}
                              className="h-[180px] w-full object-cover"
                              alt={row.eventName}
                            />
                          </div>
                        </Link>
                        <div className="p-4 bg-[#fff] shadow-[0_1px_14px_0_rgba(0,0,0,0.1)]">
                          <div className="flex justify-between items-center pb-1">
                            <div className="relative flex items-center">
                              <span className="h-[9px] w-[9px] rounded-full bg-[#ababab] mr-2"></span>
                              <div>
                                <h5
                                  className="text-[#ababab] text-base font-normal cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toogleModal("myevent_addtag_", index + 1);
                                    setActiveIndex(index);
                                  }}
                                >
                                  {allEventwiseTag.length > 0 &&
                                  allEventwiseTag[index]
                                    ? allEventwiseTag[index]
                                    : "Add Tag"}
                                </h5>
                                <div
                                  style={{
                                    display:
                                      modelShow[
                                        `myevent_addtag_${index + 1}`
                                      ] && activeIndex === index
                                        ? "block"
                                        : "none",
                                  }}
                                  className="absolute top-[25px] bg-[#fff] shadow-[0_4px_12px_0_rgba(0,0,0,0.2)] rounded z-10 w-[210px]"
                                >
                                  <div className="max-h-28 overflow-y-scroll border-b cursor-pointer">
                                    {eventTags &&
                                      eventTags.map((tag: any) => (
                                        <div
                                          key={tag.id}
                                          onClick={() => {
                                            const a = [...allEventwiseTag];
                                            a[index] = tag.name;
                                            setAllEventwiseTag(a);
                                            handleUpdateEventData(
                                              row.id,
                                              { TagId: tag.id },
                                              ""
                                            );
                                          }}
                                          className="flex relative px-4 py-2 hover:bg-[#ddd]"
                                        >
                                          <span className="rounded-full w-3 h-3 mr-2 mt-2 bg-[#4854e2]">
                                            {" "}
                                          </span>
                                          <div className="flex justify-between items-center w-full">
                                            <div
                                              className="text-sm text-black mt-1 cursor-pointer"
                                            >
                                              {tag.name}
                                            </div>
                                            <div>
                                              <i
                                                className="fas fa-trash-alt ml-3 text-xs text-[#666] cursor-pointer"
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  deleteEventTag(tag.id);
                                                }}
                                              ></i>
                                              <i
                                                className="fas fa-edit ml-3 text-xs text-[#666] cursor-pointer"
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  if ( allEventwiseTag.includes(tag.name) || allExpireEventwiseTag.includes(tag.name) ) {
                                                    setIsActiveTagUpdate(true)
                                                  } else {
                                                    setIsActiveTagUpdate(false)
                                                  }
                                                  setOpenAddTagModel(true);
                                                  setIsUpdateTag(true);
                                                  setEventTagId(tag.id);
                                                  setEventTagName(tag.name);
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="underline-offset-4 px-2 py-2 text-left relative">
                                    <div
                                      className="font-semibold text-base text-black cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenAddTagModel(!openAddTagModel);
                                        setEventTagName("")
                                      }}
                                    >
                                      Customize
                                    </div>
                                    {openAddTagModel && (
                                      <div>
                                        <input
                                          type="text"
                                          value={eventTagName}
                                          placeholder="Add New tag"
                                          onClick={(e) => e.stopPropagation()}
                                          onChange={(e) => {
                                            setEventTagName(e.target.value);
                                          }}
                                          className="w-full py-2 px-1.5 input-box-shadow text-xs text-[6666] font-normal focus:outline-transparent rounded"
                                        />

                                        <div className="flex items-center justify-center gap-4 py-2 px-1.5 text-center mx-auto">
                                          <a
                                            className="text-sm font-bold underline underline-offset-1 text-[#4854e2] cursor-pointer"
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              addOrUpdateEventTag(eventTagId);
                                              setEventTagName("");
                                              setOpenAddTagModel(
                                                !openAddTagModel
                                              );
                                              setIsUpdateTag(false);
                                            }}
                                          >
                                            {isUpdateTag
                                              ? "Update tag"
                                              : "Add tag"}
                                          </a>
                                          <a
                                            className="text-sm font-bold underline underline-offset-1 text-[#00f3dd] cursor-pointer"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setOpenAddTagModel(false);
                                              setEventTagName("")
                                            }}
                                          >
                                            Cancel
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-xs text-[#838383] font-normal">
                              {moment(row.eventDate).format("Do MMM YYYY")}
                            </div>
                          </div>
                          <h3 className="text-2xl text-black font-semibold pb-1 text-ellipsis overflow-hidden whitespace-nowrap">
                            <Link to={`events/${row.id}`}>{row.eventName}</Link>
                          </h3>
                          <div className="flex justify-between items-center">
                            <div className="text-sm text-[#666] font-normal">
                              {row.lstImage.length} Photos | 1 Folders
                            </div>
                            <div
                              className="relative flex items-center cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveIndex(index);
                                toogleModal("myevent_options_", index + 1);
                              }}
                            >
                              <i className="fas fa-ellipsis-h"></i>
                              <div
                                style={{
                                  display:
                                    modelShow[`myevent_options_${index + 1}`] &&
                                    activeIndex === index
                                      ? "block"
                                      : "none",
                                }}
                                className="absolute top-[20px] bg-[#fff] shadow-[0_4px_12px_0_rgba(0,0,0,0.2)] rounded p-3 w-48 right-0 z-10"
                              >
                                <div className="flex flex-col">
                                  <Link to={`/share/${row.id}`}>
                                    <div className="text-md text-black font-medium p-[10px] hover:bg-[#ddd]">
                                      Share
                                    </div>
                                  </Link>
                                  <div
                                    onClick={() => {
                                      handleUpdateEventData(
                                        row.id,
                                        {
                                          ExpiryDate: moment()
                                            .subtract(1, "days")
                                            .format("YYYY-MM-DD"),
                                        },
                                        ""
                                      );
                                    }}
                                    className="text-md text-black font-medium p-[10px]   hover:bg-[#ddd]"
                                  >
                                    Move to Expired Events
                                  </div>
                                  <Link
                                    to={`events/${row.id}/settings`}
                                    className=""
                                  >
                                    <div className="text-md text-black font-medium p-[10px]   hover:bg-[#ddd]">
                                      Edit Event Details
                                    </div>
                                  </Link>
                                  <div
                                    onClick={() => copy(row.eventCode)}
                                    className="text-md text-black font-medium p-[10px] hover:bg-[#ddd]"
                                  >
                                    Copy Event Code
                                  </div>
                                  {/* <div className="text-sm text-black font-medium pb-2">
                                Move Event to draft
                              </div> */}
                                  <div
                                    onClick={() =>
                                      handleDeleteEvent(
                                        row.id,
                                        row.eventName,
                                        () => {
                                          const {
                                            orderByColumnName,
                                            sortColumnDir,
                                          } = filterArg;
                                          getDynamicEventList(
                                            1,
                                            "",
                                            orderByColumnName,
                                            sortColumnDir,
                                            "",
                                            true
                                          );
                                        }
                                      )
                                    }
                                    className="text-md text-black font-medium p-[10px] hover:bg-[#ddd]"
                                  >
                                    Delete Event
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {totalPage && (
                <div className="flex items-center justify-center mt-8">
                  <Pagination
                    count={totalPage}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    size="large"
                    page={page}
                    onChange={handlePaginationChange}
                  />
                </div>
              )}

              {expireEventData.length > 0 && (
                <div className="my-[30px]">
                  <h3 className="text-3xl font-bold text-black mr-3">
                    Expired Events
                  </h3>
                </div>
              )}

              <div className="grid grid-cols-4 gap-5 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
                {expireEventData.length > 0 &&
                  expireEventData.map((row: any, index: number) => {
                    return (
                      <div key={index}>
                        <Link to={`events/${row.id}`}>
                          <div className="relative cursor-pointer">
                            <img
                              src={`${API_URL}/${row?.coverImage}`}
                              className="h-[180px] w-full object-cover"
                              alt={row.eventName}
                            />
                          </div>
                        </Link>
                        <div className="p-4 bg-[#fff] shadow-[0_1px_14px_0_rgba(0,0,0,0.1)]">
                          <div className="flex justify-between items-center pb-1">
                            <div className="relative flex items-center ">
                              <span className="h-[9px] w-[9px] rounded-full bg-[#ababab] mr-2"></span>
                              <div>
                                <h5
                                  className="text-[#ababab] text-base font-normal cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setExpireActiveIndex(index);
                                    toogleModal("exp_addtag_", index + 1);
                                  }}
                                >
                                  {allExpireEventwiseTag.length > 0 &&
                                  allExpireEventwiseTag[index]
                                    ? allExpireEventwiseTag[index]
                                    : "Add Tag"}
                                </h5>
                                <div
                                  style={{
                                    display:
                                      modelShow[`exp_addtag_${index + 1}`] &&
                                      expireActiveIndex === index
                                        ? "block"
                                        : "none",
                                  }}
                                  className="absolute top-[25px] bg-[#fff] shadow-[0_4px_12px_0_rgba(0,0,0,0.2)] rounded z-10 w-[210px]"
                                >
                                  <div className="max-h-28 overflow-y-scroll border-b cursor-pointer">
                                    {eventTags &&
                                      eventTags.map((tag: any) => (
                                        <div
                                          key={tag.id}
                                          onClick={() => {
                                            const a = [
                                              ...allExpireEventwiseTag,
                                            ];
                                            a[index] = tag.name;
                                            setAllExpireEventwiseTag(a);
                                            handleUpdateEventData(
                                              row.id,
                                              { TagId: tag.id },
                                              ""
                                            );
                                          }}
                                          className="flex relative px-4 py-2 hover:bg-[#ddd]"
                                        >
                                          <span className="rounded-full w-3 h-3 mr-2 mt-2 bg-[#4854e2]">
                                            {" "}
                                          </span>
                                          <div className="flex justify-between items-center w-full">
                                            <div
                                              className="text-sm text-black mt-1 cursor-pointer"
                                            >
                                              {tag.name}
                                            </div>
                                            <div>
                                              <i
                                                className="fas fa-trash-alt ml-3 text-xs text-[#666] cursor-pointer"
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  deleteEventTag(tag.id);
                                                }}
                                              ></i>
                                              <i
                                                className="fas fa-edit ml-3 text-xs text-[#666] cursor-pointer"
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  if( allExpireEventwiseTag.includes(tag.name) || allEventwiseTag.includes(tag.name) ){
                                                    setIsActiveTagUpdate(true)
                                                  } else {
                                                    setIsActiveTagUpdate(false)
                                                  }
                                                  setOpenAddTagModel(true);
                                                  setIsUpdateTag(true);
                                                  setEventTagId(tag.id);
                                                  setEventTagName(tag.name);
                                                }}
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="underline-offset-4 px-2 py-2 text-left relative">
                                    <div
                                      className="font-semibold text-base text-black cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenAddTagModel(!openAddTagModel);
                                      }}
                                    >
                                      Customize
                                    </div>
                                    {openAddTagModel && (
                                      <div>
                                        <input
                                          type="text"
                                          value={eventTagName}
                                          placeholder="Add New tag"
                                          onClick={(e) => e.stopPropagation()}
                                          onChange={(e) =>
                                            setEventTagName(e.target.value)
                                          }
                                          className="w-full py-2 px-1.5 input-box-shadow text-xs text-[6666] font-normal focus:outline-transparent rounded"
                                        />

                                        <div className="flex items-center justify-center gap-4 py-2 px-1.5 text-center mx-auto">
                                          <a
                                            className="text-sm font-bold underline underline-offset-1 text-[#4854e2] cursor-pointer"
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              addOrUpdateEventTag(eventTagId);
                                              setEventTagName("");
                                              setOpenAddTagModel(
                                                !openAddTagModel
                                              );
                                              setIsUpdateTag(false);
                                            }}
                                          >
                                            {isUpdateTag
                                              ? "Update tag"
                                              : "Add tag"}
                                          </a>
                                          <a
                                            className="text-sm font-bold underline underline-offset-1 text-[#00f3dd] cursor-pointer"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setOpenAddTagModel(false);
                                              setEventTagName("")
                                            }}
                                          >
                                            Cancel
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-xs text-[#838383] font-normal">
                              {moment(row.eventDate).format("Do MMM YYYY")}
                            </div>
                          </div>
                          <Link to={`events/${row.id}`}>
                            <h3 className="text-2xl text-black font-semibold pb-1 cursor-pointer text-ellipsis overflow-hidden whitespace-nowrap">
                              {row.eventName}
                            </h3>
                          </Link>
                          <div
                            className="flex justify-between items-center"
                          >
                            <div className="text-sm text-[#666] font-normal">
                              {row.lstImage.length} Photos | 1 Folders
                            </div>
                            <div
                              className="relative flex items-center cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setExpireActiveIndex(index);
                                toogleModal("exp_options_", index + 1);
                              }}
                            >
                              <i className="fas fa-ellipsis-h"></i>
                              <div
                                style={{
                                  display:
                                    modelShow[`exp_options_${index + 1}`] &&
                                    expireActiveIndex === index
                                      ? "block"
                                      : "none",
                                }}
                                className="absolute top-[20px] bg-[#fff] shadow-[0_4px_12px_0_rgba(0,0,0,0.2)] rounded p-3 w-48 right-0 z-10"
                              >
                                <div className="flex flex-col">
                                  <Link to={`/share/${row.id}`}>
                                    <div className="text-md text-black font-medium p-[10px] hover:bg-[#ddd]">
                                      Share
                                    </div>
                                  </Link>
                                  {/* <div
                                    onClick={() => { handleUpdateEventData(row.id, { ExpiryDate: moment().format("YYYY-MM-DD") }, '') }}
                                    className="text-sm text-black font-medium pb-2"
                                  >
                                    Move to Expired Events
                                  </div> */}
                                  <Link to={`events/${row.id}/settings`}>
                                    <div className="text-md text-black font-medium p-[10px] hover:bg-[#ddd]">
                                      Edit Event Details
                                    </div>
                                  </Link>
                                  <div
                                    onClick={() => copy(row.eventCode)}
                                    className="text-md text-black font-medium p-[10px] hover:bg-[#ddd]"
                                  >
                                    Copy Event Code
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleDeleteEvent(
                                        row.id,
                                        row.eventName,
                                        () => {
                                          const {
                                            orderByColumnName,
                                            sortColumnDir,
                                          } = filterArg;
                                          getDynamicEventList(
                                            1,
                                            "",
                                            orderByColumnName,
                                            sortColumnDir,
                                            "expired",
                                            true
                                          );
                                        }
                                      )
                                    }
                                    className="text-md text-black font-medium p-[10px] hover:bg-[#ddd]"
                                  >
                                    Delete Event
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* {totalPage && (
                <div className="flex items-center justify-center mt-8">
                  <Pagination
                    count={totalPage}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    size="large"
                    page={page}
                    onChange={handlePaginationChange}
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      {searchText && eventData.length === 0 && (
        <div className="flex items-center justify-center">
          No events found based on your search
        </div>
      )}
      {!searchText &&
        eventData.length === 0 &&
        expireEventData.length === 0 &&
        !isLoading && (
          <div className="flex flex-col items-center justify-center">
            <div>
              <img src={start} alt="start" className="w-[100px] h-auto" />
            </div>
            <h4 className="text-2xl font-semibold text-black capitalize mt-2">
              Get Started!
            </h4>
            <p className="text-base text-black font-medium mt-2">
              Create an event and start sharing them instantly.
            </p>
            <button
              onClick={() => setModalOpen(true)}
              className="bg-[#4854e2] text-white px-2 py-2.5 font-normal text-base w-[11rem] relative mt-10"
            >
              Create Event
            </button>
          </div>
        )}

      {/* modal material ui */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalBoxStyle}>
          <div>
            <div className="flex items-start justify-between">
              <h2 className="text-[28px] text-black font-semibold leading-[28px]">
                Create Event
              </h2>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                <i className="fas fa-times "></i>
              </div>
            </div>

            <div className="mt-6">
              <Formik
                initialValues={{
                  eventName: "",
                  // eventDate: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleCreateEvent(values);
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                }) => {
                  return (
                    <>
                      <div className="relative">
                        <label className="text-xs font-normal text-[#666]">
                          Event Name <span className="text-[#eb1616]">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Event Name"
                          id="eventName"
                          name="eventName"
                          onChange={handleChange("eventName")}
                          onBlur={handleBlur}
                          value={values.eventName}
                          className="w-full py-2 px-3 border border-[#ddd] outline outline-transparent focus:outline-transparent text-base text-black font-normal placeholder-[#999]"
                        />
                        {errors.eventName && touched.eventName ? (
                          <div className="text-[red]">{errors.eventName}</div>
                        ) : null}
                      </div>
                      <div className="mt-4 relative">
                        <label className="text-xs font-normal text-[#666]">
                          Event Date <span className="text-[#eb1616]">*</span>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="border border-[#e3e3e3] text-base text-black placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent mt-1"
                            defaultValue={dayjs(new Date())}
                            onChange={(e: any) => {
                              const date = moment(e.$d).format();
                              setEventCreateDate(
                                moment(date).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        </LocalizationProvider>
                        {/* <input
                          type="date"
                          id="eventDate"
                          name="eventDate"
                          onChange={handleChange("eventDate")}
                          onBlur={handleBlur}
                          value={values.eventDate}
                          className="w-full py-2 px-3 border border-[#ddd] outline outline-transparent focus:outline-transparent text-base text-black font-normal placeholder-[#999]"
                        /> */}
                        {/* {errors.eventDate && touched.eventDate ? (
                          <div className="text-[red]">{errors.eventDate}</div>
                        ) : null} */}
                      </div>
                      <div className="mt-10 mx-auto text-center">
                        <button
                          type="button"
                          onClick={() => handleSubmit()}
                          className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base mt-4 w-[13rem] relative
                          flex items-center justify-center mx-auto"
                        >
                          <span className="text-center mx-auto flex justify-center w-full mr-[10px]">
                            Create Events
                          </span>
                          {/* {!isLoading && (
                            <CircularProgress
                              thickness={2}
                              size="sm"
                              style={{ marginLeft: "10px", lineHeight: "0", position: "absolute", right: "20px" }}
                            />
                          )} */}
                        </button>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Event;
