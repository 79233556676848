// Routing
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Components
import App from "../App";
import Home from "../components/Home";
import Login from "../components/login";
import Register from "../components/Register";
import CustomSnackbar from "../components/CustomSnackbar";

// Private Routes
import PrivateRoutes from "./PrivateRoutes";

// Context
import { useAppContext } from "../hooks/useAppContext";


const AppRoutes = () => {

    const { isOpen, setIsOpen, alertType, responseMessage } = useAppContext()
    const token = localStorage.getItem('auth-token')

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App />}>
                        {!token ? (
                            <>
                                <Route index element={<Home />} />
                                <Route path="/login" element={<Login />} />
                                <Route path="/register" element={<Register />} />
                                <Route path="/*" element={<Navigate to="/login" />} />
                            </>
                        ) :
                            (<>
                                <Route path='/*' element={<PrivateRoutes />} />
                                <Route index element={<Navigate to='/dashboard' />} />
                            </>)
                        }
                    </Route>
                </Routes>
            </BrowserRouter>

            {/* snackbar component to show message */}
            <CustomSnackbar
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                alertType={alertType}
                responseMessage={responseMessage}
            />
        </>
    );
};

export default AppRoutes;
