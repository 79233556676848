import ReactDOM from 'react-dom/client';

// AppRoutes
import AppRoutes from './routing/AppRoutes';

// Context
import { AppContextProvider } from './context/AppContext';

// Style
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <AppContextProvider>
    <AppRoutes />
  </AppContextProvider>
);
