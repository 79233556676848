/* eslint-disable react-hooks/exhaustive-deps */
// material-ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/joy/CircularProgress";
// Modal style
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    "@media (min-width: 768px)": {
        width: "800px",
    },
};

const UpdateEventNameModal = ({ open, onClose, eventName, setEventName, isLoading, onSubmit }: any) => {

    return (

        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="relative">
                    <div className="flex items-center justify-between">
                        <div>
                            <h3 className="text-3xl text-black font-bold capitalize text-center">
                                Update Event Name
                            </h3>
                        </div>
                        <div
                            className="cursor-pointer "
                            onClick={onClose}
                        >
                            <i className="fas fa-times "></i>
                        </div>
                    </div>
                    <div className="relative mt-6">
                        <div>
                            <label>Event Name*</label>
                            <input
                                placeholder="Event Name"
                                type="text"
                                value={eventName}
                                onChange={(e: any) => setEventName(e.target.value)}
                                className="w-full py-2 px-3 border border-[#ddd] outline outline-transparent focus:outline-transparent text-base text-black font-normal placeholder-[#999]"
                            />
                        </div>
                        {/* <div className="mt-4">
                            <label>Event URL*</label>
                            <input
                                placeholder="test@gmail.com"
                                type="text"
                                className="w-full py-2 px-3 border border-[#ddd] outline outline-transparent focus:outline-transparent text-base text-black font-normal placeholder-[#999]"
                            />
                        </div> */}
                    </div>

                    <div className="relative mt-8 mx-auto text-center">
                        <button
                            onClick={onSubmit}
                            className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base capitalize relative w-[17rem]"
                        >
                            Update Event Name
                            {isLoading && (
                                <CircularProgress
                                    thickness={2}
                                    size="sm"
                                    style={{ marginLeft: "10px", lineHeight: "0", position: "absolute", right: "20px" }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default UpdateEventNameModal