// materialui
import CircularProgress from "@mui/joy/CircularProgress";

// Formik
import { Formik } from 'formik';

// Yup validation
import * as Yup from 'yup';

const CustomFormikInput = ({ initialValue, onsubmit, inputRef, placeholder, isLoading, fieldNo, name, number }: any) => {

    // Validationschema
    const validationSchema = Yup.object().shape({
        fieldname: Yup.string()
            .required(`${name} is required`),
    });

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                fieldname: initialValue,
            }}
            validationSchema={validationSchema}
            onSubmit={values => {
                console.log('values', values)
                onsubmit()
            }}>
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
            }) => {
                return (
                    <>
                        <input
                            type="text"
                            ref={inputRef}
                            placeholder={placeholder}
                            // id="fieldname"
                            name="fieldname"
                            onChange={handleChange('fieldname')}
                            onBlur={handleBlur}
                            value={values.fieldname}
                            className="border border-[#e3e3e3] text-sm text-black placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent"
                        />
                        {errors.fieldname && touched.fieldname ? (
                            <div className='text-[red]'>{errors.fieldname as string}</div>
                        ) : null}
                        <span
                            onClick={() => handleSubmit()}
                            className="text-sm text-[#4854e2] cursor-pointer pl-2 bg-white font-semibold absolute right-[12px] top-[6px] capitalize flex items-center"
                        >
                            {isLoading && fieldNo === number && (
                                <CircularProgress thickness={2} size="sm" />
                            )}
                            update
                        </span>
                    </>
                );
            }}
        </Formik>
    )
}

export default CustomFormikInput