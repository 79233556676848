import  { useEffect } from "react";
import { useAppContext } from "../../hooks/useAppContext";

// Api url
const API_URL = process.env.REACT_APP_API_KEY;

const PhotoGallery = ({ imageList,setGalleryPhoto }: any) => {
  const {eventDataById}=useAppContext();
  
  const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];
  useEffect(() => {
    const unsplashPhotos: any = [];
    imageList.length > 0 &&eventDataById &&
      imageList.map((row: any,index:number) => {
        unsplashPhotos.push({
          // imageLink:`${API_URL}/${row.imageLink}`,
          imageLink:`${API_URL}/${eventDataById.lstImage[index]}`,
          width: row.imageWidth,
          height: row.imageHeight,
        });
      });

      console.log(unsplashPhotos);

      const photos = unsplashPhotos.map((photo:any) => {
        const width = breakpoints[0];
        const height = (photo.height / photo.width) * width;
      
        return {
            src:photo.imageLink,
            width,
            height,
            srcSet: breakpoints.map((breakpoint) => {
                const height = Math.round((photo.height / photo.width) * breakpoint);
                return {
                    src:photo.imageLink,
                    width: breakpoint,
                    height,
                };
            }),
        };  
    });
    console.log('gjhsghsdfgfjsdg',photos);
    
    setGalleryPhoto(photos)
  }, [imageList,eventDataById]);

  return <></>
};

  


export default PhotoGallery;
