/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

// navigation
import { useNavigate } from "react-router-dom";


//MUI click-away-listener
import ClickAwayListener from '@mui/base/ClickAwayListener';




// AppContext
import { useAppContext } from "../hooks/useAppContext";

const Header = () => {
  const { setIsOpen, setAlertType, setResponseMessage, userDetail } = useAppContext()

  const navigate = useNavigate()
  const token = localStorage.getItem('auth-token')

  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  const [isProfileOpen, setIsProfileOpen] = useState(false)

  // Function to call when click logout
  const handleLogout = () => {
    localStorage.removeItem("auth-token");
    localStorage.removeItem("user-detail");
    setIsOpen(true);
    setAlertType("success");
    setResponseMessage("Logout Succesfully!");
  };

  // Function to Close the open notificaton popup
  const handleClickAway = () => {
    isNotificationOpen && setIsNotificationOpen(false)
  };

  const handleClickAwayProfile = () => {
    isProfileOpen && setIsProfileOpen(false)
  };

  return (
    // <div onClick={handleClickAway} className="shadow-[0_3px_10px_0_rgba(0,0,0,0.1)] bg-white py-4 z-10">
    <div className="shadow-[0_3px_10px_0_rgba(0,0,0,0.1)] bg-white py-4 z-10 sticky top-0">
      <div className="container-2xl mx-auto px-[3rem] max-lg:px-10 max-sm:px-4">
        <div className="flex justify-between items-center">
          <div className="cursor-pointer">
            <a
              className="text-black text-2xl font-bold leading-[24px]"
              onClick={() => navigate("/dashboard")}
            >
              PhotoFace
            </a>
          </div>
          <div className="relative flex items-center gap-4">
            {!token ? (<>
              {/* <div className="text-base font-semibold find-btn text-white capitalize tracking-wide
               py-1 px-3 cursor-pointer">View plans</div> */}
              <div onClick={() => navigate("/login")} className="cursor-pointer text-black text-base font-semibold">
                Log In
              </div>
            </>) :
              (<>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div>
                    <div className="leading-none relative">
                      <div className="cursor-pointer" onClick={() => setIsNotificationOpen(!isNotificationOpen)}>
                        <i className="fas fa-bell text-xl"></i>
                        <span className="h-[9px] w-[9px] right-[-3px] top-[3px] border border-[#fff] rounded-full bg-[#eb1616] absolute"></span>
                      </div>

                      {/* notification */}

                      {isNotificationOpen &&
                        <div
                          onClick={handleClickAway}
                          className="absolute top-[30px] bg-[#fff] shadow-[0_4px_12px_0_rgba(0,0,0,0.2)] w-[22.5rem] rounded p-5 right-[15px] z-10 cursor-pointer max-sm:w-[12rem] max-sm:p-2"
                        >
                          <div className="flex items-center">
                            <a className="relative text-base capitalize font-semibold text-[#4854e2] cursor-pointer">
                              inbox
                            </a>
                            <span className="h-[9px] w-[9px] ml-1 rounded-full bg-[#4854e2]"></span>
                          </div>
                          <div className="text-left">
                            <p className="text-base text-black font-semibold mt-4 mb-1 cursor-pointer max-sm:text-sm">
                              Our New Pricing has been updated
                            </p>
                            <a
                              className="text-xs text-[#4854e2] font-semibold underline cursor-pointer">
                              View Pricing Plans
                            </a>
                            <p className="text-xs text-[#666] font-normal mt-2">
                              9 June 2022 09:00 AM
                            </p>
                          </div>
                        </div>}
                      {/* notification */}

                    </div>
                  </div>
                </ClickAwayListener >
                {/* <div className="text-black font-base font-bold leading-[16px] cursor-pointer pr-4">
                  Help
                </div> */}
                <div className="profile relative">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => setIsProfileOpen(!isProfileOpen)}
                  >
                    <button className="text-lg font-semibold text-white bg-[#4854e2] rounded-full h-[35px] w-[35px] flex items-center justify-center leading-[35px] uppercase">
                      {userDetail.firstName.charAt(0)}
                    </button>
                    {!isProfileOpen && <i className="fas fa-sort-down ml-2 text-black text-[22px] h-[30px]"></i>}
                    {isProfileOpen && <i className="fas fa-sort-up ml-2 text-black text-[22px] h-[30px] leading-[40px]"></i>}
                  </div>
                  {isProfileOpen &&
                    <ClickAwayListener onClickAway={() => {
                      handleClickAwayProfile()
                    }}>
                      <div className="py-8 px-10 absolute right-[0px] top-[35px] z-10 rounded-xl w-72 bg-white box-inner-shadow text-center mt-2 max-lg:right-[40px] max-sm:right-[16px]">
                        <div className="rounded-full h-28 w-28 bg-[#4854e2] flex items-center justify-center text-white text-2xl font-normal mx-auto uppercase">
                          {userDetail.firstName.charAt(0) +
                            userDetail.lastName.charAt(0)}
                        </div>
                        <div className="text-base text-black font-medium mt-2 overflow-anywhere">
                          {userDetail.email}
                        </div>
                        <button
                          className="bg-[#4854e2] text-white px-6 py-2 font-semibold text-sm mt-4 w-[10rem]"
                          onClick={() => {
                            navigate("/profile");
                          }}
                        >
                          View Profile
                        </button>
                        <button
                          className="capitalize text-black font-semibold mt-2"
                          onClick={handleLogout}
                        >
                          logout
                        </button>
                      </div>
                    </ClickAwayListener>
                  }

                </div>
              </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
