const Loader = () => {
  return (
    <>
    <div className="relative bg-white">
    <div className="container mx-auto">
      <div className="flex items-center align-middle min-h-[calc(100vh_-_67px)]">
        <div className="centered">
          <div className="blob-1"></div>
          <div className="blob-2"></div>
          <h3 className="text-3xl text-black font-bold mt-5 absolute left-0 right-0 bottom-[70px] 
          text-center mx-auto">Loading...</h3>
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default Loader;
