/* eslint-disable jsx-a11y/anchor-is-valid */


import { useState, useEffect } from "react"
// material-ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// Copy to clipboard
import copy from "copy-to-clipboard";

// Social-share
import { FacebookShareButton, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TelegramShareButton, TelegramIcon } from "react-share";

// import {ShareSocial} from 'react-share-social' 

// images and logo
import facebook from "../../assets/image/facebook.png";
// import instagram from "../../assets/image/instagram.png";
import twitter from "../../assets/image/twitter.png";

// Image open Modal style
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    "@media (min-width: 768px)": {
        width: "800px",
    },
};



const ShareModel = ({ open, onClose, imageUrl }: any) => {
    const [copyMsgShow, setCopyMsgShow] = useState(false)

    // setTimeout to change copy text
    useEffect(() => {
        setTimeout(() => {
            setCopyMsgShow(false)
        }, 2000)
    }, [copyMsgShow])

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div
                    className="cursor-pointer absolute right-[10px] top-[10px]"
                    onClick={onClose}
                >
                    <i className="fas fa-times "></i>
                </div>
                <div className="relative">
                    <div className="flex items-center justify-start">
                        <div>
                            <h3 className="text-3xl text-black font-bold capitalize text-center">
                                Share
                            </h3>
                        </div>
                    </div>
                    <div className="relative mt-4">
                        <div className="rounded-md box-inner-shadow w-full py-1 px-4 h-10 relative">
                            <div className="whitespace-nowrap text-sm leading-[32px] text-ellipsis overflow-hidden mr-[5rem]">{imageUrl}</div>
                            <span
                                onClick={() => {
                                    copy(imageUrl)
                                    setCopyMsgShow(true)
                                }}
                                className="text-sm cursor-pointer bg-white py-[5px] px-[10px] font-bold text-[#4854e2] capitalize absolute right-0 top-[5px]"
                            >
                                {copyMsgShow ? "Copied" : "Copy"}
                            </span>
                        </div>
                        <div className="flex items-center justify-start gap-4 mt-4">
                            <FacebookShareButton
                                url={imageUrl}
                                quote={"Hello welcome to my event"}
                                hashtag={"#hashtag"}
                            // description="aiueo"
                            // className="Demo__some-network__share-button"
                            >
                                <img src={facebook} alt="fb" className="h-8 w-8 object-cover" />
                            </FacebookShareButton>
                            <WhatsappShareButton
                                url={imageUrl}
                                // url={'http://103.232.124.169:8444/MediaUpload/Form/WhatsApp%20Image%202023-05-09%20at%203.59.09%20PM233501503.jpeg'}
                                title={"Image"}
                            >
                                <WhatsappIcon size={32} />
                            </WhatsappShareButton>
                            {/* <LinkedinShareButton
                                url={imageUrl}
                                title={"Image"}
                            >
                                <LinkedinIcon size={32} />
                            </LinkedinShareButton> */}
                            <TwitterShareButton
                                url={imageUrl}
                                title={"Image"}
                                via={"images"}
                                hashtags={['#weddings', '#fun']}
                            >
                                <img src={twitter} alt="tw" className="h-8 w-8 object-cover" />
                            </TwitterShareButton>
                            <TelegramShareButton
                                url={imageUrl}
                                title={"Image"}
                            >
                                <TelegramIcon size={32} />
                            </TelegramShareButton>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ShareModel