/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";

// navigation
import { useNavigate } from "react-router-dom";

// Header Component
import Header from "../menu/Header";

// material-ui
import CircularProgress from "@mui/joy/CircularProgress";

// Formik
import { Formik } from "formik";
import * as Yup from "yup";

// Appcontext
import { useAppContext } from "../hooks/useAppContext";

// Api Service
import ApiService from "../service/ApiDataRequest";

// material-ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// Images and Logos
// import googleIcon from "../assets/image/google-icon.png";
import SignInPhoto from "../assets/image/human-ai.png";

// RegExp
const emailRegExp = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
// const passwordRegExp = RegExp(
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
// );

// Validationschema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")
    .matches(emailRegExp, "Please enter valid email")
    .required("Email is required"),
  password: Yup.string()
    .label("Password")
    .required("Password is required")
    .min(4, "Minimum 4 characters required")
    .max(20, "Maximum 20 characters allowed"),
  // .matches(
  //   passwordRegExp,
  //   "Password must contain one uppercase, one lowercase, one digit and special character"
  // ),
});

// Modal Style
const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  "@media (min-width: 768px)": {
    width: "600px",
  },
};

type LoginValue = {
  email: string;
  password: string;
};

const Login = () => {
  const { setIsOpen, setAlertType, setResponseMessage, setUserDetail } =
    useAppContext();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  // const [mail, setMail] = useState<string>("");

  // function for API call to login user
  const handleLogin = async (values: LoginValue) => {
    setIsLoading(true);
    const { email, password } = values;

    try {
      const response = await ApiService.loginUser(email, password);
      console.log("response", response);
      if (response.data.token) {
        localStorage.setItem("auth-token", response.data.token);
        const { firstName, lastName, userId } = response.data;

        setIsOpen(true);
        setAlertType("success");
        setResponseMessage(response.message);
        setUserDetail({ firstName, lastName, email: values.email, userId });
        localStorage.setItem(
          "user-detail",
          JSON.stringify({ firstName, lastName, email: values.email, userId })
        );
      }
      setIsLoading(false);
    } catch (error: any) {
      console.log('error', error);
      setIsLoading(false);
      setIsOpen(true);
      setAlertType("error");
      setResponseMessage(error.data[0].description);
    }
  };

  // function for api call to reset password
  const handleForgotPassword = async (mail: string) => {
    setIsLoading1(true);
    try {
      const response = await ApiService.forgotPassword(mail);
      console.log("response", response);
      setIsLoading1(false);
      setModalOpen(false);
      setIsOpen(true);
      setAlertType("success");
      setResponseMessage(response.data.message);
      // setMail("");
    } catch (error: any) {
      console.log("error", error);
      setIsLoading1(false);
      setIsOpen(true);
      setAlertType("error");
      setResponseMessage(error.data.description);
    }
  };


  return (
    <>
      <Header />
      <div className="container mx-auto max-sm:px-4">
        <div className="flex items-center align-middle min-h-[calc(100vh_-_64px)]">
          <div className="grid md:grid-cols-2 gap-4 py-10">
            <div className="p-6 flex items-start justify-center flex-col max-[640px]:text-center max-[640px]:items-center">
              <div className="mb-5">
                <img
                  src={SignInPhoto}
                  style={{
                    height: "150px",
                    borderRadius: "50% 50% 50% 50%/40% 40% 60% 60%",
                  }}
                  alt="SignInPhoto"
                />
              </div>
              PhotoFace is the world's only end to end AI powered image post
              production solution
            </div>
            <div className="box-inner-shadow rounded-xl p-6">
              <div className="font-bold text-xl py-3">Sign In</div>
              <div className="mb-3">
                New here?
                <button
                  onClick={() => navigate("/register")}
                  className="underline underline-offset-2 decoration-[#4854e2] text-[#4854e2] font-bold pl-2 cursor-pointer"
                >
                  Create An Account
                </button>
              </div>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  console.log("values", values);
                  handleLogin(values);
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                }) => {
                  return (
                    <>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                      >
                        <div className="my-3">
                          <div className="py-1">
                            <label htmlFor="email" className="px-3">
                              Email Id*
                            </label>
                          </div>
                          <div className="input-box-shadow rounded-lg">
                            <input
                              type="text"
                              id="email"
                              name="email"
                              onChange={handleChange("email")}
                              onBlur={handleBlur}
                              value={values.email}
                              className="w-full h-10 rounded-lg focus-visible:outline-none px-3"
                            />
                          </div>
                          {errors.email && touched.email ? (
                            <div className="text-[red]">{errors.email}</div>
                          ) : null}
                        </div>
                        <div className="my-3">
                          <div className="py-1">
                            <label htmlFor="password" className="px-3">
                              Password*
                            </label>
                          </div>
                          <div className="input-box-shadow rounded-lg relative">
                            <input
                              type={passwordType}
                              id="password"
                              name="password"
                              onChange={handleChange("password")}
                              onBlur={handleBlur}
                              value={values.password}
                              className="w-full h-10 rounded-lg focus-visible:outline-none px-3"
                            />
                            <div className="absolute right-[10px] top-[9px]">
                              {passwordType === "text" && (
                                <i
                                  onClick={() => setPasswordType("password")}
                                  className="fas fa-eye text-[#787878] cursor-pointer"
                                ></i>
                              )}
                              {passwordType === "password" && (
                                <i
                                  onClick={() => setPasswordType("text")}
                                  className="fas fa-eye-slash text-[#787878] cursor-pointer"
                                ></i>
                              )}
                            </div>
                          </div>
                          {errors.password && touched.password ? (
                            <div className="text-[red]">{errors.password}</div>
                          ) : null}
                        </div>
                        <div className="w-full flex justify-end py-5 pt-3">
                          <div
                            // type="button"
                            className="underline underline-offset-2 decoration-[#4854e2] text-[#4854e2] pl-2 cursor-pointer"
                            onClick={() => { setModalOpen(true) }}
                          >
                            Forgot Password
                          </div>
                        </div>
                        <div className="w-full text-center mb-20">
                          <button
                            type="submit"
                            disabled={isLoading}
                            className="px-14 py-2 bg-[#4854e2] input-box-shadow text-white font-medium flex items-center mx-auto"
                          // onClick={() => handleSubmit()}
                          >
                            <span>Sign In</span>
                            {isLoading && (
                              <CircularProgress
                                thickness={2}
                                size="sm"
                                style={{ marginLeft: "10px", lineHeight: "0" }}
                              />
                            )}
                          </button>
                        </div>
                      </form>
                    </>
                  );
                }}
              </Formik>
              {/* <div className="relative w-full text-center py-5">
                <div
                  className="after:content-[''] after:h-[1px] after:bg-[#cacaca] after:w-[43%]
              after:absolute after:left-0 after:top-[30px]
              before:content-[''] before:h-[1px] before:bg-[#cacaca] before:w-[43%]
              before:absolute before:right-0 before:top-[30px]
              "
                >
                  Or
                </div>
              </div>
              <div className="w-full text-center">
                <div className="flex justify-center">
                  <img src={googleIcon} className="h-[24px]" alt="googleIcon" />
                  <button
                    className="underline underline-offset-2 decoration-[#4854e2] text-[#4854e2] font-bold pl-2"
                    onClick={() => navigate("/dashboard")}
                  >
                    Sign In with Google
                  </button>
                </div>
              </div> */}
            </div>
            <div></div>
          </div>
        </div>
      </div>

      {/* modal material ui */}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalBoxStyle}>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Please enter valid email")
                .matches(emailRegExp, "Please enter valid email")
                .required("Email is required"),
            })}
            onSubmit={(values) => {
              handleForgotPassword(values.email);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
            }) => {
              return (
                <>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div>
                      <div className="flex items-start justify-center">
                        <h2 className="text-3xl text-black font-semibold leading-[28px] capitalize">
                          Password Reset
                        </h2>
                      </div>

                      <div className="mt-6">
                        <div className="relative">
                          <label className="text-sm font-normal text-[#666]">
                            Email Id <span className="text-[#777]">*</span>
                          </label>
                          <input
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange("email")}
                            onBlur={handleBlur}
                            className="w-full py-2 px-3 border border-[#ddd] outline outline-transparent focus:outline-transparent text-base text-black font-normal input-box-shadow rounded"
                          />
                          {errors.email && touched.email ? (
                            <div className="text-[red]">{errors.email}</div>
                          ) : null}
                        </div>

                        <div className="mt-6 mx-auto text-center">
                          <button
                            type="submit"
                            disabled={!values.email}
                            // onClick={handleSubmit}
                            className="bg-[#4854e2] text-white py-2.5 font-normal text-base mt-4 w-[12rem] flex items-center
                            justify-center mx-auto relative"
                          > <span className="text-center mx-auto flex justify-center w-full">Send Email</span>

                            {isLoading1 && (
                              <CircularProgress
                                thickness={2}
                                size="sm"
                                style={{ marginLeft: "10px", lineHeight: "0", position: "absolute", right: "20px" }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Modal>
    </>
  );
};

export default Login;
