/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { SetStateAction, useEffect, useState } from "react";

// Navigation
import { useNavigate, useParams } from "react-router-dom";

// material-ui
// import Switch from '@mui/material/Switch';

// Header Component
import Header from "../menu/Header";

// QRcode
import { QRCodeCanvas } from 'qrcode.react';

// Copy to clipboard
import copy from "copy-to-clipboard";

// file-saver
import { saveAs } from "file-saver";

//Loader
import Loader from "./Loader";

// Api Service
import ApiService from "../service/ApiDataRequest";

// App-context
import { useAppContext } from "../hooks/useAppContext";

// Images and Logos
// import player2 from "../assets/image/player2.png";
// import facebook from "../assets/image/facebook.png";
// import instagram from "../assets/image/instagram.png";
// import layout from "../assets/image/layout.png";
// import layout1 from "../assets/image/layout1.png";
// import mail from "../assets/image/mail.png";


// const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ShareEvent = () => {

  const { eventDataById, getEventDataById, setIsOpen, setAlertType, setResponseMessage } = useAppContext()

  // Api url
  const API_URL = process.env.REACT_APP_API_KEY;
  const VISITOR_URL = process.env.REACT_APP_VISITOR_URL;

  const navigate = useNavigate()
  const { id } = useParams()
  // const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const validEmailRegex = /\S+@\S+\.\S+/;

  const [isLoading, setIsLoading] = useState(false)
  // const [accessLink, setAccessLink] = useState(`http://192.168.1.62:3001/${id}`)
  const [accessLink, setAccessLink] = useState(`${VISITOR_URL}/${id}`)
  const [eventAccess, setEventAccess] = useState("facesearch")
  const [shareAccess, setShareAccess] = useState("email")
  const [emailAddress, setEmailAddress] = useState<any[]>([])
  const [copyMsgShow, setCopyMsgShow] = useState(false)
  const [copyLinkMsgShow, setCopyLinkMsgShow] = useState(false)
  const [addressMsgShow, setAdressMsgShow] = useState(false)
  const [subjectMsgShow, setSubjectMsgShow] = useState(false)
  const [messageMsgShow, setMessageMsgShow] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false)
  const [mailDetails, setMailDetails] = useState({
    emailAddress: '',
    emailSubject: '',
    emailMessage: ''
  })


  // useEffect for api function call of getEventDataById 
  useEffect(() => {
    if (!eventDataById) {
      getEventDataById(id as string)
    }
  }, [])

  // function to call when change eventaccess
  const onEventAccessChange = (e: { target: { value: SetStateAction<string> } }) => {
    setEventAccess(e.target.value)
    if (e.target.value === "facesearch") {
      // setAccessLink(`http://192.168.1.62:3001/${id}`)
      setAccessLink(`${VISITOR_URL}/${id}`)
    } else {
      setAccessLink(`${VISITOR_URL}/${id}/fulleventaccess`)
    }
  }

  // function to call when change shareaccess
  const onShareAccessChange = (e: { target: { value: SetStateAction<string> } }) => {
    setShareAccess(e.target.value)
  }

  // function to download generated QR
  const downloadQR = () => {
    const canvas: any = document.getElementById("qrCodeEl");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    saveAs(pngUrl, `${eventAccess}QR.png`)
    // let downloadLink = document.createElement("a");
    // downloadLink.href = pngUrl;
    // downloadLink.download = "123456.png";
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
  };


  //funtion to get QR Url to share event
  const base64QR = () => {
    const canvas: any = document.getElementById("qrCodeEl");
    const pngUrl = canvas && canvas.toDataURL("image/png").replace("image/png", "image/base64");
    const newPngUrl = pngUrl && pngUrl.split(",").slice(1).join(", ")
    return newPngUrl
  };

  // function for api call to share event on mail
  const handleShareEvent = async (payload: any) => {
    setIsLoading(true)
    try {
      const response = await ApiService.shareEvent(payload)
      console.log('response', response)
      setIsLoading(false)
      setIsOpen(true);
      setAlertType("success");
      setResponseMessage(response.message);
    } catch (error: any) {
      console.log('error', error)
      setIsLoading(false)
      setIsOpen(true);
      setAlertType("error");
      setResponseMessage(error.data[0].description);
    }
  }

  // setTimeout to change copy text
  useEffect(() => {
    setTimeout(() => {
      setCopyLinkMsgShow(false)
      setCopyMsgShow(false)
    }, 2000)
  }, [copyMsgShow, copyLinkMsgShow])


  return (
    <>
      <Header />
      {isLoading && <Loader />}
      {!isLoading && <div className="px-[3rem] pb-8 max-lg:px-10 max-sm:px-4">
        <div className="mt-6 flex items-center mb-16">
          <div onClick={() => navigate(`/dashboard/events/${id}`)}>
            <i className="fas fa-chevron-left pr-6 cursor-pointer"></i>
          </div>
          <h2 className="text-3xl font-semibold text-black">
            Share event with people
          </h2>
        </div>

        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
          <div>
            <div className="mr-12 mb-[100px] max-sm:mr-0">
              <div>
                <h5 className="text-lg text-[#7a7a7a] mb-3 capitalize font-medium leading-[22px]">
                  Select access type
                </h5>
                <div className="grid grid-cols-1 grid-flow-row gap-4 sm:grid-cols-2">
                  <div className="mr-0 flex items-center sm:mr-4">
                    <label className="text-sm text-black font-semibold p-3 leading-[14px] cursor-pointer border border-[#e3e3e3] w-full flex items-center">
                      <input
                        type="radio"
                        name="access"
                        value="facesearch"
                        checked={eventAccess === "facesearch"}
                        onChange={onEventAccessChange}
                        className="outline outline-transparent focus:outline-transparent mr-3"
                      />
                      Face search access
                    </label>
                  </div>
                  <div className="flex items-center">
                    <label className="text-sm text-black font-semibold p-3 leading-[14px] cursor-pointer border border-[#e3e3e3] w-full flex items-center">
                      <input
                        type="radio"
                        name="access"
                        value="fullevent"
                        checked={eventAccess === "fullevent"}
                        onChange={onEventAccessChange}
                        className="outline outline-transparent focus:outline-transparent mr-3"
                      />
                      Full event access
                    </label>
                  </div>
                </div>
              </div>

              <div className="mt-10">
                <h5 className="text-lg text-[#7a7a7a] mb-3 capitalize font-medium leading-[22px]">
                  Choose how you want to share
                </h5>
                <div className="grid grid-cols-1 grid-flow-row gap-4 sm:grid-cols-2">
                  <div className="mr-0 flex items-center sm:mr-4">
                    <label className="text-sm text-black font-semibold p-3 leading-[14px] cursor-pointer border border-[#e3e3e3] w-full flex items-center">
                      <input
                        type="radio"
                        name="shareType"
                        value="email"
                        checked={shareAccess === "email"}
                        onChange={onShareAccessChange}
                        className="outline outline-transparent focus:outline-transparent mr-3"
                      />
                      Custom email
                    </label>
                  </div>
                  <div className="flex items-center">
                    <label className="text-sm text-black font-semibold p-3 leading-[14px] cursor-pointer border border-[#e3e3e3] w-full flex items-center">
                      <input
                        type="radio"
                        name="shareType"
                        value="qrcode"
                        checked={shareAccess === "qrcode"}
                        onChange={onShareAccessChange}
                        className="outline outline-transparent focus:outline-transparent mr-3"
                      />
                      QR code / Direct link
                    </label>
                  </div>
                </div>
              </div>

              {shareAccess === "qrcode" &&
                <div className="mt-10">
                  <div className="flex items-center justify-start gap-5">

                    <h5 className="text-lg text-[#7a7a7a] capitalize font-medium leading-[22px]">
                      Download QR Code
                    </h5>
                    <button className="text-sm underline text-[#4854e2] font-semibold" onClick={() => downloadQR()}>Download</button>
                  </div>
                  <div className="bg-[white] pt-[16px]">
                    <QRCodeCanvas
                      id="qrCodeEl"
                      value={accessLink}
                      size={260}
                      level={"H"}
                      includeMargin={true}
                    />
                    {/* <QRCodeCanvas value={`http://192.168.1.62:3001/22923430-e5d3-42bf-9eed-2f69790d4df8/fulleventaccess`} /> */}
                  </div>
                </div>}

              {/* Don't remove this commented code */}
              {/* <div className="mt-10">
                <h5 className="text-lg text-[#7a7a7a] mb-3 capitalize font-medium leading-[22px]">
                  Choose email template
                </h5>
                <div className="grid grid-cols-2 grid-flow-row gap-4">
                  <div className="flex items-center justify-center">
                    <label className="text-center cursor-pointer">
                      <div className="mb-2  shadow-[0_1px_8px_0_rgba(0,0,0,0.2)]">
                        <img src={layout} className="h-28 w-28" alt='layout' />
                      </div>
                      <span className="text-black text-sm font-semibold capitalize text-center">
                        dark
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center justify-center">
                    <label className="text-center cursor-pointer">
                      <div className="mb-2  shadow-[0_1px_8px_0_rgba(0,0,0,0.2)]">
                        <img src={layout1} className="h-28 w-28" alt='layout1' />
                      </div>
                      <span className="text-black text-sm font-semibold capitalize text-center mt-2">
                        light
                      </span>
                    </label>
                  </div>
                </div>
              </div> */}

              {shareAccess === "qrcode" &&
                <div className="mt-10">
                  <h5 className="text-lg text-[#7a7a7a] mb-3 capitalize font-medium leading-[22px]">
                    Copy direct link
                  </h5>

                  <div className="relative">
                    <input
                      type="text"
                      // value={`http://192.168.1.62:3001/${id}/fulleventaccess`}
                      value={accessLink}
                      disabled
                      className="border border-[#e3e3e3] relative text-xs text-black placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent"
                    />
                    <button className="absolute right-[10px] top-[3px] text-[#4854e2] underline"
                      onClick={() => {
                        copy(accessLink)
                        setCopyLinkMsgShow(true)
                      }}
                    // className="text-sm text-[#4854e2] font-semibold absolute right-[12px] top-[6px] capitalize"
                    >
                      {copyLinkMsgShow ? "Copied" : "Copy"}
                    </button>
                  </div>
                </div>}

              {shareAccess === "email" &&
                <>
                  <div className="mt-10">
                    <h5 className="text-lg text-[#7a7a7a] mb-3 capitalize font-medium leading-[22px]">
                      Enter email address to invite people*
                    </h5>
                    <div>
                      <input
                        type="email"
                        placeholder="Enter email address"
                        value={mailDetails.emailAddress}
                        onChange={(e: any) => setMailDetails({ ...mailDetails, emailAddress: e.target.value })}
                        className="border border-[#e3e3e3] text-base text-black placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent"
                      />
                      {!isValidEmail && <span className="text-[red]">Please enter valid email</span>}
                      {isDuplicateEmail && <span className="text-[red]">This email is already included in list</span>}
                      {addressMsgShow && emailAddress.length < 1 && !mailDetails.emailAddress && <span className="text-[red]">Email is required</span>}
                    </div>
                    <div className="flex justify-between items-center">
                      <QRCodeCanvas
                        id="qrCodeEl"
                        value={accessLink}
                        size={10}
                        level={"H"}
                        includeMargin={true}
                        style={{ visibility: "hidden" }}
                      />
                      <button
                        // disabled={mailDetails.emailAddress ? false : true}
                        className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base mt-4 w-[10rem]"
                        onClick={() => {
                          const duplicateEmail = emailAddress.includes(mailDetails.emailAddress)
                          if (mailDetails.emailAddress.match(validEmailRegex) && !duplicateEmail) {
                            console.log(mailDetails.emailAddress, "ll");
                            setIsValidEmail(true)
                            setEmailAddress([...emailAddress, mailDetails.emailAddress])
                            setMailDetails({ ...mailDetails, emailAddress: '' })
                            setIsDuplicateEmail(false)
                          } else if (duplicateEmail) {
                            setIsDuplicateEmail(true)
                          }
                          else {
                            setIsValidEmail(false)
                          }
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <div className="mt-14">
                    <h6 className="text-base text-[#7a7a7a] capitalize font-medium leading-[18px]">
                      Invite List
                    </h6>
                    <div className="flex justify-start gap-6 items-center mt-2">
                      {emailAddress && emailAddress.map((item, index) =>
                        <p key={index}> {item}
                          <i className="fas fa-trash-alt ml-2 cursor-pointer" onClick={() => {
                            const remainingEmail = [...emailAddress]
                            remainingEmail.splice(index, 1)
                            setEmailAddress(remainingEmail)
                          }}></i>
                        </p>
                      )}
                    </div>
                    <div className="mt-10">
                      <h5 className="text-lg text-[#7a7a7a] mb-3 capitalize font-medium leading-[22px]">
                        Email Subject*
                      </h5>
                      <div>
                        <input
                          type="text"
                          onChange={(e: any) => setMailDetails({ ...mailDetails, emailSubject: e.target.value })}
                          placeholder="Lorem Ipsum has been the industry's standard dummy text"
                          className="border border-[#e3e3e3] text-base text-black placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent"
                        />
                        {subjectMsgShow && <span className="text-[red]">Email subject is required</span>}
                      </div>
                    </div>
                    <div className="mt-10">
                      <h5 className="text-lg text-[#7a7a7a] mb-3 capitalize font-medium leading-[22px]">
                        Email message*
                      </h5>
                      <div>
                        <textarea
                          onChange={(e: any) => setMailDetails({ ...mailDetails, emailMessage: e.target.value })}
                          className="border border-[#e3e3e3] text-base text-black placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent"
                          cols={30}
                          rows={8}
                          placeholder="Write your message here!"
                        // defaultValue={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`}
                        />

                        {messageMsgShow && <span className="text-[red]">Email message is required</span>}
                      </div>
                    </div>
                  </div>
                </>}

              <div className="mt-10">
                <div className="grid grid-cols-1 grid-flow-row gap-10 sm:grid-cols-2">
                  <div>
                    {/* <div className="flex justify-between items-center">
                      <h5 className="text-lg text-[#7a7a7a] capitalize font-medium leading-[22px]">
                        Face Search Pin
                      </h5>
                      <Switch {...label} defaultChecked />
                    </div> */}
                    <div className="relative mt-6">
                      <h5 className="text-base text-[#7a7a7a] mb-3 capitalize font-medium leading-[22px]">
                        PIN for access
                      </h5>
                      <div className="border border-[#e3e3e3] text-base text-black relativeplaceholder:text-[#666] py-1.5 px-4 w-full">
                        <button
                          onClick={() => {
                            copy(eventDataById?.eventCode)
                            setCopyMsgShow(true)
                          }}
                          className="text-sm text-[#4854e2] font-semibold absolute right-[12px] top-[6px] capitalize">
                          {copyMsgShow ? "Copied" : "Copy"}
                        </button>
                        {eventDataById?.eventCode}
                      </div>
                    </div>
                  </div>

                  {/* <div>
                    <div className="flex justify-between items-center">
                      <h5 className="text-lg text-[#7a7a7a] capitalize font-medium leading-[22px]">
                        Liveness
                      </h5>
                      <Switch {...label} />
                    </div>
                    <div>
                      <p className="text-black text-sm font-normal">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s
                      </p>
                    </div>
                  </div> */}
                </div>
                <div className="text-center mx-auto mt-8">
                  <button
                    // disabled={emailAddress &&
                    //   mailDetails.emailSubject
                    //   && mailDetails.emailMessage
                    //   ? false : true}
                    className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base mt-4 w-[10rem]"
                    onClick={() => {
                      setAdressMsgShow(mailDetails.emailAddress || emailAddress.length > 0 ? false : true)
                      setSubjectMsgShow(mailDetails.emailSubject ? false : true)
                      setMessageMsgShow(mailDetails.emailMessage ? false : true)
                      if (emailAddress.length > 0 &&
                        mailDetails.emailSubject && mailDetails.emailMessage) {
                        handleShareEvent({
                          eventId: id,
                          accessType: 0,
                          shareType: 0,
                          qrCodeImageBase64: base64QR(),
                          arrEmail: emailAddress,
                          emailSubject: mailDetails.emailSubject,
                          emailBody: mailDetails.emailMessage,
                          eventLink: accessLink,
                          isPasswordProtected: true
                        })
                        setEmailAddress([])
                      }
                    }}
                  >
                    Share Event
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h5 className="text-lg text-[#7a7a7a] mb-3 font-medium leading-[22px]">
              Preview email
            </h5>
            <div className="p-6 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] pb-8">
              {eventDataById?.coverImage && (
                <img
                  src={`${API_URL}/${eventDataById?.coverImage}`}
                  alt={eventDataById.eventName}
                />
              )}
              <div className="mt-10">
                <h4 className="text-black text-2xl font-bold">Hi,</h4>
                <p className="text-[#777] font-normal text-base mt-4" style={{ overflowWrap: 'anywhere' }}>
                  {mailDetails.emailMessage}
                </p>
                <p className="text-[#777] font-normal text-base mt-4">Use this four digit pin when asked - </p>
                {eventDataById?.eventCode}
                <div className="mx-auto text-center my-8">
                  <a
                    href={accessLink}
                    target="_blank"
                    className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base mt-4 w-[10rem] cursor-pointer" rel="noreferrer"
                  >
                    Find Photos
                  </a>
                  {/* <button
                    onClick={() => console.log('click')}
                    className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base mt-4 w-[10rem]"
                  >
                    Find Photos
                  </button> */}
                </div>
              </div>
              <div className="text-center border-t border-[#ddd] pt-8">
                <p className="text-base text-black font-normal mb-1 Capitalize leading-[16px]">
                  Questions? Contact
                </p>
                <a className="text-base text-black font-normal" href="#">
                  text@gmail.com
                </a>
                {/* <div className="mt-6">
                  <ul className="flex mx-auto text-center justify-center">
                    <li className="mx-1 cursor-pointer">
                      <img src={facebook} className="w-6" alt="facebook" />
                    </li>
                    <li className="mx-1 cursor-pointer">
                      <img src={instagram} className="w-6" alt="instagram" />
                    </li>
                    <li className="mx-1 cursor-pointer">
                      <img src={mail} className="w-6" alt="mail" />
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            {/* use dark theme section */}
            {/* <div className="p-6 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] pb-8 bg-black">
              <img
                src={player2}
                className="w-full max-w-none h-[800px] object-cover object-left-top"
              />
              <div className="mt-10">
                <h4 className="text-white text-2xl font-bold">Hi,</h4>
                <p className="text-[#fff] font-normal text-base mt-4">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s
                </p>
                <p className="text-[#fff] font-normal text-base mt-4">
                  when an unknown printer took a galley of type and scrambled it
                  to make a type specimen book. It has survived not only five
                  centuries, but also the leap into electronic typesetting,
                  remaining essentially unchanged.
                </p>
                <div className="mx-auto text-center my-8">
                  <button className="bg-[#4854e2] text-white px-6 py-2.5 font-normal text-base mt-4 w-[10rem]">
                    Find Photos
                  </button>
                </div>
              </div>
              <div className="text-center border-t border-[#ddd] pt-8">
                <p className="text-base text-white font-normal mb-1 Capitalize leading-[16px]">
                  Questions? Contact
                </p>
                <a className="text-base text-white font-normal" href="#">
                  text@gmail.com
                </a>
                <div className="mt-6">
                  <ul className="flex mx-auto text-center justify-center">
                    <li className="mx-1">
                      <img src={facebook} className="w-6" />
                    </li>
                    <li className="mx-1">
                      <img src={instagram} className="w-6" />
                    </li>
                    <li className="mx-1">
                      <img src={mail} className="w-6" />
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div >}
    </>
  );
};

export default ShareEvent;
