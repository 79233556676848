// http common request
import http from "./http-common";

// type interface 
import { Payload } from "../interface/ProfileInterface";


////////// Start : Authenticate //////////

// Api : login user
const loginUser = (email: string, password: string) => {
    return http.post(`v1/PhotoFaceFilter/Login`, {
        "email": email,
        "password": password
    })
}

// Api : Forgot Password
const forgotPassword = (emailId: string) => {
    return http.get(`v1/PhotoFaceFilter/ForgotPassword/${emailId}`)
}

////////// End : Authenticate //////////


////////// Start : Event //////////

// Api : Create Event
const createEvent = (userId: string, eventName: string, eventDate: string) => {
    return http.post(`v1/PhotoFaceFilter/CreateEvent/${userId}`, {
        "eventName": eventName,
        "eventDate": eventDate
    })
}

// Api : Update Event Data

const updateEventData = (eventId: any, payload: Payload) => {
    return http.patch(`v1/PhotoFaceFilter/UpdateEventData/${eventId}`, payload
    )
}

// Api : Get Event By Id
const getEventById = (eventId: string) => {
    return http.get(`v1/PhotoFaceFilter/GetEventById/${eventId}`)
}

// Api : Get Event Dynamic List
const getEventDynamicList = (pageNo: number, pageSize: number, userId: string, searchText?: string, orderByColumnName?: string, sortColumnDir?: string, status?: string) => {
    return http.get(`v1/PhotoFaceFilter/GetEventDynamicList/${pageNo}/${pageSize}/${userId}?searchText=${searchText}&orderByColumnName=${orderByColumnName}&sortColumnDir=${sortColumnDir}&status=${status}`)
}

// Api : Delete Event By Id
const deleteEventById = (eventId: string) => {
    return http.delet(`v1/PhotoFaceFilter/DeleteEventById/${eventId}`)
}

// Api : Publish Event

const publishEvent = (eventId: string, isPublish: boolean) => {
    return http.put(`v1/PhotoFaceFilter/PublishEvent`, {
        "eventId": eventId,
        "isPublish": isPublish
    })
}

// Api : Share Event

const shareEvent = (payload: any) => {
    return http.post(`v1/PhotoFaceFilter/ShareEvent`, payload)
}
// const shareEvent = () => {
//     return http.post(`v1/PhotoFaceFilter/ShareEvent`, {
//         "eventId": eventId,
//         "accessType": accessType,
//         "shareType": shareType,
//         "qrCodeImageBase64": qrCodeImageBase64,
//         "arrEmail": arrEnail,
//         "emailSubject": emailSubject,
//         "emailBody": emailBody,
//         "eventLink": eventLink,
//         "isPasswordProtected": isPasswordProtected
//     })
// }

////////// End : Event //////////


////////// Start : Image //////////

// Api : get Dynamic Image Data List
const getDynamicImageDataList = (eventId?: string, userId?: string, searchText?: string, orderByColumnName?: string, sortColumnDir?: string) => {
    return http.get(`v1/PhotoFaceFilter/GetDynamicImageDataList?eventId=${eventId}&userId=${userId}&searchText=${searchText}&orderByColumnName=${orderByColumnName}&sortColumnDir=${sortColumnDir}`)
}

// Api : get Image Data By Id
const getImageDataById = (id: string) => {
    return http.get(`v1/PhotoFaceFilter/GetImageDataById/${id}`)
}

// Api: Upload Event Images
const uploadEventImages = (body: any,options:any) => {
    return http.post(`v1/PhotoFaceFilter/UploadEventImages`, body,options)
}

// Api : Retrive Face Images
const retriveFaceImages = (faceImage: string) => {
    return http.post(`v1/PhotoFaceFilter/RetriveFaceImages`, {
        "faceImage": faceImage
    })
}

// Api : Delete Image Data By Id
const deleteImageDataById = (ids: string[]) => {
    return http.deletes(`v1/PhotoFaceFilter/DeleteImageDataById`, ids)
}

////////// End : Image //////////


////////// Start : Visitor User //////////

// Api : get all register user (visitor)
const getAllRegisterVisitor = (eventId: string) => {
    return http.get(`v1/PhotoFaceFilter/GetAllRegisterUserByEventId/${eventId}`)
}

////////// End : Visitor User //////////


////////// Start : Role //////////

// Api : get Dynamic Role Data List
const getDynamicRoleDataList = (pageNo: number, pageSize: number, searchText: string, orderByColumnName: string, sortColumnDir: string) => {
    return http.get(`v1/PhotoFaceFilter/GetDynamicRoleDataList/${pageNo}/${pageSize}?searchText=${searchText}&orderByColumnName=${orderByColumnName}&sortColumnDir=${sortColumnDir}`)
}

// Api : get Role By Id
const getRoleById = (roleId: string) => {
    return http.get(`v1/PhotoFaceFilter/GetRoleById/${roleId}`)
}

// Api : get All Role
const getAllRole = () => {
    return http.get(`v1/PhotoFaceFilter/GetAllRole`)
}

// Api : Add Or Update Role
const addOrUpdateRole = (id: string, roleName: string) => {
    return http.post(`v1/PhotoFaceFilter/AddOrUpdateRole`, {
        "id": id,
        "roleName": roleName
    })
}

////////// End : Role //////////


///////////// Start : Tag /////////////

//Api : Get tag By id
const getTagById = (id: string) => {
    return http.get(`v1/PhotoFaceFilter/GetTagById/${id}`)
}

//Api : Get All Tag
const getAllTag = (id: string) => {
    return http.get(`v1/PhotoFaceFilter/GetAllTag/${id}`)
}

//Api : Add or Update Tag
const addOrUpdateTag = (id: string, userId: string, name: string) => {
    return http.post(`v1/PhotoFaceFilter/AddOrUpdateTag`, {
        "id": id,
        "UserId": userId,
        "name": name
    })
}

//Api : Delete Tag
const deleteTag = (id: string) => {
    return http.delet(`v1/PhotoFaceFilter/DeleteTagById/${id}`)
}

///////////// End : Tag //////////////////

////////// Start : Admin User //////////

// Api : User Registration 
const registerUser = (email: string, firstName: string, lastName: string, password: string, phoneNumber: string) => {
    return http.post(`v1/PhotoFaceFilter/UserRegistration`, {
        "email": email,
        "firstName": firstName,
        "lastName": lastName,
        "password": password,
        "phoneNumber": phoneNumber
    });
}

// Api : update User Profile
const updateUserProfile = (id: string, payload: Payload) => {
    return http.patch(`v1/PhotoFaceFilter/UpdateUserProfile/${id}`, payload)
}

// Api : get User Details By User Id

const getUserDetailsByUserId = (id: string) => {
    return http.get(`v1/PhotoFaceFilter/GetUserDetailsByUserId/${id}`)
}

//Api : get all countries
const getAllCountry = () => {
    return http.get(`v1/PhotoFaceFilter/GetAllCountry`)
}

////////// End : Admin User //////////


const ApiService = {
    loginUser,
    forgotPassword,
    createEvent,
    updateEventData,
    getEventById,
    getEventDynamicList,
    deleteEventById,
    publishEvent,
    shareEvent,
    getDynamicImageDataList,
    getImageDataById,
    uploadEventImages,
    retriveFaceImages,
    deleteImageDataById,
    getAllRegisterVisitor,
    getDynamicRoleDataList,
    getRoleById,
    getAllRole,
    addOrUpdateRole,
    registerUser,
    updateUserProfile,
    getUserDetailsByUserId,
    getTagById,
    getAllTag,
    addOrUpdateTag,
    deleteTag,
    getAllCountry
}

export default ApiService