// material-ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

// Image open Modal style
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "transparent",
    boxShadow: "none",
    p: "100px 160px",
    "@media (min-width: 768px)": {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
};

// Api url
const API_URL = process.env.REACT_APP_API_KEY;

const ShowFullScreenImage = ({ open, onClose, imageDetailById, activeImage, displayPrevious, imageListDataId, displayNext }: any) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <div
                        className="cursor-pointer absolute right-[30px] top-[30px]"
                        onClick={onClose}
                    >
                        <i className="fas fa-times text-white text-[26px]" />
                    </div>
                    <div className="">
                        {imageDetailById?.imageLink &&
                            <img
                                src={`${API_URL}/${imageDetailById?.imageLink}`}
                                alt={imageDetailById?.imageName}
                                className="mx-auto object-contain h-[840px]"
                            />}
                    </div>
                    <div className="absolute top-[50%] translate-y-[-50%] left-[60px] right-[60px] mx-auto flex justify-between">
                        <div
                            className={activeImage > 0 ? "cursor-pointer" : ""}
                            onClick={displayPrevious}
                        >
                            <i className="fas fa-angle-left text-[#ddd] text-[32px]"></i>
                        </div>
                        <div
                            className={
                                activeImage < imageListDataId.length - 1
                                    ? "cursor-pointer"
                                    : ""
                            }
                            onClick={displayNext}
                        >
                            <i className="fas fa-angle-right text-[#ddd] text-[32px]"></i>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ShowFullScreenImage