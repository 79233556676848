/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

// Navigation
import { useNavigate, useParams } from "react-router-dom";

// material-ui
import Switch from "@mui/material/Switch";

// material-ui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/joy/CircularProgress";

// Header Component
import Header from "../menu/Header";

// Api Service
import ApiService from "../service/ApiDataRequest";

//context
import { useAppContext } from "../hooks/useAppContext";

// moment
import moment from "moment";

// Images and Logos
// import facebook from "../assets/image/facebook.png";
// import instagram from "../assets/image/instagram.png";
// import twitter from "../assets/image/twitter.png";
// import youtube from "../assets/image/youtube.png";
// import player2 from "../assets/image/player2.png";
import split from "../assets/image/split.png";
import labelClick from "../assets/image/label-hightlight.png";
import border from "../assets/image/border.png";
import corner from "../assets/image/corner.png";
import smallGrid from "../assets/image/smallGrid.png";
import regularGrid from "../assets/image/regularGrid.png";
import large from "../assets/image/large.png";
import regularThambnail from "../assets/image/regularThambnail.png";
import smallThambnail from "../assets/image/smallThambnail.png";
import largeThambnail from "../assets/image/largeThambnail.png";
import layout from "../assets/image/layout.png";
import layout1 from "../assets/image/layout1.png";
import horizontalGrid from "../assets/image/horizontalGrid.png";

// type interface
import { VisitorInterface } from "../interface/EventSettingsInterface";

// react-scroll
import { Link, Element } from 'react-scroll'

//MUI calender
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';

const label = { inputProps: { "aria-label": "Switch demo" } };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 360,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  "@media (min-width: 768px)": {
    width: "600px",
  },
};

const EventSettings = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // Api url
  const API_URL = process.env.REACT_APP_API_KEY;

  const { handleUpdateEventData, eventDataById, getEventDataById, isUpdateEvent } = useAppContext()

  const [modalExpiry, setModalExpiry] = useState(false);
  const [modalShare, setModalShare] = useState(false);
  const [visitorUser, setVisitorUser] = useState<VisitorInterface[]>();
  const [eventStartDate, setEventStartDate] = useState<any>('')
  const [eventName, setEventName] = useState<string>('')
  const [eventExpiryDate, seteventExpiryDate] = useState('')
  const [eventCoverImage, setEventCoverImage] = useState<any>()
  const [changedValue, setChangedValue] = useState<any>()

  // Function for Api call to get all register visitor
  const getAllRegisterVisitor = async () => {

    try {
      const response = await ApiService.getAllRegisterVisitor(id as string);
      console.log("response", response);
      setVisitorUser(response.data.data);
    } catch (error: any) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getAllRegisterVisitor();
  }, []);

  useEffect(() => {
    getEventDataById(id as string)
  }, [])

  useEffect(() => {
    setEventName(eventDataById?.eventName)
    setEventStartDate(moment(eventDataById?.eventDate).format("YYYY-MM-DD"))
    seteventExpiryDate(moment(eventDataById?.expiryDate).format("YYYY-MM-DD"))
    eventDataById?.coverImage && setEventCoverImage(`${API_URL}/${eventDataById?.coverImage}`)
  }, [eventDataById])

  return (
    <>
      <Header />
      <div>
        <div className="flex items-start">
          <div
            className=" w-[20%] mr-6 shadow-sidebar pt-7 pl-[3rem] pr-4 h-[calc(100vh_-_67px)] sticky top-[67px] bg-[#f1f1f1] 
           max-[1350px]:pl-4 max-sm:pl-[1rem] max-sm:w-[30%] max-sm:mr-3"
          >
            <div
              className="cursor-pointer mb-6 flex items-center text-base text-black font-bold"
              onClick={() => navigate(`/dashboard/events/${id}`)}
            >
              <i className="fas fa-chevron-left pr-3"></i>
              Back
            </div>
            <ul>

              <li className='cursor-pointer text-base font-medium  my-1.5  pl-4 pr-6 hover:text-white max-[450px]:text-xs text-black max-lg:pr-1 max-[450px]:pl-1'>
                <Link
                  style={{display : "block"}}
                  className=" font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm text-black max-[1200px]:w-[160px] max-lg:w-full max-lg:pr-1 max-lg:text-sm max-[450px]:pl-1"
                  activeClass="w-full text-base font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm bg-[#4854e2] text-white max-[1200px]:w-[160px] max-lg:w-full max-lg:pr-1 max-lg:text-sm max-[450px]:pl-1"
                  to="test1"
                  spy={true}
                  smooth={true}
                  duration={50}
                >
                  General
                </Link>
              </li>

              <li className='cursor-pointer text-base font-medium my-1.5 pl-4 pr-6 hover:text-white max-[450px]:text-xs text-black max-lg:pr-1 max-[450px]:pl-1'>
                <Link
                  style={{display:"block"}}
                  className=" font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm text-black max-[1200px]:w-[160px] max-lg:w-full max-lg:pr-1 max-lg:text-sm max-[450px]:pl-1"
                  activeClass="text-base font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm bg-[#4854e2] text-white max-[1200px]:w-[160px] max-lg:w-full max-lg:pr-1 max-lg:text-sm max-[450px]:pl-1"
                  to="test2"
                  spy={true}
                  smooth={true}
                  duration={50}
                >
                  Cover Image
                </Link>
              </li>

              <li className='cursor-pointer text-base font-medium my-1.5  pl-4 pr-6 hover:text-white max-[450px]:text-sm text-black max-lg:pr-3 max-[450px]:pl-1'>
                <Link
                  style={{display:"block"}}
                  className=" font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm text-black max-[1200px]:w-[160px] max-lg:w-full max-lg:pr-3 max-lg:text-sm max-[450px]:pl-1"
                  activeClass="text-base font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm bg-[#4854e2] text-white max-[1200px]:w-[160px] max-lg:w-full max-lg:pr-3 max-lg:text-sm max-[450px]:pl-1"
                  to="test3"
                  spy={true}
                  smooth={true}
                  duration={50}
                >
                  Gallery layout
                </Link>
              </li>
    
              <li className='cursor-pointer text-base font-medium  my-1.5  pl-4 pr-6 hover:text-white max-[450px]:text-sm text-black max-lg:pr-3 max-[450px]:pl-1'>
                <Link
                  style={{display:"block"}}
                  className=" font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm text-black max-[1200px]:w-[160px] max-lg:w-full max-lg:pr-3 max-lg:text-sm max-[450px]:pl-1"
                  activeClass="text-base font-medium py-2.5 my-2 hover:bg-[#4854e2] pl-4 pr-6 hover:text-white max-[450px]:text-sm bg-[#4854e2] text-white max-[1200px]:w-[160px] max-lg:w-full max-lg:pr-3 max-lg:text-sm max-[450px]:pl-1"
                  to="test4"
                  spy={true}
                  smooth={true}
                  duration={50}
                >
                  Sharing
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-[80%] max-sm:w-[70%]">
            <div className="flex flex-col pr-[3rem] grow pb-44 max-lg:pr-10 max-sm:px-4">
              <h2 className="text-3xl font-semibold text-black mt-6">
                Event Settings
              </h2>

              <Element name="test1" className="element" >
                <div className="mt-4 mb-20" id="general">
                  <h3 className="bg-[#f3f3f3] py-2 px-4 w-full text-xl font-semibold text-black mb-7">
                    General
                  </h3>
                  <div className="grid grid-cols-2 gap-5 max-lg:grid-cols-1">
                    <div className="pr-16 max-[1023px]:pr-0">
                      <div>
                        <label className="text-base text-black font-medium">
                          Name
                        </label>
                        <div className="relative">
                          <input
                            onChange={(e) => {
                              setEventName(e.target.value)
                            }}
                            value={eventName ? eventName : ""}
                            type="text"
                            placeholder="Jhon Wick"
                            className="border border-[#e3e3e3] text-base text-black 
                            placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent mt-1"
                          />
                          <button
                            onClick={() => {
                              handleUpdateEventData(id as string, { EventName: eventName }, '')
                              setChangedValue("eventName")
                            }}
                            className="text-sm text-[#4854e2] font-semibold absolute right-[12px] top-[12px] capitalize"
                          >
                            update {isUpdateEvent && changedValue === "eventName" && (
                              <CircularProgress
                                thickness={2}
                                size="sm"
                                style={{ marginLeft: "10px", lineHeight: "0", position: "absolute", right: "60px", top: "-2px" }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                      {/* <div className="mt-8">
                        <div className="relative flex justify-between">
                          <label className="text-base text-black font-medium">
                            Email Registration
                          </label>
                          <Switch {...label} defaultChecked />
                        </div>
                        <p className="text-black text-sm font-normal">
                          Disable Email Registration for this event
                        </p>
                      </div>
                      <div className="mt-8">
                        <div className="relative flex justify-between">
                          <label className="text-base text-black font-medium">
                            Social sharing buttons
                          </label>
                          <Switch {...label} defaultChecked />
                        </div>
                        <p className="text-black text-sm font-normal">
                          Share your work with clients
                        </p>
                        <div className="mt-8">
                          <ul className="flex">
                            <li className="mr-4 cursor-pointer">
                              <img
                                src={facebook}
                                className="h-8 w-8"
                                alt="facebookIcon"
                              />
                            </li>
                            <li className="mr-4 grayscale-[50%] point-event-none">
                              <img
                                src={instagram}
                                className="h-8 w-8"
                                alt="instagramIcon"
                              />
                            </li>
                            <li className="mr-4 cursor-pointer">
                              <img
                                src={twitter}
                                className="h-8 w-8"
                                alt="twitterIcon"
                              />
                            </li>
                            <li className="mr-4 cursor-pointer">
                              <img
                                src={youtube}
                                className="h-8 w-8"
                                alt="youtubeIcon"
                              />
                            </li>
                          </ul>
                        </div>
                      </div> */}
                      {/* <div className="mt-8">
                      <div className="relative flex justify-between">
                        <label className="text-base text-black font-medium">
                          Print Store
                        </label>
                        <Switch {...label} />
                      </div>
                      <p className="text-black text-sm font-normal">
                        Enable Print Store in General Settings to access it
                        here.
                      </p>
                    </div> */}
                      {/* <div className="mt-8">
                      <div className="relative flex justify-between">
                        <label className="text-base text-black font-medium">
                          Mobile Field
                        </label>
                        <Switch {...label} defaultChecked />
                      </div>
                      <p className="text-black text-sm font-normal">
                        Hide mobile number field in face search form
                      </p>
                    </div> */}
                    </div>
                    <div className="pl-16 max-[1023px]:pl-0">
                      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                        <div>
                          <label className="text-base text-black font-medium">
                            Event date
                          </label>
                          {isUpdateEvent && changedValue === "eventStartDate" && (
                            <CircularProgress
                              thickness={2}
                              size="sm"
                              style={{ marginLeft: "10px", lineHeight: "0" }}
                            />
                          )}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="border border-[#e3e3e3] text-base text-black 
                           placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent mt-1"
                              value={dayjs(eventStartDate)}
                              onChange={(e: any) => {
                                const date = moment(e.$d).format()
                                handleUpdateEventData(id as string, { EventDate: moment(date).format("YYYY-MM-DD") }, '')
                                setEventStartDate(moment(date).format("YYYY-MM-DD"))
                                setChangedValue("eventStartDate")
                              }}
                            />
                          </LocalizationProvider>
                          {/* <input
                          onChange={(e) => {
                            handleUpdateEventData(id as string, { EventDate: e.target.value }, '')
                            setEventStartDate(e.target.value)
                          }}
                          value={eventStartDate}
                          type="date"
                          className="border border-[#e3e3e3] text-base text-black 
                            placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent mt-1"
                        /> */}
                        </div>
                        <div>
                          <label className="text-base text-black font-medium flex items-center">
                            Expiry date
                            <div
                              onClick={() => setModalExpiry(true)}
                              className="pl-1"
                            >
                              <i className="fas fa-exclamation-circle cursor-pointer"></i>
                            </div>
                            {isUpdateEvent && changedValue === "expiryDate" && (
                              <CircularProgress
                                thickness={2}
                                size="sm"
                                style={{ marginLeft: "10px", lineHeight: "0" }}
                              />
                            )}
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="border border-[#e3e3e3] text-base text-black 
                           placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent mt-1"
                              value={dayjs(eventExpiryDate)}
                              minDate={dayjs(eventStartDate)}
                              onChange={(e: any) => {
                                const date = moment(e.$d).format()
                                handleUpdateEventData(id as string, { ExpiryDate: moment(date).format("YYYY-MM-DD") }, '')
                                seteventExpiryDate(moment(date).format("YYYY-MM-DD"))
                                setChangedValue("expiryDate")
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      {/* <div className="mt-8">
                        <div className="relative flex justify-between">
                          <label className="text-base text-black font-medium">
                            Password Protection
                          </label>
                          <Switch {...label} />
                        </div>
                        <p className="text-black text-sm font-normal">
                          Enable Password for this event
                        </p>
                      </div> */}

                      {/* <div className="mt-8">
                      <div className="relative flex justify-between">
                        <label className="text-xl text-black font-medium">
                          Watermark
                        </label>
                      </div>
                      <p className="text-black text-sm font-normal">
                        Go to{" "}
                        <button className="text-[#4854e2] text-base font-semibold">
                          General Settings
                        </button>{" "}
                        to edit watermark settings.
                      </p>
                    </div> */}

                      {/* <div className="mt-3">
                      <label className="text-base text-black font-medium">
                        Select watermark preset
                      </label>

                      <div className="relative flex mt-1">
                        <div className="max-sm:mt-3 sorting-menu w-full">
                          <FormControl sx={{ m: 1, width: "100%" }}>
                            <Select
                              value={age}
                              onChange={handleChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">
                                <span>Select watermark preset</span>
                              </MenuItem>
                              <MenuItem value={10}>Jhon Vertical</MenuItem>
                              <MenuItem value={20}>Jhon</MenuItem>
                              <MenuItem value={30}>Remove watermark</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div> */}

                      {/* <div className="mt-8">
                      <div className="relative flex justify-between">
                        <label className="text-base text-black font-medium">
                          Chat Bot
                        </label>
                        <Switch {...label} defaultChecked />
                      </div>
                      <p className="text-black text-sm font-normal">
                        Disable Chat Bot for this event
                      </p>
                    </div> */}
                      {/* <div className="mt-8">
                        <div className="relative flex justify-between">
                          <label className="text-base text-black font-medium">
                            Image Share
                          </label>
                          <Switch {...label} defaultChecked />
                        </div>
                        <p className="text-black text-sm font-normal">
                          Hide share option on gallery
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Element>

              <Element name="test2" className="element" >
                <div className="mt-4 mb-20" id="cover-image">
                  <h3 className="bg-[#f3f3f3] py-2 px-4 w-full flex text-xl font-semibold text-black mb-7">
                    Cover Image
                    {isUpdateEvent && changedValue === "coverImage" ? (
                      <CircularProgress
                        thickness={2}
                        size="sm"
                        style={{ marginLeft: "10px", lineHeight: "0" }}
                      />
                    ) : (
                      <label className="bg-[#4854e2] flex items-center justify-center h-8 w-8 rounded-full ml-4 cursor-pointer">
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/webp"
                          onChange={(e: any) => {
                            handleUpdateEventData(id as string, { CoverImage: e.target.files[0] }, e.target.files[0])
                            setEventCoverImage(URL.createObjectURL(e.target.files[0]))
                            setChangedValue("coverImage")
                          }}
                          className="h-0 w-0 opacity-0"
                        />
                        <i className="fas fa-upload text-white text-base leading-[16px]"></i>
                      </label>
                    )}
                  </h3>
                  <div className="flex flex-row gap-8 max-sm:flex-wrap">
                    <div className="basis-3/4">
                      <div>
                        <h6 className="text-base text-black font-medium mb-3">
                          Desktop view
                        </h6>
                        { eventCoverImage && (
                          <img
                            className="overflow-hidden w-full h-auto object-cover bg-center bg-no-repeat bg-cover relative aspect-video shadow-[0_1px_8px_0_rgba(0,0,0,0.4)]"
                            // src={`${API_URL}/${eventDataById?.coverImage}`}
                            src={eventCoverImage}
                            alt=""
                          />)}
                        {/* <div
                        className="overflow-hidden w-full h-auto bg-center bg-no-repeat bg-cover relative 
                          aspect-video shadow-[0_1px_8px_0_rgba(0,0,0,0.4)]"
                        style={{ backgroundImage: `url(${player2})` }}
                      ></div> */}
                      </div>
                      <div className="flex mt-8 max-[450px]:flex-wrap">
                        <div>
                          <p className="text-lg text-black font-bold leading-[22px] capitalize">
                            Split
                          </p>
                          <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1">
                            <img src={split} alt="split" />
                          </div>
                        </div>
                        <div className="ml-6 max-[450px]:ml-0">
                          <p className="text-lg text-black font-bold leading-[22px] capitalize">
                            label
                          </p>
                          <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1">
                            <img src={labelClick} alt="labelClick" />
                          </div>
                        </div>
                        <div className="ml-6 max-[450px]:ml-0">
                          <p className="text-lg text-black font-bold leading-[22px] capitalize">
                            border
                          </p>
                          <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1">
                            <img src={border} alt="border" />
                          </div>
                        </div>
                        <div className="ml-6 max-[450px]:ml-0">
                          <p className="text-lg text-black font-bold leading-[22px] capitalize">
                            corner
                          </p>
                          <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1">
                            <img src={corner} alt="corner" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="basis-1/4">
                      <div>
                        <h6 className="text-base text-black font-medium mb-3">
                          Mobile view
                        </h6>
                        {eventCoverImage &&
                          <img
                            className="overflow-hidden w-full bg-center object-cover bg-no-repeat bg-cover relative aspect-mobile-crop h-auto shadow-[0_1px_8px_0_rgba(0,0,0,0.4)]"
                            // src={`${API_URL}/${eventDataById && eventDataById.coverImage}`}
                            src={eventCoverImage}
                            alt=""
                          />}
                        {/* <div
                        className="overflow-hidden w-full bg-center bg-no-repeat bg-cover relative 
                          aspect-mobile-crop h-auto shadow-[0_1px_8px_0_rgba(0,0,0,0.4)]"
                        style={{ backgroundImage: `url(${player2})` }}
                      ></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Element>

              <Element name="test3" className="element" >
                <div className="mt-4 mb-20" id="gallery-layout">
                  <h3 className="bg-[#f3f3f3] py-2 px-4 w-full flex text-xl font-semibold text-black mb-7">
                    Gallery Layout
                  </h3>

                  <div className="flex justify-start max-lg:flex-wrap">
                    <div className="pr-32 max-lg:pr-10">
                      <p className="text-lg text-black font-semibold leading-[20px] capitalize">
                        Grid spacing
                      </p>
                      <div className="flex mt-4 gap-8">
                        <div>
                          <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1 cursor-pointer">
                            <img src={smallGrid} alt="smallGrid" />
                          </div>
                          <p className="text-sm text-black font-semibold leading-[22px] capitalize mt-3 text-center mx-auto">
                            Small
                          </p>
                        </div>
                        <div>
                          <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1 cursor-pointer">
                            <img src={regularGrid} alt="regularGrid" />
                          </div>
                          <p className="text-sm text-black font-semibold leading-[22px] capitalize mt-3 text-center mx-auto">
                            Regular
                          </p>
                        </div>

                        <div>
                          <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1 cursor-pointer">
                            <img src={large} alt="large" />
                          </div>
                          <p className="text-sm text-black font-semibold leading-[22px] capitalize mt-3 text-center mx-auto">
                            Large
                          </p>
                        </div>
                      </div>

                      <div className="mt-8">
                        <p className="text-lg text-black font-semibold leading-[20px] capitalize">
                          Thumbnails
                        </p>
                        <div className="flex mt-4 gap-8">
                          <div>
                            <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1 cursor-pointer">
                              <img src={smallThambnail} alt="smallGrid" />
                            </div>
                            <p className="text-sm text-black font-semibold leading-[22px] capitalize mt-3 text-center mx-auto">
                              Small
                            </p>
                          </div>
                          <div>
                            <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1 cursor-pointer">
                              <img
                                src={regularThambnail}
                                alt="regularThambnail"
                              />
                            </div>
                            <p className="text-sm text-black font-semibold leading-[22px] capitalize mt-3 text-center mx-auto">
                              Regular
                            </p>
                          </div>

                          <div>
                            <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1 cursor-pointer">
                              <img src={largeThambnail} alt="large" />
                            </div>
                            <p className="text-sm text-black font-semibold leading-[22px] capitalize mt-3 text-center mx-auto">
                              Large
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="max-md:mt-8">
                      <p className="text-lg text-black font-semibold leading-[20px] capitalize">
                        Grid layout
                      </p>
                      <div className="flex mt-4 gap-8">
                        <div>
                          <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1 cursor-pointer">
                            <img src={regularThambnail} alt="smallGrid" />
                          </div>
                          <p className="text-sm text-black font-semibold leading-[22px] capitalize mt-3 text-center mx-auto">
                            Vertical
                          </p>
                        </div>
                        <div>
                          <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1 cursor-pointer">
                            <img src={horizontalGrid} alt="regularGrid" />
                          </div>
                          <p className="text-sm text-black font-semibold leading-[22px] capitalize mt-3 text-center mx-auto">
                            Regular
                          </p>
                        </div>
                      </div>

                      <div className="mt-8">
                        <p className="text-lg text-black font-semibold leading-[20px] capitalize">
                          Preview theme for viewers
                        </p>
                        <div className="flex mt-4 gap-8">
                          <div>
                            <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1 cursor-pointer">
                              <img
                                src={layout1}
                                className="h-[100px] w-[100px]"
                                alt="layout1"
                              />
                            </div>
                            <p className="text-sm text-black font-semibold leading-[22px] capitalize mt-3 text-center mx-auto">
                              Light mode
                            </p>
                          </div>
                          <div>
                            <div className="mt-4 shadow-[0_1px_8px_0_rgba(0,0,0,0.2)] p-1 cursor-pointer">
                              <img
                                src={layout}
                                className="h-[100px] w-[100px]"
                                alt="layout"
                              />
                            </div>
                            <p className="text-sm text-black font-semibold leading-[22px] capitalize mt-3 text-center mx-auto">
                              Dark mode
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="mt-8 flex flex-col basis-1/2 max-w-[25rem]">
                      <div className="relative flex justify-between">
                        <div>
                          <label className="text-base text-black font-medium">
                            Rounded corners
                          </label>
                          <p className="text-black text-sm font-normal pr-20 mt-6">
                            Toggle to switch between rounded v/s sharp corners for
                            gallery images
                          </p>
                        </div>

                        <Switch {...label} defaultChecked />
                      </div>
                    </div>
                  </div>
                </div>
              </Element>

              {/* <div className="mt-4 mb-20" id="downloads">
                <h3 className="bg-[#f3f3f3] py-2 px-4 w-full text-xl font-semibold text-black mb-7">
                  Download Settings
                </h3>
                <p className="text-lg text-black font-semibold leading-[22px] mb-8">
                  Download images
                </p>
                <div className="grid grid-cols-2 gap-5 max-lg:grid-cols-1">
                  <div className="pr-16">
                    <div>
                      <div className="relative grid grid-cols-2 gap-4">
                        <div>
                          <label className="text-base text-black font-medium">
                            Single image download
                          </label>
                          <p className="text-black text-sm font-normal mt-4">
                            Turn on to allow your clients to download single
                            photos from this event
                          </p>
                        </div>
                        <div className="text-end">
                          <Switch {...label} defaultChecked />
                        </div>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div>
                        <h4 className="text-base text-black font-medium">
                          Download sizes
                        </h4>
                        <h5 className="text-sm text-[#7a7a7a] mb-3 capitalize font-medium leading-[22px] mt-4">
                          One-click download size
                        </h5>
                        <div className="grid grid-cols-1 grid-flow-row gap-4 sm:grid-cols-2">
                          <div className="mr-0 flex items-center sm:mr-4">
                            <label
                              className="text-sm text-black font-semibold p-3 leading-[14px] cursor-pointer border border-[#e3e3e3] 
                   w-full flex items-center"
                            >
                              <input
                                type="radio"
                                className="outline outline-transparent focus:outline-transparent mr-3"
                              />
                              1600 px
                            </label>
                          </div>
                          <div className="flex items-center">
                            <label
                              className="text-sm text-black font-semibold p-3 leading-[14px] cursor-pointer border border-[#e3e3e3] 
                  w-full flex items-center"
                            >
                              <input
                                type="radio"
                                className="outline outline-transparent focus:outline-transparent mr-3"
                              />
                              Original
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pl-16 max-lg:pl-0">
                    <div>
                      <div className="relative grid grid-cols-2 gap-4">
                        <div>
                          <label className="text-base text-black font-medium">
                            Bulk image download
                          </label>
                          <p className="text-black text-sm font-normal mt-4">
                            Turn on to allow your clients to download single
                            photos from this event
                          </p>
                        </div>
                        <div className="text-end">
                          <Switch {...label} defaultChecked />
                        </div>
                      </div>
                    </div>

                    <div className="mt-8">
                      <div>
                        <h4 className="text-base text-black font-medium">
                          Download sizes
                        </h4>

                        <div className="mt-12 grid grid-cols-1 xl:grid-cols-3 lg:grid-col-2 grid-flow-row gap-4 md:grid-cols-2">
                          <div className="mr-0 flex items-center sm:mr-4">
                            <label
                              className="text-sm text-black font-semibold p-3 leading-[14px] cursor-pointer border border-[#e3e3e3] 
                   w-full flex items-center"
                            >
                              <input
                                type="radio"
                                className="outline outline-transparent focus:outline-transparent mr-3"
                              />
                              1600 px
                            </label>
                          </div>
                          <div className="flex items-center">
                            <label
                              className="text-sm text-black font-semibold p-3 leading-[14px] cursor-pointer border border-[#e3e3e3] 
                  w-full flex items-center"
                            >
                              <input
                                type="radio"
                                className="outline outline-transparent focus:outline-transparent mr-3"
                              />
                              Original
                            </label>
                          </div>

                          <div className="flex items-center">
                            <label
                              className="text-sm text-black font-semibold p-3 leading-[14px] cursor-pointer border border-[#e3e3e3] 
                  w-full flex items-center"
                            >
                              <input
                                type="radio"
                                className="outline outline-transparent focus:outline-transparent mr-3"
                              />
                              both
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-[#e8eaff] p-3 rounded mt-20 relative mb-6">
                  <div className="absolute right-[16px] top-[10px] cursor-pointer">
                    <i className="fas fa-chevron-down"></i>
                  </div>

                  <h6 className="text-black text-base leading-[20px] font-semibold mb-2">
                    Advanced options
                  </h6>
                  <p className="text-black text-sm leading-[20px] font-normal mb-8 pr-4">
                    Restrict specific emails from downloading and prevent
                    sub-events from being downloaded
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-5 max-lg:grid-cols-1">
                  <div className="pr-16 max-lg:pr-0">
                    <div>
                      <div className="relative grid grid-cols-2 gap-4">
                        <div>
                          <label className="text-base text-black font-medium">
                            Block specific emails
                          </label>
                          <p className="text-black text-sm font-normal mt-4">
                            Block downloads to emails you have entered here.
                          </p>
                        </div>
                        <div className="text-end">
                          <Switch {...label} defaultChecked />
                        </div>
                      </div>
                      <div className="relative grid grid-cols-2 gap-4 max-sm:grid-cols-1">
                        <div>
                          <div className="relative mt-4">
                            <input
                              type="email"
                              placeholder="Enter Email"
                              className="border border-[#e3e3e3] text-base text-black 
                            placeholder:text-[#666] py-1.5 px-4 w-full outline outline-transparent focus:outline-transparent mt-1"
                            />
                            <button className="text-sm text-[#4854e2] font-semibold absolute right-[12px] top-[12px] capitalize">
                              update
                            </button>
                          </div>
                          <div className="mt-6">
                            <label className="text-base text-black font-medium">
                              Blocked email addresses
                            </label>
                            <div className="mt-4 border border-[#eee] p-5 relative overflow-y-auto w-full h-[200px] shadow-inner rounded"></div>
                          </div>
                        </div>
                        <div className="text-end"></div>
                      </div>
                    </div>
                  </div>
                  <div className="pl-16 max-lg:pl-0">
                    <div>
                      <div className="relative grid grid-cols-2 gap-4">
                        <div>
                          <label className="text-base text-black font-medium">
                            Restrict Download for Sub-Event Folders
                          </label>
                          <p className="text-black text-sm font-normal mt-4">
                            Enable to restrict download for specific sub-event
                            folder in the collection. This applies to both Bulk
                            and Single Photo Download.
                          </p>
                        </div>
                        <div className="text-end">
                          <Switch {...label} defaultChecked />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="mt-4 mb-20" id="digital-downloads">
                <h3 className="bg-[#f3f3f3] py-2 px-4 w-full text-xl font-semibold text-black mb-7">
                  Paid Digital Download Settings
                </h3>
                <div className="grid grid-cols-2 gap-5 max-sm:grid-cols-1">
                  <div className="pr-16 max-sm:pr-0">
                    <div>
                      <div className="relative flex justify-between">
                        <label className="text-base text-black font-medium">
                          Enable Digital Download
                        </label>
                        <Switch {...label} defaultChecked />
                      </div>
                      <p className="text-black text-sm font-normal">
                        Turn on to allow your clients to pay for
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div> */}

              {/* <div className="mt-4 mb-20" id="favourites-list">
                <h3 className="bg-[#f3f3f3] py-2 px-4 w-full text-xl font-semibold text-black mb-7">
                  Favourite List Settings
                </h3>
                <p className="text-base text-black font-semibold mb-4">
                  Log of limits on favourite folders
                </p>
                <div>
                  <div className="relative">
                    <i className="fas fa-search absolute left-[12px] top-[16px]"></i>
                    <input
                      type="email"
                      placeholder="Search email address or list name"
                      className="w-full border border-[#e3e3e3] py-2.5
                pr-3 pl-8 outline outline-transparent focus:outline-transparent"
                    />
                  </div>

                  <div className="flex flex-row items-center mt-6 bg-[#c7c7c7] py-5 px-4 rounded overflow-x-auto">
                    <div className="basis-1/4 pr-8">
                      <div className="flex items-center pr4">
                        <p className="text-base capitalize font-semibold leading-[18px] pr-2">
                          email
                        </p>
                        <i className="fas fa-exchange-alt rotate-90"></i>
                      </div>
                    </div>
                    <div className="basis-1/4 pr-8">
                      <div className="flex items-center pr4">
                        <p className="text-base capitalize font-semibold leading-[18px] pr-2">
                          List Name
                        </p>
                        <i className="fas fa-exchange-alt rotate-90"></i>
                      </div>
                    </div>
                    <div className="basis-1/4 pr-8">
                      <div className="flex items-center pr4">
                        <p className="text-base capitalize font-semibold leading-[18px] pr-2">
                          Max Selection
                        </p>
                        <i className="fas fa-exchange-alt rotate-90"></i>
                      </div>
                    </div>
                  </div>

                  <div className="max-h-[400px] overflow-y-auto">
                    {arr.map((index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-row items-center bg-[#f3f3f3] py-5 px-4 border-b border-[#ddd] overflow-x-auto"
                        >
                          <div className="basis-1/4 pr-8">
                            <div className="flex items-center pr4">
                              <p className="text-sm capitalize font-normal leading-[18px] pr-2">
                                test@gmail.com
                              </p>
                            </div>
                          </div>
                          <div className="basis-1/4 pr-8">
                            <div className="flex items-center pr4">
                              <p className="text-sm capitalize font-normal leading-[18px] pr-2">
                                My Favorite
                              </p>
                            </div>
                          </div>
                          <div className="basis-1/4 pr-8">
                            <div className="flex items-center pr4">
                              <div className="max-sm:mt-3 sorting-menu w-full">
                                <FormControl sx={{ m: 1, minWidth: 80 }}>
                                  <Select
                                    value={age}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value={10}>1</MenuItem>
                                    <MenuItem value={20}>2</MenuItem>
                                    <MenuItem value={30}>3</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <div className="basis-1/4 pr-8">
                            <div className="flex items-center pr4">
                              <i className="fas fa-trash cursor-pointer"></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div> */}

              <Element name="test4" className="element" >
                <div className="mt-4 mb-20" id="sharing">
                  <h3 className="bg-[#f3f3f3] py-2 px-4 w-full text-xl font-semibold text-black mb-7">
                    Sharing
                  </h3>
                  <div className="mt-6">
                    <h6 className="text-lg font-semibold text-bold capitalize">
                      Registered people
                      <span className="pl-3" onClick={() => setModalShare(true)}>
                        <i className="fas fa-exclamation-circle"></i>
                      </span>
                    </h6>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
                    {visitorUser &&
                      visitorUser.map((row: VisitorInterface, index: number) => {
                        return (
                          <div
                            key={index}
                            className="flex justify-between items-center"
                          >
                            <div className="flex justify-start items-center">
                              <div className="h-10 w-10 rounded-full bg-[#ddd] text-black font-bold text-center flex items-center justify-center text-base max-[450px]:h-6 max-[450px]:w-6 max-[450px]:text-xs uppercase">
                                {row.firstName.slice(0, 2)}
                              </div>
                              <div className="pl-4 max-[450px]:pl-2">
                                <h6 className="text-black text-sm font-bold leading-[18px] max-[450px]:text-xs">
                                  {`${row.firstName} ${row.lastName}`}
                                </h6>
                                <p className="text-xs text-[#7a7a7a] font-normal leading-[14px] max-[450px]:text-xs">
                                  {row.userEmail}
                                </p>
                              </div>
                            </div>
                            <div>

                              <div className="max-sm:mt-3 sorting-menu w-full">
                                {row.roleName}
                                {/* <FormControl sx={{ m: 1, width: 100 }}>
                                <Select
                                  value={row.roleName}
                                  onChange={handleChange}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem value="">
                                    <span>Select watermark preset</span>
                                  </MenuItem>
                                  <MenuItem value={10}>Viewer</MenuItem>
                                  <MenuItem value={20}>Co-Admin</MenuItem>
                                  <MenuItem value={30}>
                                    Block User
                                  </MenuItem>
                                </Select>
                              </FormControl> */}
                              </div>

                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Element>

            </div>
          </div>
        </div>
      </div>

      {/* modal un publish event material ui */}
      <Modal
        open={modalExpiry}
        onClose={() => setModalExpiry(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="relative">
            <div></div>
            <div
              className="cursor-pointer absolute right-[-18px] top-[-25px]"
              onClick={() => { setModalExpiry(false) }}
            >
              <i className="fas fa-times "></i>
            </div>

            <div>
              <div className="relative">
                <div className="text-3xl text-black font-bold capitalize text-center mb-1 leading-[30px]">
                  Warning!
                </div>
                <p className="text-base font-medium capitalize text-center mb-3">
                  Event gallery won't be accessible after the expiry date
                </p>
              </div>

              <div
                className="mt-10 mx-auto text-center flex items-center justify-center mb-4 gap-6 
                max-sm:flex-wrap max-sm:mt-4 max-sm:gap-4"
              >
                <button
                  onClick={() => { setModalExpiry(false) }}
                  className="text-white px-8 py-2.5 find-btn font-normal text-base capitalize"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* modal un publish event material ui */}
      <Modal
        open={modalShare}
        onClose={() => setModalShare(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="relative">
            <div className="flex items-start justify-between border-b border-[#ababab] pb-4">
              <div>
                <div className="text-3xl text-black font-semibold capitalize mb-1 leading-[30px]">
                  Viewer Roles
                </div>
                <p className="text-sm text-[#666] font-normal capitalize">
                  Assigning different viewer roles can decide the viewer's
                  access to the event
                </p>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setModalShare(false);
                }}
              >
                <i className="fas fa-times "></i>
              </div>
            </div>

            <div className="pt-4">
              <div className="relative">
                <div className="text-lg text-black font-bold capitalize mb-1 leading-[30px]">
                  Co-admin
                </div>
                <p className="text-sm text-[#666] font-normal capitalize mb-3">
                  <i className="fas fa-long-arrow-alt-right text-sm text-[#666] mr-3"></i>
                  Upload (to guest folder), hide, download, favourite, share
                  images and add them to cart
                </p>
              </div>

              <div className="relative">
                <div className="text-lg text-black font-bold capitalize mb-1 leading-[30px]">
                  Viewer
                </div>
                <p className="text-sm text-[#666] font-normal capitalize">
                  <i className="fas fa-long-arrow-alt-right text-sm text-[#666] mr-3"></i>
                  Download, favourite, share images and add them to cart
                </p>
              </div>

              <div
                className="mt-8 mx-auto text-center flex items-center justify-center gap-6 
                max-sm:flex-wrap max-sm:mt-4 max-sm:gap-4"
              >
                <button className="text-white px-8 py-2.5 find-btn font-medium text-base capitalize">
                  Done
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default EventSettings;
