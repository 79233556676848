import axios from 'axios';

const API_URL = process.env.REACT_APP_API_KEY

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        // "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("auth-token")

        if (token) {
            config.headers["Authorization"] = token;
            // config.headers["Authorization"] = 'Bearer ' + token;
            // config.headers["x-access-token"] = token; 
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


instance.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        const { response } = error
        if (response.status === 401 && response.statusText === 'Unauthorized') {
            localStorage.removeItem('kt-auth-react-v')
            window.location.reload()
        }
        return Promise.reject(response)
    }
)

// axios.interceptors.response.use(
//     function (response) {
//         return response
//     },
//     function (error) {
//         const { response } = error
//         if (response.status === 401 && response.statusText === 'Unauthorized') {
//             localStorage.removeItem('kt-auth-react-v')
//             window.location.reload()
//         }
//         return Promise.reject(response)
//     }
// )
// interceptors.request define AuthHelper file (setupAxios)

// http common for api get request
export const get = (url: string, params?: any) => {
    return instance
        .get(`${API_URL}/${url}`, params)
        .then((response) => {
            return response
        })
        .catch((error) => {
            if (error.status === 401) {
                localStorage.removeItem("auth-token")
                localStorage.removeItem("user-detail")
            }
            return Promise.reject(error)
        })
}


// http common for api post request
export const post = (url: string, payload?: any, config = {}) =>
    instance
        .post(`${API_URL}/${url}`, payload, config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })

// http common for api patch request
export const patch = (url: string, payload?: any, config = {}) =>
    instance
        .patch(`${API_URL}/${url}`, payload, config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })

// http common for api put request
export const put = (url: string, payload?: any, config = {}) =>
    instance
        .put(`${API_URL}/${url}`, payload, config)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })

// http common for api delete request
export const delet = (url: string) =>
    instance
        .delete(`${API_URL}/${url}`)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })

// http common for api multiple delete request
export const deletes = (url: string, payload: any) =>
    instance
        .delete(`${API_URL}/${url}`, { data: payload })
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return Promise.reject(error)
        })


const http = {
    get,
    post,
    patch,
    put,
    delet,
    deletes,
}

export default http
