// Routing
import { Routes, Route, Navigate } from "react-router-dom";

// Components
import Event from "../components/Event";
import Eventdashboard from "../components/EventDashboard";
import EventSettings from "../components/EventSettings";
import ShareEvent from "../components/ShareEvent";
import Profile from "../components/Profile";

const PrivateRoutes = () => {
    return (
        <Routes>
            <Route path="dashboard" element={<Event />} />
            <Route path="dashboard/events/:id" element={<Eventdashboard />} />
            <Route path="dashboard/events/:id/settings" element={<EventSettings />} />
            <Route path="share/:id" element={<ShareEvent />} />
            <Route path="profile" element={<Profile />} />
            <Route path="/*" element={<Navigate to="/dashboard" />} />
        </Routes>
    )
}

export default PrivateRoutes